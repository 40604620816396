import { Component, Input, OnInit } from '@angular/core';

import { ExioImageServiceOptionsActions } from 'app/services/exio';
import { AppService } from 'app/services/app';
import { Article } from 'app/models/article';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

type ImageUrls = {
	url: string;
	position: string;
	public?: string;
	private?: string;
	exclusive?: string;
}[];

@Component( {
	moduleId: module.id,
	selector: '.app-content-image-gallery',
	templateUrl: 'img.component.html',
	styleUrls: [ 'img.component.less' ]
} )
export class ImageGalleryComponent implements OnInit {

	@Input()
	public layout: string = null;

	@Input()
	public urls: ImageUrls = [];

	@Input()
	public locked: boolean = false;

	@Input()
	public lockedCallback: () => void = null;

	public outputUrls: string[] = [];
	public outputArticleUrls: Article[] = [];


	constructor( public appService: AppService, public sanitizer: DomSanitizer ) {
	}

	public ngOnInit(): void {

		// this.getUrls();
		this.setupArticleUrls();

	}

	public getLayout(): string {

		if ( typeof this.layout === 'string' && this.layout.length > 0 ) {
			return this.layout;
		}

		return 'carousel';

	}

	public getUrls(): ImageUrls {

		return this.urls;

	}

	public clickedLock(): void {
		if ( typeof this.lockedCallback === 'function' ) {
			this.lockedCallback();
		}
	}

	public setupArticleUrls(): Article[] {

		let articles = this.outputArticleUrls;

		// reuse the array object to avoid infinite loop in Angular dirty checking
		while ( articles.length > 0 ) {
			articles.pop();
		}

		this.getUrls().forEach( ( url ) => {
			articles.push( {
				type: 'listing',
				bannerImgUrl: url.url || url.private || url.public || url.exclusive,
				bannerImgPosition: url.position || 'top left'
			} );
		} );

		return articles;

	}


	public cleanPosition( i: number ): SafeStyle {

		let urls = this.getUrls();

		if ( urls.length <= i ) {
			return null;
		}

		let position = urls[ i ].position || 'right bottom';

		return this.sanitizer.bypassSecurityTrustStyle( position );

	}

	public cleanUrl( i: number ): SafeStyle {

		let urls = this.getUrls();

		if ( urls.length <= i ) {
			return null;
		}

		let url = urls[ i ].url || urls[ i ].private || urls[ i ].public || urls[ i ].exclusive;

		let actions: ExioImageServiceOptionsActions[] = <any>[
			{
				type: 'shrink',
				width: 800
			},
			{
				type: 'quality',
				quality: 75
			}
		];

		if ( this.locked ) {
			actions.push( <any>{
				type: 'blur',
				radius: 50
			} );
		}

		url = this.appService.exio.image.getUrl( url, {
			format: 'jpg',
			actions: actions
		} );

		return this.sanitizer.bypassSecurityTrustStyle( `url(${url})` );

	}


}
