
<div class="profile-upload-container" *ngIf="!!workflowTargetState &amp;&amp; !!path &amp;&amp; !!workflowTargetState.id &amp;&amp; workflowTargetState.id !== 'new'">
  <input class="profile-upload-input" *ngIf="files !== null &amp;&amp; !accept" type="file" multiple>
  <input class="profile-upload-input" *ngIf="files !== null &amp;&amp; !!accept" type="file" [accept]="accept" multiple>
  <div class="profile-upload-button">
    <button class="active enabled" (click)="openDialogue()">Upload File</button>
  </div>
  <div class="loader-spinner" *ngIf="files === null"></div>
  <div class="profile-uploaded-files" *ngIf="files !== null &amp;&amp; files.length &lt; 1">
    <div class="profile-uploaded-file">
      <div class="profile-uploaded-file-field profile-uploaded-file-name">No Files Uploaded</div>
    </div>
  </div>
  <div class="profile-uploaded-files" *ngIf="files !== null">
    <div class="profile-uploaded-file" *ngFor="let file of fileList();">
      <div class="profile-uploaded-file-field profile-uploaded-file-name">{{ file.name }}</div>
      <div class="profile-uploaded-file-field profile-uploaded-file-remove" *ngIf="uploadComplete(file)" (click)="removeFile(file)">Remove</div>
      <div class="profile-uploaded-file-field profile-uploaded-file-view" *ngIf="uploadComplete(file) &amp;&amp; file.webViewLink" (click)="viewFile(file)">View</div>
      <div class="profile-uploaded-file-field profile-uploaded-file-progress" *ngIf="!uploadComplete(file)">{{ formatPercent(file.uploadProgress) }}</div>
    </div>
  </div>
</div>