import { Component, OnInit, OnDestroy, Input, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'app/services/app';
import { LegalDocument, LegalDocumentInstance, LegalDocumentInstanceSigner } from 'app/models/legal.document';
import { Profile } from 'app/models/profile';
import { Hash } from 'app/types/containers';
import * as async from 'async';

@Component( {
  moduleId: module.id,
  selector: 'app-content-render-legal-document-sign',
  templateUrl: 'legal.document.sign.component.html',
  styleUrls: [ 'legal.document.sign.component.less' ]
} )
export class ContentRenderLegalDocumentSignComponent implements OnInit, OnDestroy {

  @Input()
  public path: string = null;

  @Input()
  public redirect: string = null;

  @Input()
  public instanceId: string = null;

  @Input()
  public showWaitingSignatures: boolean = true;

  @Input()
  public profile = <Profile>null;

  @Input()
  public mode: string = 'normal';

  public signing: boolean = false;

  public $el: JQuery = null;

  public document: LegalDocument = null;
  public documentRaw: LegalDocument = null;

  public instance: LegalDocumentInstance = null;

  public documentExpanded: boolean = false;

  public profileCache: Hash<Profile> = {};

  public loading: boolean = false;

  public authorized: boolean = true;

  public errorMessage: string = null;
  public errorMessageTimeout: number = null;

  public requestedSigners: LegalDocumentInstanceSigner[] = [];

  public onFormChangeHandler: () => void = null;

  public subscriptions: any[] = [];

  constructor( public app: AppService, public router: Router, public element: ElementRef ) {
    this.$el = $( this.element.nativeElement );
    this.$el.val( 'unsigned' );

    this.onFormChangeHandler = () => {
      this.onFormChange();
    };

  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach( ( subscription ) => {
      subscription.unsubscribe();
    } );
  }

  public ngOnInit(): void {

    // console.log( 'init ContentRenderLegalDocumentSignComponent' );
    if ( !(
      (this.path || this.instanceId) &&
      this.loading === false &&
      this.documentRaw === null &&
      this.instance === null
    ) ) {
      return;
    }

    // console.log( 'loading ContentRenderLegalDocumentSignComponent' );
    this.loading = true;
    this.documentRaw = null;
    this.instance = null;

    const loadNotAuthorized = () => {
      this.loading = false;
      this.authorized = false;
      this.app.contentLoading( false );
    };

    const loadDocument = () => {

      this.app
        .legalDocumentModel
        .get( this.instance.legalDocumentId )
        .then( ( document: LegalDocument ) => {
          // console.log( 'loadDocument', document );

          this.documentRaw = document;

          this.onFormChange();

          this.loading = false;
          this.setRequestedSigners();
          this.app.contentLoading( false );

          return null;

        } )
        .catch( ( err ) => {
          console.error( 'loadDocument err', err );
          this.documentRaw = null;
          this.instance = null;
          this.app.contentLoading( false );
        } );

    };

    const loadDocumentInstance = () => {

      const handleError = ( err ) => {

        console.error( 'loadDocumentInstance handleError', err );

        this.loading = false;
        this.documentRaw = null;
        this.instance = null;
      };

      const handleInstance = ( instance: LegalDocumentInstance ) => {
        // console.log( 'handleInstance', instance );


        if ( instance ) {
          this.instance = instance;

          this.$el.val( instance && instance.complete ? 'signed' : 'unsigned' );
          this.$el.change();

          loadDocument();
        } else {
          this.instance = null;
          loadNotAuthorized();
        }

        return null;

      };

      // these three legal contracts can be signed by anyone, no instance required
      if ( this.instanceId ) {

        this.app
          .legalDocumentModel
          .getDocumentInstances( {
            id: this.instanceId
          } )
          .then( ( instances ) => {
            if ( Array.isArray( instances ) && instances.length > 0 ) {
              return handleInstance( instances[ 0 ] );
            }

            handleError( new Error( 'instance not found' ) );

            return null;
          } )
          .catch( handleError );

      } else if ( this.path.match( /^confidential-information-agreement/ ) ||
                  this.path.match( /^non-disclosure-agreement/ ) ||
                  this.path.match( /^buyer-representative-agreement/ )
      ) {

        // load instance for current user, or create one if it doesn't exist
        this.app
          .legalDocumentModel
          .upsertSignerInstance( {
            path: this.path
          } )
          .then( handleInstance )
          .catch( handleError );

      } else {

        // all other
        this.app
          .legalDocumentModel
          .getSignerInstance( {
            path: this.path
          } )
          .then( handleInstance )
          .catch( handleError );

      }

    };

    this.subscriptions.push( this.app.getAuthenticatedProfile( {
      next: ( profile ) => {

        // console.log( 'init getAuthenticatedProfile', JSON.stringify( profile ) );

        if ( profile ) {

          // there is a profile, try loading document
          this.profile = profile;
          loadDocumentInstance();

        } else if ( profile === null ) {

          // user not logged in, prompt user to log in
          this.app.contentLoading( false );
          this.loading = false;

        }

      },
      error: () => {
        this.app.contentLoading( true );
        this.router.navigate( [ '/' ] );
      },
      complete: () => {
        this.app.contentLoading( true );
        this.router.navigate( [ '/' ] );
      }
    } ) );

  }

  public markdownToHtml( markdownStr: string ): string {

    if ( typeof markdownStr !== 'string' ) {
      markdownStr = '';
    }

    return this.app.markdownToHtml( markdownStr );

  }

  public signerProfile( signer: LegalDocumentInstanceSigner ): Profile {

    if ( this.profileCache.hasOwnProperty( signer.userId ) ) {
      return this.profileCache[ signer.userId ];
    }

    this.profileCache[ signer.userId ] = null;

    this.app.profileModel
      .get( signer.userId )
      .then( ( profile: Profile ) => {
        this.profileCache[ signer.userId ] = profile;
        return null;
      } );

    return null;

  }

  public setRequestedSigners(): void {

    let result: LegalDocumentInstanceSigner[] = [];

    if ( this.instance && this.instance.signers ) {

      result = this.instance.signers.filter( ( signer ) => {
        return signer && signer.userId !== this.profile.id && (typeof signer.signed !== 'string' || signer.signed.length < 1);
      } );

    }

    this.requestedSigners = result;

  }

  public onFormChange(): void {

    this.document = this.app
      .legalDocumentModel
      .applyMergeCodes( this.documentRaw, this.profile, this.instance.data || {} );

  }

  public collapse(): void {
    this.documentExpanded = false;
  }

  public expand(): void {
    this.documentExpanded = true;
  }

  public signed(): boolean {
    let signed = false;

    if ( this.instance && this.instance.signers ) {
      this.instance.signers.forEach( ( signer ) => {

        if ( signed ) {
          return;
        }

        if ( signer && signer.userId && this.profile && this.profile.id === signer.userId && (signer.signed || signer.declined) ) {
          signed = true;

          if ( this.document.signatureSuccess.match( /^[A-Za-z0-9\-\/]+$/ ) ) {
            const successUrl = this.document.signatureSuccess.trim().split( '/' );
            successUrl.unshift( '/' );
            this.app.contentLoading( true );
            this.router.navigate( successUrl );
          }

        }
      } );
    }

    if ( signed && typeof this.redirect === 'string' && this.redirect.trim().length > 0 ) {
      this.app.contentLoading( true );
      this.router.navigateByUrl( this.redirect );
    }

    return signed;
  }

  private trackLinkedInConversion(): void {

    let pixel = $( '<img height="1" width="1" style="display:none;" alt="" src="https://dc.ads.linkedin.com/collect/?pid=165700&conversionId=241193&fmt=gif" />' );

    $( 'body' ).append( pixel );


    window.setTimeout( () => {
      pixel.remove();
    }, 5000 );

  }

  public accept(): void {

    this.signing = true;

    // console.log( 'accept' );

    this.app.profileModel.save( this.profile )
      .then( () => {

        return this.app.legalDocumentModel
          .acceptInstance( { id: this.instance.id, urlRef: this.redirect } )
          .then( ( signature: LegalDocumentInstanceSigner ) => {

            // console.log( 'signature', signature );
            this.signing = false;

            if ( signature ) {

              // console.log( 'this.instance', this.instance );
              if ( this.instance && this.instance.signers ) {

                this.instance.signers.forEach( ( signer ) => {
                  if ( signer && signer.userId && signature.userId === signer.userId ) {
                    signer.signed = signature.signed;
                  }
                } );

              }

              this.trackLinkedInConversion();

              if ( typeof this.redirect === 'string' && this.redirect.trim().length > 0 ) {
                this.app.contentLoading( true );
                this.router.navigateByUrl( this.redirect );
              } else if ( this.document.signatureSuccess.match( /^[A-Za-z0-9\-\/]+$/ ) ) {
                const successUrl = this.document.signatureSuccess.trim().split( '/' );
                successUrl.unshift( '/' );

                this.app.contentLoading( true );
                this.router.navigate( successUrl );
              }
            } else {
              this.setErrorMessage( 'An error occurred while accepting. Please try again in a moment.' );
            }

            return null;
          } )
          .catch( () => {
            this.setErrorMessage( 'An error occurred while accepting. Please try again in a moment.' );
          } );

      } )
      .catch( ( e ) => {
        console.error( 'failed to save profile before signing agreement', e );
      } );

  }

  public decline(): void {

    this.app.legalDocumentModel
      .declineInstance( { legalDocumentId: this.document.id } )
      .then( () => {
        this.router.navigate( [ '/' ] );
        return null;
      } )
      .catch( () => {
        this.setErrorMessage( 'An error occurred while declining. Please try again in a moment.' );
      } );

  }

  public setErrorMessage( message: string, timeout?: number ): void {
    this.errorMessage = message;

    if ( this.errorMessageTimeout !== null ) {
      window.clearTimeout( this.errorMessageTimeout );
    }

    const defaultTimeout = 30000;

    if ( typeof timeout !== 'number' || timeout < 0 ) {
      timeout = defaultTimeout;
    }

    this.errorMessageTimeout = window.setTimeout( () => {
      this.errorMessage = null;
    }, timeout );

  }

  public formComplete(): boolean {

    if ( !this.profile ) {
      return false;
    }

    const hasText = ( obj, field, minLength ) => {
      return obj && obj.hasOwnProperty( field ) && typeof obj[ field ] === 'string' && obj[ field ].trim().length >= minLength;
    };

    const hasNumber = ( obj, field, minLength ) => {

      return obj &&
             obj.hasOwnProperty( field ) &&
             typeof obj[ field ] === 'string' && obj[ field ].replace( /[^0-9]/g, '' ).length >= minLength;

    };

    const textFields = [
      'firstName',
      'lastName',
      'email',
      'company',
      'title'
    ];

    for ( let i = 0; i < textFields.length; i++ ) {
      if ( !hasText( this.profile, textFields[ i ], 3 ) ) {
        return false;
      }
    }

    if ( !this.profile.email.trim().match( /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ) ) {
      return false;
    }

    if ( !hasNumber( this.profile, 'workPhone', 10 ) && !hasNumber( this.profile, 'mobilePhone', 10 ) ) {
      return false;
    }

    if ( !hasText( this.profile.address, 'street1', 5 ) ) {
      return false;
    }

    if ( typeof this.profile.address.zip === 'number' ) {
      this.profile.address.zip = this.profile.address.zip + '';
    }

    return hasNumber( this.profile.address, 'zip', 5 );

  }

}
