
<div class="newsletter-signup">
  <div class="newsletter-signup-container">
    <div class="title">Go Forward. Go Exio.</div>
    <div class="message" *ngIf="subscribeComplete">Thank you for signing up!</div>
    <div class="message" *ngIf="!subscribeComplete">Sign up for our newsletter and be the first to receive Exio blog posts and announcements.</div>
    <div class="subscribe-email" *ngIf="!subscribeComplete">
      <input placeholder="Enter your email" type="email" [(ngModel)]="subscribeEmail">
    </div>
    <div class="subscribe-button" *ngIf="!subscribeComplete &amp;&amp; !subscribing" [class.active]="validEmail()" (click)="subscribe()"><span>Subscribe</span></div>
    <div class="subscribing" *ngIf="!subscribeComplete &amp;&amp; subscribing">
      <div class="loader-bar"></div>
    </div>
  </div>
</div>