
<div class="leadership-grid">
  <div class="leadership-grid-item-container" *ngFor="let profile of profiles;">
    <div class="leadership-grid-item" [routerLink]="['/learn/leadership/', profile.id]">
      <div class="background" [style.background-image]="profileImageUrl(profile)"></div>
      <div class="content-container">
        <div class="content">
          <div class="title">{{ profile.title }}</div>
          <div class="first-name" *ngIf="profile.firstName">{{profile.firstName}}</div>
          <div class="last-name" *ngIf="profile.lastName">{{profile.lastName}}</div>
        </div>
      </div>
    </div>
  </div>
</div><a class="seo-link" *ngFor="let profile of profiles;" [href]="'/learn/leadership/' + profile.id">{{profile.firstName}} {{profile.lastName}}</a>