import { Component, ChangeDetectorRef } from '@angular/core';
import { Profile } from 'app/models/profile';

import { AppService } from 'app/services/app';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component( {
  moduleId: module.id,
  selector: '.content-render-leadership',
  templateUrl: 'leadership.component.html',
  styleUrls: [ 'leadership.component.less' ]
} )
export class ContentRenderLeadershipComponent {

  public profiles: Profile[] = [];

  constructor( public app: AppService, public sanitizer: DomSanitizer, public changeDetector: ChangeDetectorRef ) {

    this.app.profileModel.listFeaturedEmployees()
      .then( ( profiles: Profile[] ) => {

        this.profiles = profiles.sort( ( a, b ) => {
          if ( a.sortHint === b.sortHint ) {
            return 0;
          } else if ( a.sortHint === null ) {
            return 1;
          } else if ( b.sortHint === null ) {
            return -1;
          } else {
            return a.sortHint - b.sortHint;
          }
        } );

        this.changeDetector.markForCheck();

      } )
      .catch( () => {
        this.profiles = [];
        this.changeDetector.markForCheck();
      } );

  }

  public imgUrl( url: string ): string {

    if ( !url ) {
      return 'none';
    }

    url = this.app.exio.image.getUrl( url, {
      format: 'jpg',
      actions: [
        {
          type: 'shrink',
          width: 300
        },
        {
          type: 'quality',
          quality: 100
        }
      ]
    } );

    return `url("${url}")`;

  }

  public profileImageUrl( profile: Profile ): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle( this.imgUrl( profile.headshotURL ) );
  }

}
