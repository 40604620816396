
<div class="work-flow-window-toolbar"></div>
<div class="work-flow-window" *ngIf="workflowError">
  <div class="workflow-error" [innerHTML]="markdownToHtml(workflowError)">{{ workflowError }}</div>
</div>
<div class="work-flow-window" *ngIf="!workflowError &amp;&amp; !!workflowDefinition &amp;&amp; !!workflowTargetState">
  <div class="workflow-menu" *ngIf="leftMenuShowing()">
    <div class="workflow-menu-container">
      <div class="workflow-menu-body">
        <div class="workflow-menu-item" *ngFor="let section of getMenuSections()">
          <div class="workflow-menu-item-done">
            <div class="component-icon check" [class.active]="isCompleteSection(section) &amp;&amp; showComplete(section)"></div>
          </div>
          <div class="workflow-menu-item-label" [class.active]="isActiveSection(section)" (click)="goSection(section)">{{ section.title }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="workflow-process" [class.full-width]="!leftMenuShowing()">
    <div class="workflow-process-container">
      <div class="page-title" *ngIf="!!currentPage" [innerHTML]="markdownToHtml(currentPage.title)"></div>
      <div class="workflow-process-menu" *ngIf="!section || !page">
        <div class="process-menu-title" *ngIf="!!workflowDefinition.title" [innerHTML]="markdownToHtml(workflowDefinition.title)"></div>
        <div class="process-menu-description" *ngIf="workflowDefinition.description" [innerHTML]="markdownToHtml(workflowDefinition.description)"></div>
        <div class="process-menu-sections-container">
          <div class="process-menu-section-container col-lg-2 col-md-4 col-sm-5 col-xs-6" *ngFor="let section of getMenuSections()">
            <div class="process-menu-section" (click)="goSection(section)">
              <div class="process-menu-section-icon-container">
                <div class="process-menu-section-icon" [class]="'process-menu-section-icon ' + getSectionIcon(section)"></div>
              </div>
              <div class="process-menu-section-title" [innerHTML]="markdownToHtml(section.title)"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="workflow-process-page" *ngIf="section &amp;&amp; page &amp;&amp; currentPage" [style.width]="pageWidth(currentPage)">
        <div class="page-item col-xs-12" *ngFor="let item of currentPage.items; let i = index;" [attr.id]="inputId(i) + '-item'">
          <div class="page-item-title" *ngIf="!!item.title" [innerHTML]="markdownToHtml(item.title)"></div>
          <div class="page-item-content" *ngIf="!!item.type">
            <page-item-content-section-review *ngIf="item.type  === 'section-review'" [workflowDefinition]="workflowDefinition" [workflowTargetState]="workflowTargetState" [section]="section" [go]="goPageHandler()"></page-item-content-section-review>
            <div class="page-item-content-listing-upload" *ngIf="item.type  === 'listing-upload'">
              <label *ngIf="item.label" [style.text-align]="textAlign(item)" [innerHTML]="markdownToHtml(item.label)"></label>
              <div class="content-render-listing-upload" [workflowTargetState]="workflowTargetState" [item]="item"></div>
            </div>
            <div class="page-item-content-profile-upload" *ngIf="item.type  === 'profile-upload'">
              <label *ngIf="item.label" [style.text-align]="textAlign(item)" [innerHTML]="markdownToHtml(item.label)"></label>
              <app-content-render-profile-upload [workflowTargetState]="workflowTargetState" [item]="item"></app-content-render-profile-upload>
            </div>
            <div class="page-item-content-my-listings" *ngIf="item.type  === 'my-listings'">
              <app-content-render-my-listings></app-content-render-my-listings>
            </div>
            <div class="page-item-content-my-legal-documents" *ngIf="item.type  === 'my-legal-documents'">
              <div class="content-render-my-legal-documents"></div>
            </div>
            <div class="page-item-content-legal-document" *ngIf="item.type  === 'legal-document'">
              <app-content-render-legal-document-sign *ngIf="item.params &amp;&amp; item.params.path" [path]="item.params.path" [showWaitingSignatures]="false" [mode]="'embed'" [attr.id]="inputId(i)" [attr.name]="inputId(i)" [attr.value]="inputId(i)"></app-content-render-legal-document-sign>
            </div>
            <div class="page-item-content-social-login" *ngIf="item.type === 'social-login' &amp;&amp; this.socialLoginWait">
              <div class="social-login-processing">Waiting for login to finish...</div>
            </div>
            <div class="page-item-content-social-login" *ngIf="item.type === 'social-login' &amp;&amp; !this.socialLoginWait">
              <div class="social-login-timeout" *ngIf="this.socialLoginWaitTimeout">Login timed out, please try again</div>
              <div class="social-login-buttons-container">
                <div class="social-login-button-container" *ngFor="let network of item.params.networks">
                  <div class="social-login-button" (click)="socialLogin(network)" [class]="'social-login-button social-icon social-icon-' + network.toLowerCase()"></div>
                </div>
              </div>
            </div>
            <div class="page-item-content-checkbox page-item-contain" *ngIf="item.type === 'checkbox'">
              <label *ngIf="item.label" [attr.for]="inputId(i)" [style.text-align]="textAlign(item)" [innerHTML]="markdownToHtml(item.label)"></label>
              <input type="checkbox" [attr.id]="inputId(i)" [attr.name]="inputId(i)" [attr.value]="inputId(i)">
              <div class="complete-hint" *ngIf="isAnsweredItem(item) &amp;&amp; !isCompleteItem(item)">{{ incompleteText(item) }}</div>
            </div>
            <div class="page-item-content-text page-item-contain" *ngIf="item.type === 'text' || item.type === 'email' || item.type === 'password'">
              <label *ngIf="item.label" [attr.for]="inputId(i)" [style.text-align]="textAlign(item)" [innerHTML]="markdownToHtml(item.label)"></label>
              <input [type]="item.type" [attr.id]="inputId(i)" [attr.name]="inputId(i)" [attr.placeholder]="item.placeholder || ''">
              <div class="complete-hint" *ngIf="isAnsweredItem(item) &amp;&amp; !isCompleteItem(item)">{{ incompleteText(item) }}</div>
            </div>
            <div class="page-item-content-text page-item-contain" *ngIf="item.type === 'datepicker'">
              <label *ngIf="item.label" [attr.for]="inputId(i)" [style.text-align]="textAlign(item)" [innerHTML]="markdownToHtml(item.label)"></label>
              <input class="datepicker" [type]="item.type" [attr.id]="inputId(i)" [attr.name]="inputId(i)" [attr.placeholder]="item.placeholder || ''">
              <div class="complete-hint" *ngIf="isAnsweredItem(item) &amp;&amp; !isCompleteItem(item)">{{ incompleteText(item) }}</div>
            </div>
            <div class="page-item-content-textarea page-item-contain" *ngIf="item.type === 'textarea'">
              <label *ngIf="item.label" [attr.for]="inputId(i)" [style.text-align]="textAlign(item)" [innerHTML]="markdownToHtml(item.label)"></label>
              <textarea [attr.id]="inputId(i)" [attr.name]="inputId(i)" [attr.placeholder]="item.placeholder || ''"></textarea>
              <div class="complete-hint" *ngIf="isAnsweredItem(item) &amp;&amp; !isCompleteItem(item)">{{ incompleteText(item) }}</div>
            </div>
            <div class="page-item-content-dropdown" *ngIf="item.type === 'dropdown'">
              <label *ngIf="item.label" [attr.for]="inputId(i)" [style.text-align]="textAlign(item)" [innerHTML]="markdownToHtml(item.label)"></label>
              <div class="input-type-dropdown" [attr.id]="inputId(i)" [attr.name]="inputId(i)" [options]="item.params.options" [sortOptions]="item.params.sortOptions || 'none'" [unselectedDisplayValue]="item.params.unselectedDisplayValue"></div>
              <div class="complete-hint" *ngIf="isAnsweredItem(item) &amp;&amp; !isCompleteItem(item)">{{ incompleteText(item) }}</div>
            </div>
            <div class="page-item-content-workflow-continue" *ngIf="item.type === 'workflow-continue'">
              <div class="workflow-menu col-xs-12" *ngIf="!!workflowDefinition &amp;&amp; !!workflowDefinition.sections &amp;&amp; getMenuSections().length &gt; 1 &amp;&amp; workflowDefinition.type === 'linear'">
                <div class="workflow-menu-container col-xs-12">
                  <div class="workflow-menu-body">
                    <div class="workflow-menu-row col-xs-12" *ngFor="let sections of getMenuSectionsGroups(3)">
                      <div class="workflow-menu-item col-xs-4" *ngFor="let section of sections">
                        <div class="workflow-menu-item-done">
                          <div class="component-icon check" [class.active]="isCompleteSection(section)"></div>
                        </div>
                        <div class="workflow-menu-item-label" [class.active]="section.id === lastSection" (click)="goSection(section)">{{ section.title }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="resume-button active" (click)="goResume()">{{ item.resumeText || 'PICK UP WHERE I LEFT OFF' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="workflow-process-page-controls" *ngIf="currentSection &amp;&amp; currentPage">
      <div class="back-button-container">
        <div class="back-button" *ngIf="!currentSection.continueDisabled &amp;&amp; (isType('menu') || !isFirstSection() || !isFirstPage())" (click)="goPrevious()"><&nbsp;&nbsp;Back</div>
      </div>
      <div class="footer-message-container">{{ currentPage.footerMessage }}
        <div class="footer-message" [innerHTML]="markdownToHtml(currentPage.footerMessage)"></div>
      </div>
      <div class="continue-button-container">
        <div class="continue-button" *ngIf="!currentPage.continueDisabled &amp;&amp; !currentSection.continueDisabled &amp;&amp; !currentSection.nextDisabled" (click)="goNext()" [class.active]="currentPageComplete()">{{ continueButtonLabel() }}</div>
      </div>
    </div>
  </div>
</div>