
<app-content-banner [article]="article" [lockedCallback]="lockedCallback"></app-content-banner>
<app-submenu *ngIf="article &amp;&amp; article.submenu &amp;&amp; article.submenu.length &gt; 0" [items]="article.submenu"></app-submenu>
<div class="listing-upgrade-follow" *ngIf="showUpgradeFollow()">
  <div class="action">
    <div class="label-container" (click)="upgradeNow()">
      <div class="label">{{ followUpgradeCallToActionText() }}</div>
    </div>
  </div>
</div>
<div class="content-listing-table-container" *ngIf="showTableOfContents()">
  <div class="content-listing-table">
    <div class="content-listing-title">Table of Contents</div>
    <div class="content-listing-table-items-container">
      <div class="content-listing-table-items">
        <div class="content-listing-table-item-container" *ngFor="let item of getListingTableItems(); let i = index;" (click)="tableOfContentsNavHandler(item)">
          <div class="content-listing-table-item">
            <div class="content-listing-table-item-icon">
              <div class="component-icon item-indicator" [ngClass]="item.classes"></div>
              <div class="component-icon item-indicator active active-pre-load" [ngClass]="item.classes"></div>
            </div>
            <div class="content-listing-table-item-name item-indicator" [innerHTML]="item.nameHTML"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="content-unlocking">
  <div class="content-unlocking-container">
    <div class="unlock-message">Content Unlocking...</div>
    <div class="loader-spinner"></div>
  </div>
</div>
<div class="content-render initial-state" *ngIf="!!article" [article]="{ sections: getFilteredSections() }" [lockedCallback]="lockedCallback" [accessLevel]="getAccessLevel()"></div>
<app-content-render-documents *ngIf="hasDocuments()" [article]="article" [lockedCallback]="lockedCallback" [attr.data-section-name]="&quot;Diligence\nDocuments&quot;"></app-content-render-documents>
<div class="content-listing-close-deal-container" *ngIf="!!article &amp;&amp; article.type ==='listing' &amp;&amp; !showTableOfContents()">
  <div class="content-listing-close-deal">
    <h2 class="upgrade-title">Want to be notified when this<br>company hits the market?</h2>
    <div class="upgrade-details">Click below to register for updates on the status of this listing.</div>
    <div class="purchase-cta-container">
      <div class="purchase-cta" (click)="followListingComingSoon()">Follow This Listing</div>
    </div>
  </div>
</div>
<div class="content-listing-close-deal-container" *ngIf="!!article &amp;&amp; article.type ==='advisor' &amp;&amp; isAdvisor()">
  <div class="content-listing-close-deal">
    <h2 class="upgrade-title">Want to Contact this Advisor?</h2>
    <div class="upgrade-details">Whether you want to ask a question or simply introduce yourself to this advisor, you can click below to take the next step.</div>
    <div class="purchase-cta-container">
      <div class="purchase-cta" (click)="contactAdvisor()">Contact Advisor</div>
    </div>
  </div>
</div>
<div class="content-listing-close-deal-container" *ngIf="!!article &amp;&amp; article.type ==='listing' &amp;&amp; getAccessLevel() === 'exclusive'">
  <div class="content-listing-close-deal">
    <h2 class="upgrade-title">Ready to Make a Deal?</h2>
    <div class="upgrade-details">You’ve successfully navigated the complete story for this listing through our Public, Private and Exclusive-level platform. Click below to take the next step.</div>
    <div class="purchase-cta-container">
      <div class="purchase-cta" (click)="inquireAboutPurchase()">Inquire About Purchase</div>
    </div>
  </div>
</div>
<div class="content-listing-close-deal-container" *ngIf="!!article &amp;&amp; !article.categories.includes('cano_mode') &amp;&amp; article.type ==='buyer' &amp;&amp; getAccessLevel() === 'exclusive'">
  <div class="content-listing-close-deal">
    <h2 class="upgrade-title">Your Inquiry Has Been Approved!</h2>
    <!--.upgrade-details You’ve successfully navigated the complete story for this-->
    <!--	br-->
    <!--	| buyer through our Public, Private and Exclusive-level platform.-->
    <div class="upgrade-details">Your preliminary business information has been submitted to the buyer and it was accepted. We will contact you shortly with additional steps.<br>Hang in there!</div>
    <!--.purchase-cta-container-->
    <!--	.purchase-cta((click)="inquireAboutSelling()") Inquire About Selling-->
  </div>
</div>
<div class="content-listing-close-deal-container" *ngIf="!!article &amp;&amp; article.categories.includes('cano_mode') &amp;&amp; article.type ==='buyer' &amp;&amp; getAccessLevel(true) === 'exclusive'">
  <div class="content-listing-close-deal">
    <h2 class="upgrade-title">Your Practice Information Has Been Received!</h2>
    <!--.upgrade-details You’ve successfully navigated the complete story for this-->
    <!--	br-->
    <!--	| buyer through our Public, Private and Exclusive-level platform.-->
    <div class="upgrade-details">CEO Dr. Hernandez and his staff thank you for your preliminary practice information and look forward to meeting with you soon.</div>
    <!--.purchase-cta-container-->
    <!--	.purchase-cta((click)="inquireAboutSelling()") Inquire About Selling-->
  </div>
</div>
<div class="content-listing-upgrade-access" id="main-call-to-actions" *ngIf="showTableOfContents() &amp;&amp; !isAdvisor() &amp;&amp; getAccessLevel() !== 'exclusive'">
  <h2 class="upgrade-title" *ngIf="getAccessLevel() === 'public'">I'm Ready to See More. What's Next?</h2>
  <h2 class="upgrade-title" *ngIf="getAccessLevel() === 'private' &amp;&amp; getAccessLevel() === getAccessLevel()">See the complete Story with Exclusive Access.</h2>
  <h2 class="upgrade-title" *ngIf="getAccessLevel() === 'private' &amp;&amp; getAccessLevel() !== getAccessLevel()">Log In to re-enable your Private Access.</h2>
  <h2 class="upgrade-title" *ngIf="getAccessLevel() === 'exclusive' &amp;&amp; getAccessLevel() !== getAccessLevel()">Log In to re-enable your Exclusive Access.</h2>
  <div class="upgrade-description" *ngIf="article.type === 'listing' &amp;&amp; getAccessLevel() === 'public'">If you'd like to see more, create a free account for a private, behind-the-scenes look at how this company performs.</div>
  <div class="upgrade-description" *ngIf="article.type === 'buyer' &amp;&amp; getAccessLevel() === 'public'">If you'd like to see more, create a free account for a private, behind-the-scenes look at this premier buyer.</div>
  <div class="upgrade-description" *ngIf="article.type === 'listing' &amp;&amp; getAccessLevel() === 'private' &amp;&amp; getAccessLevel() === getAccessLevel()">If you'd like to gain unfiltered, complete access to all confidential information for this company. Upgrade to Exclusive Access.</div>
  <div class="upgrade-description" *ngIf="article.type === 'buyer' &amp;&amp; getAccessLevel() === 'private' &amp;&amp; getAccessLevel() === getAccessLevel()">If you'd like to gain unfiltered, complete access to all confidential information for this buyer. Upgrade to Exclusive Access.</div>
  <div class="upgrade-description" *ngIf="getAccessLevel() === 'private' &amp;&amp; getAccessLevel() !== getAccessLevel()">You appear to already have Private Access. You simply need to log in to re-gain access to private content.</div>
  <div class="upgrade-description" *ngIf="getAccessLevel() === 'exclusive' &amp;&amp; getAccessLevel() !== getAccessLevel()">You appear to already have Exclusive Access. You simply need to log in to re-gain access to exclusive content.</div>
  <div class="upgrade-matrix-container">
    <div class="matrix-row header-row">
      <div class="field"></div>
      <div class="field">
        <div class="field-content">Public</div>
        <div class="field-subcontent">Step One</div>
      </div>
      <div class="field" [class.active]="getAccessLevel() === 'public'">
        <div class="field-content">Private</div>
        <div class="field-subcontent">Step Two</div>
      </div>
      <div class="field" [class.active]="getAccessLevel() === 'private'">
        <div class="field-content">Exclusive</div>
        <div class="field-subcontent">Step Three</div>
      </div>
    </div>
    <div class="matrix-row feature-row" *ngFor="let feature of getUpgradeFeatures()">
      <div class="field feature-name">
        <div class="field-content">{{ feature.name }}</div>
      </div>
      <div class="field feature-access">
        <div class="icon-container" [class.unlocked]="feature.public" [class.locked]="!feature.public">
          <div class="component-icon check active"></div>
          <div class="component-icon lock-gray active"></div>
        </div>
      </div>
      <div class="field feature-access" [class.active]="getAccessLevel() === 'public'">
        <div class="icon-container" [class.unlocked]="feature.private" [class.locked]="!feature.private">
          <div class="component-icon check active" *ngIf="getAccessLevel() === 'private'"></div>
          <div class="component-icon check-gray" *ngIf="getAccessLevel() === 'public'"></div>
          <div class="component-icon lock-gray active"></div>
        </div>
      </div>
      <div class="field feature-access" [class.active]="getAccessLevel() === 'private'">
        <div class="icon-container" [class.unlocked]="feature.exclusive" [class.locked]="!feature.exclusive">
          <div class="component-icon check active"></div>
          <div class="component-icon check-gray"></div>
          <div class="component-icon lock-gray active"></div>
        </div>
      </div>
    </div>
    <div class="matrix-row action-row">
      <div class="field"></div>
      <div class="field">
        <div class="field-content">Completed</div>
      </div>
      <div class="field active" *ngIf="getAccessLevel() === 'public'">
        <div class="field-content pending" *ngIf="isPendingPrivateAccess()">Under Review</div>
        <div class="field-content pending-tagline" *ngIf="isPendingPrivateAccess()">Please check back<br>in 1 business day</div>
        <div class="field-content" *ngIf="!isPendingPrivateAccess()">
          <div class="start-button" (click)="upgradeNow()">{{ upgradeCallToActionText() }}</div>
        </div>
      </div>
      <div class="field" *ngIf="getAccessLevel() === 'private'">
        <div class="field-content">Completed</div>
      </div>
      <div class="field" *ngIf="getAccessLevel() === 'public'">
        <div class="field-content locked">Locked</div>
      </div>
      <div class="field active" *ngIf="getAccessLevel() === 'private'">
        <div class="field-content pending" *ngIf="isPendingExclusiveAccess()">Under Review
          <div class="field-content pending-tagline" *ngIf="isPendingExclusiveAccess()">Please check back<br>in 3 business days</div>
        </div>
        <div class="field-content" *ngIf="!isPendingExclusiveAccess()">
          <div class="start-button" (click)="upgradeNow()">{{ upgradeCallToActionText() }}</div>
        </div>
      </div>
    </div>
    <div class="matrix-row requirements-row">
      <div class="field"></div>
      <div class="field">
        <div class="field-content">
          <div class="title">Requirements</div>
          <div class="requirement">None</div>
        </div>
      </div>
      <div class="field" [class.active]="getAccessLevel() === 'public'">
        <div class="field-content">
          <div class="title">Requirements</div>
          <div class="requirement">Create Account</div>
          <div class="requirement">Sign NDA</div>
        </div>
      </div>
      <div class="field" [class.active]="getAccessLevel() === 'private'">
        <div class="field-content">
          <div class="title">Requirements</div>
          <div class="requirement">Complete Step Two</div>
          <div class="requirement">Questionnaire</div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-forms-newsletter *ngIf="showNewsLetter()"></app-forms-newsletter>
<app-footer-border *ngIf="article &amp;&amp; (article.type === 'listing' || article.type === 'buyer' || article.type === 'advisor')" [top]="590" [floatAfter]="1000"></app-footer-border>
<div class="app-footer-margin" *ngIf="article &amp;&amp; !(article.type === 'listing' || article.type === 'buyer')"></div>
<!--app-footer-border(*ngIf="article && (article.type === 'listing' || article.type === 'buyer')")-->