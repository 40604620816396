
<div class="video-tile-container">
  <div class="banner" *ngIf="article.tileImgUrl" [style.background-image]="sanitizer.bypassSecurityTrustStyle(imageUrl(article.tileImgUrl))"></div>
  <div class="content">
    <div class="content-container">
      <div class="tagline">{{ article.tagline }}</div>
      <div class="title" [innerHTML]="getTitle(markdownToHtml)"></div>
    </div>
  </div>
  <div class="vimeo-play-logo" (click)="go(article)"></div>
</div>