
<app-content-banner [article]="bannerArticle"></app-content-banner>
<app-submenu *ngIf="bannerArticle &amp;&amp; bannerArticle.submenu &amp;&amp; bannerArticle.submenu.length &gt; 0" [items]="bannerArticle.submenu"></app-submenu>
<div class="blog-container">
  <div class="blog-toolbar">
    <div class="article-count" *ngIf="getArticles().length &gt; 0">{{articleCountText()}}</div>
    <div class="selects">
      <div class="filter-select">
        <select [(ngModel)]="filterCategory">
          <option *ngFor="let option of filterOptions" value="{{option[0]}}">{{ option[1] }}&nbsp;&nbsp;&nbsp;&nbsp;</option>
        </select>
        <div class="component-icon-container">
          <div class="component-icon arrow-down"></div>
        </div>
      </div>
      <div class="sort-select">
        <select [(ngModel)]="sortField">
          <option *ngFor="let option of sortOptions" value="{{option[0]}}">{{ option[1] }}&nbsp;&nbsp;&nbsp;&nbsp;</option>
        </select>
        <div class="component-icon-container">
          <div class="component-icon arrow-down"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="loader-spinner" *ngIf="loadingContent"></div>
  <div class="blog-list-container"><a class="seo-link" *ngFor="let article of getArticles();" [href]="article.path">{{ article.title }}</a>
    <div class="blog-entry" *ngFor="let article of getArticles();" (click)="go(article)">
      <div class="blog-entry-container">
        <div class="blog-entry-new" *ngIf="is(article, 'new')">
          <div class="blog-entry-new-container">New</div>
          <div class="blog-entry-new-tail"></div>
        </div>
        <div class="blog-entry-contents">
          <div class="banner-image" [style.background-image]="backgroundImageStyle(article)"></div>
          <div class="blog-copy">
            <div class="blog-created-date">{{ article.created | date:'longDate' }}</div>
            <div class="blog-title">{{ article.title }}</div>
            <div class="blog-break"></div>
            <div class="blog-description">{{ article.description }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-forms-newsletter></app-forms-newsletter><br>
<app-footer-border></app-footer-border>