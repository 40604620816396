import {Component, OnDestroy, ElementRef, OnInit} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AppService} from 'app/services/app';
import {LogicService} from 'app/services/logic';
import {Article} from 'app/models/article';
import {Profile} from 'app/models/profile';

import * as moment from 'moment-timezone';

type TileTypes = Article | null;

interface QueryParams {
  favorites?: boolean;
  type?: 'listing' | 'buyer' | 'advisor';
  price: {
    low: number;
    high: number;
  };
  score: {
    low: number;
    high: number;
  };
  industry: string;
  location: {
    value: string;
    display: string;
    checked: boolean;
  }[];
}

@Component({
  moduleId: module.id,
  selector: '.app-view-marketplace-main',
  templateUrl: 'main.component.html',
  styleUrls: ['main.component.less']
})
export class ViewsMarketplaceMainComponent implements OnDestroy, OnInit {

  public industries: string[][] = [];
  public industriesRaw: string[][] = [
    ['11', 'Agriculture, Forestry, Fishing and Hunting'],
    ['21', 'Mining'],
    ['22', 'Utilities'],
    ['23', 'Construction'],
    ['31,32,33', 'Manufacturing'],
    ['42', 'Wholesale Trade'],
    ['44,45', 'Retail Trade'],
    ['48,49', 'Transportation & Warehousing'],
    ['51', 'Information'],
    ['52', 'Finance & Insurance'],
    ['53', 'Real Estate Rental & Leasing'],
    ['54', 'Professional, Scientific & Technical Services'],
    ['55', 'Management of Companies & Enterprises'],
    ['56', 'Administrative, Support & Waste Management Services'],
    ['61', 'Educational Services'],
    ['62', 'Health Care & Social Assistance'],
    ['71', 'Arts, Entertainment and Recreation'],
    ['72', 'Accommodation and Food Services'],
    ['92', 'Public Administration'],
    ['81', 'Other Services']
  ];

  public searchStates: string[ ] = [];
  public locationsRaw = [
    {value: 'AL', display: 'Alabama', checked: false},
    {value: 'AK', display: 'Alaska', checked: false},
    {value: 'AS', display: 'American Samoa', checked: false},
    {value: 'AZ', display: 'Arizona', checked: false},
    {value: 'AR', display: 'Arkansas', checked: false},
    {value: 'CA', display: 'California', checked: false},
    {value: 'CO', display: 'Colorado', checked: false},
    {value: 'CT', display: 'Connecticut', checked: false},
    {value: 'DE', display: 'Delaware', checked: false},
    {value: 'DC', display: 'District Of Columbia', checked: false},
    {value: 'FL', display: 'Florida', checked: false},
    {value: 'GA', display: 'Georgia', checked: false},
    {value: 'GU', display: 'Guam', checked: false},
    {value: 'HI', display: 'Hawaii', checked: false},
    {value: 'ID', display: 'Idaho', checked: false},
    {value: 'IL', display: 'Illinois', checked: false},
    {value: 'IN', display: 'Indiana', checked: false},
    {value: 'IA', display: 'Iowa', checked: false},
    {value: 'KS', display: 'Kansas', checked: false},
    {value: 'KY', display: 'Kentucky', checked: false},
    {value: 'LA', display: 'Louisiana', checked: false},
    {value: 'ME', display: 'Maine', checked: false},
    {value: 'MH', display: 'Marshall Islands', checked: false},
    {value: 'MD', display: 'Maryland', checked: false},
    {value: 'MA', display: 'Massachusetts', checked: false},
    {value: 'MI', display: 'Michigan', checked: false},
    {value: 'MN', display: 'Minnesota', checked: false},
    {value: 'MS', display: 'Mississippi', checked: false},
    {value: 'MO', display: 'Missouri', checked: false},
    {value: 'MT', display: 'Montana', checked: false},
    {value: 'NE', display: 'Nebraska', checked: false},
    {value: 'NV', display: 'Nevada', checked: false},
    {value: 'NH', display: 'New Hampshire', checked: false},
    {value: 'NJ', display: 'New Jersey', checked: false},
    {value: 'NM', display: 'New Mexico', checked: false},
    {value: 'NY', display: 'New York', checked: false},
    {value: 'NC', display: 'North Carolina', checked: false},
    {value: 'ND', display: 'North Dakota', checked: false},
    {value: 'OH', display: 'Ohio', checked: false},
    {value: 'OK', display: 'Oklahoma', checked: false},
    {value: 'OR', display: 'Oregon', checked: false},
    // { value: "PW", display: "Palau", checked: false },
    {value: 'PA', display: 'Pennsylvania', checked: false},
    {value: 'PR', display: 'Puerto Rico', checked: false},
    {value: 'RI', display: 'Rhode Island', checked: false},
    {value: 'SC', display: 'South Carolina', checked: false},
    {value: 'SD', display: 'South Dakota', checked: false},
    {value: 'TN', display: 'Tennessee', checked: false},
    {value: 'TX', display: 'Texas', checked: false},
    {value: 'UT', display: 'Utah', checked: false},
    {value: 'VT', display: 'Vermont', checked: false},
    {value: 'VI', display: 'Virgin Islands', checked: false},
    {value: 'VA', display: 'Virginia', checked: false},
    {value: 'WA', display: 'Washington', checked: false},
    {value: 'WV', display: 'West Virginia', checked: false},
    {value: 'WI', display: 'Wisconsin', checked: false},
    {value: 'WY', display: 'Wyoming', checked: false}
  ];

  public query: QueryParams = {
    price: {
      low: null,
      high: null
    },
    score: {
      low: null,
      high: null
    },
    industry: '.*',
    location: []
  };

  public authenticatedProfile: Profile = null;
  public article: Article = null;
  public tiles: TileTypes[] = [];
  public tilesFiltered: TileTypes[] = [];

  public advancedSearch: boolean = false;

  public termTypeMode: boolean = false;
  public searchTermsInput: string = '';

  public searchTerms: string[] = [];

  public typeOptions: string[][] = [
    ['listing', 'Sellers'],
    ['buyer', 'Buyers'],
    ['advisor', 'Advisors'],
  ];

  public $searchHandles: {
    marketplaceToolbar: JQuery;
    toolbarTop: JQuery;
    homeButton: JQuery;
    searchKeywordTerms: JQuery;
    searchWindow: JQuery;
    searchAdvancedMenu: JQuery;
    toolbarMenu: JQuery;
  } = null;

  public windowResizeHandle: () => void = null;

  public $el: JQuery = null;
  public subscriptions: any[] = [];
  public gettingTiles: number = 0;

  constructor(public appService: AppService,
              public logicService: LogicService,
              public router: Router,
              public route: ActivatedRoute,
              public elementRef: ElementRef,
              public location: Location) {

    // use custom toolbar in marketplace
    this.appService.toolbar.disabled = true;
    this.appService.contentLoading(true);
    this.appService.toolbar.whiteOverContent = false;
    this.appService.toolbar.backgroundColor = null;
    this.appService.titleService.setTitle('Exio - Marketplace');

    this.$el = $(elementRef.nativeElement);

    this.resetQueryTerms();

  }

  public processResize(): void {

    window.setTimeout(() => {

      const $window = $(window);
      let remainingWidthSearchWindow = $window.width();

      this.$searchHandles.marketplaceToolbar.width(remainingWidthSearchWindow);
      this.$searchHandles.toolbarTop.width(remainingWidthSearchWindow);

      [
        'homeButton',
        'searchKeywordTerms',
        'searchAdvancedMenu'
      ].forEach((field) => {

        if (this.$searchHandles.hasOwnProperty(field)) {
          remainingWidthSearchWindow -= this.$searchHandles[field].outerWidth(true);
        }

      });

      if (remainingWidthSearchWindow < 0) {
        remainingWidthSearchWindow = 0;
      }

      remainingWidthSearchWindow = Math.floor(remainingWidthSearchWindow);

      this.$searchHandles.searchWindow.width(remainingWidthSearchWindow);

    });

  }

  public initJQueryHandles(): void {

    if (this.$el) {

      const $marketplaceToolbar = this.$el.find('.marketplace-toolbar');
      const $toolbar = $marketplaceToolbar.find('.toolbar-top');

      this.$searchHandles = {
        marketplaceToolbar: $marketplaceToolbar,
        toolbarTop: $toolbar,
        homeButton: $toolbar.find('.home-button'),
        searchKeywordTerms: $toolbar.find('.search-keyword-terms'),
        searchWindow: $toolbar.find('.search-window'),
        searchAdvancedMenu: $toolbar.find('.search-advanced-menu'),
        toolbarMenu: $toolbar.find('.toolbar-menu')
      };

    }

  }

  ngOnDestroy(): void {

    if (this.windowResizeHandle) {
      $(window).off('resize', this.windowResizeHandle);
      this.windowResizeHandle = null;
    }

    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.appService.toolbar.disabled = false;

  }

  ngOnInit(): void {

    document.body.scrollTop = 0;
    this.appService.contentLoading(true);

    this.initJQueryHandles();

    if (!this.windowResizeHandle) {
      this.windowResizeHandle = () => {
        this.processResize();
      };

      $(window).on('resize', this.windowResizeHandle);
    }

    this.getTiles('listing');
    this.getTiles('buyer');
    this.getTiles('advisor');

    const profileEvent = (profile?: Profile) => {
      this.authenticatedProfile = profile || null;
      this.applyFilter();
    };

    // get logged in user's profile
    this.subscriptions.push(this.appService.getAuthenticatedProfile({
      next: profileEvent,
      error: () => {
        profileEvent(null);
      },
      complete: () => {
        profileEvent(null);
      }
    }));

    // get query params
    this.subscriptions.push(this.route
      .params
      .subscribe((params: { filter?: 'private-sellers' | 'premier-buyers' | 'advisors' | 'favorites'; favorites?: string }) => {

        this.resetQueryTerms();
        this.appService.toolbar.disabled = true;

        if (params.favorites === 'true' || params.filter === 'favorites') {
          this.query.favorites = true;
        } else if (params.filter) {

          switch (params.filter) {
            case 'advisors':
              this.selectTypeFilter('advisor');
              break;
            case 'private-sellers':
              this.selectTypeFilter('listing');
              break;
            case 'premier-buyers':
              this.selectTypeFilter('buyer');
              break;
          }

        } else {
          this.selectTypeFilter('listing');
        }

        this.applyFilter();

      }));

    this.appService
      .articleModel
      .getByPath({
        path: 'about-marketplace'
      })
      .then((article) => {

        if (article) {
          this.article = article;
        } else {
          this.article = null;
        }

      })
      .catch(() => {
        this.article = null;
      });

    this.processResize();

  }

  public getTiles(type: 'listing' | 'buyer' | 'advisor'): void {

    this.gettingTiles++;

    const always = (result?) => {

      if (result) {
        this.tiles = this.tiles.concat(result.articles);
      }

      this.gettingTiles--;

      if (this.gettingTiles < 1) {
        this.gettingTiles = 0;
        this.updateSearchLists();
        this.applyFilter();
        this.appService.contentLoading(false);
      }

    };

    // get all listings
    this.appService
      .articleModel
      .search({
        limit: 10000,
        query: {
          type: type
        }
      })
      .then(always)
      .catch(() => {
        always();
      });


  }

  public getTitle(): string {
    switch (this.query.type) {
      case 'listing':
        return 'Private Sellers';
      case 'buyer':
        return 'Premier Buyers';
      case 'advisor':
        return 'M&A Advisors';
    }
  }

  public updateSearchLists(): void {

    let tileIndustries = {};
    let tileLocations = {};

    this.tiles.forEach((tile) => {
      if (tile && tile.type === this.query.type && tile.data) {

        if (typeof tile.data.naicsCode === 'string' && (<string>tile.data.naicsCode).trim().length > 0) {

          let naicsCodes = (<string>tile.data.naicsCode).split(',');
          naicsCodes.forEach((naicsCode) => {
            let codePrefix = naicsCode.trim().substring(0, 2);
            tileIndustries[codePrefix] = true;
          });
        }
        if (typeof tile.data.buyerInterestsNAICS === 'string' && (<string>tile.data.buyerInterestsNAICS).trim().length > 0) {

          let naicsCodes = (<string>tile.data.buyerInterestsNAICS).split(',');
          naicsCodes.forEach((naicsCode) => {
            let codePrefix = naicsCode.trim().substring(0, 2);
            tileIndustries[codePrefix] = true;
          });
        }

        if (typeof tile.data.listingLocationState === 'string' && (<string>tile.data.listingLocationState).trim().length === 2) {
          let states = (<string>tile.data.listingLocationState).split(',');
          states.forEach((state) => {
            tileLocations[state.trim().toUpperCase()] = true;
          });
        }

        if (typeof tile.data.buyerInterestsByState === 'string' && (<string>tile.data.buyerInterestsByState).trim().length > 2) {
          let states = (<string>tile.data.buyerInterestsByState).split(',');
          states.forEach((state) => {
            tileLocations[state.trim().toUpperCase()] = true;
          });
        }

      }
    });

    this.query.location = [];
    this.locationsRaw.forEach((location) => {
      location.checked = false;
      this.query.location.push(location);
      // if ( tileLocations[ location.value ] ) {
      // 	this.query.location.push( location );
      // }
    });

    this.industries = [];
    window.setTimeout(() => {
      this.industriesRaw.forEach((industry) => {

        this.industries.push(industry);

        // let parts = industry[ 0 ].split( ',' );
        // let matched = false;
        //
        // parts.forEach( ( part ) => {
        // 	if ( matched ) {
        // 		return;
        // 	}
        // 	if ( tileIndustries.hasOwnProperty( part ) ) {
        // 		matched = true;
        // 		this.industries.push( industry );
        // 	}
        // } );

      });

      this.industries = this.industries.sort((industryA, industryB) => {

        let textA = industryA[1].trim().toUpperCase();
        let textB = industryB[1].trim().toUpperCase();

        if (textA < textB) {
          return -1;
        } else if (textA > textB) {
          return 1;
        } else {
          return 0;
        }

      });

      this.industries.unshift(['.*', 'Any']);

    });

  }

  public resetQueryTerms(type?: 'listing' | 'buyer' | 'advisor'): void {

    this.query = {
      type: type ? type : 'listing',
      price: {
        low: null,
        high: null
      },
      score: {
        low: null,
        high: null
      },
      industry: '.*',
      location: []
    };
    this.industries = [];

    window.setTimeout(() => {
      this.updateSearchLists();
    });

  }

  public clearAdvanced(): void {

    this.resetQueryTerms(this.query.type);
    this.applyFilter();

  }

  public applyFilter(): void {

    let query = this.query;

    this.tilesFiltered = [];

    this.hideAdvanced();

    this.searchStates = [];

    query.location.forEach((location) => {
      if (location.checked) {
        this.searchStates.push(location.value);
      }
    });

    window.setTimeout(() => {
      this.tilesFiltered = this.tiles
        .filter((tile: Article) => {

          if (!tile) {
            return false;
          }

          if (query.type && tile.type !== query.type) {
            return false;
          }

          if (query.favorites && this.authenticatedProfile && Array.isArray(this.authenticatedProfile.favoriteListings)) {

            if (this.authenticatedProfile.favoriteListings.indexOf(tile.id) < 0) {
              return false;
            }

          }

          return this.matchesSearch(tile);

        })
        .sort((tileA: Article, tileB: Article) => {
          return new Date(tileB.created).getTime() - new Date(tileA.created).getTime();
        });
    });

  }

  public matchesSearch(tile: Article): boolean {

    let result = false;

    // typically a potential seller looks for both buyers and advisors, so the filtering is the same
    if (this.query.type === 'buyer' || this.query.type === 'advisor') {

      result = this.matchesSearchKeywords(tile) &&
        this.matchesBuyerSearchIndustry(tile) &&
        this.matchesBuyerSearchPrice(tile) &&
        this.matchesBuyerSearchLocation(tile);

    } else if (this.query.type === 'listing') {

      result = this.matchesSearchKeywords(tile) &&
        this.matchesListingSearchPrice(tile) &&
        this.matchesListingSearchScore(tile) &&
        this.matchesListingSearchIndustry(tile) &&
        this.matchesListingSearchLocation(tile);

    }

    return result;

  }

  public matchesListingSearchLocation(tile: Article): boolean {

    if (this.query.type === 'buyer') {
      return true;
    }

    if (this.searchStates.length < 1) {
      return true;
    }

    if (typeof tile.data.listingLocationState !== 'string') {
      return false;
    }

    let found = false;
    this.searchStates.forEach((state) => {

      if (found) {
        return;
      }

      found = state.toLowerCase().trim() === (<string>tile.data.listingLocationState).trim().toLowerCase();

    });

    return found;

  }

  public matchesBuyerSearchLocation(tile: Article): boolean {

    if (this.searchStates.length < 1) {
      return true;
    }

    if (typeof tile.data.buyerInterestsByState !== 'string') {
      return false;
    }

    let found = false;
    this.searchStates.forEach((state) => {

      if (found) {
        return;
      }
      state = state.toUpperCase();

      let value = (<string>tile.data.buyerInterestsByState).trim().toUpperCase();

      found = value.indexOf(state) > -1;

    });

    return found;

  }

  public matchesListingSearchIndustry(tile: Article): boolean {

    if (this.query.type === 'buyer') {
      return false;
    }

    let keep = false;

    if (typeof this.query.industry === 'string' && this.query.industry.trim().length > 0) {

      let prefixes = this.query.industry.split(',');

      prefixes.forEach((prefix) => {

        if (keep) {
          return;
        }

        let regex = new RegExp('^' + prefix.trim());

        if (typeof tile.data.naicsCode === 'string' && (<string>tile.data.naicsCode).match(regex)) {
          keep = true;
        }

      });

    } else {
      keep = true;
    }

    return keep;

  }

  public matchesBuyerSearchIndustry(tile: Article): boolean {

    let keep = false;

    if (typeof this.query.industry === 'string' && this.query.industry.trim().length > 0 && this.query.industry !== '.*') {

      if (typeof tile.data.buyerInterestsNAICS !== 'string') {
        return keep;
      }

      let prefixes = this.query.industry.split(',');

      let buyerInterestsNAICSS = <string>tile.data.buyerInterestsNAICS;

      let naicsCodes = buyerInterestsNAICSS.split(',');

      prefixes.forEach((prefix) => {

        if (keep) {
          return;
        }

        let regex = new RegExp('^' + prefix.trim());

        naicsCodes.forEach((naicsCode) => {

          if (keep) {
            return;
          }

          keep = !!naicsCode.trim().match(regex);

        });

      });

    } else {
      keep = true;
    }

    return keep;

  }

  public matchesListingSearchScore(tile: Article): boolean {

    if (this.query.type === 'buyer') {
      return true;
    }

    if (!tile.data.hasOwnProperty('exioScore')) {
      return false;
    }

    if (typeof this.query.score.low === 'number' && tile.data.exioScore < this.query.score.low) {
      return false;
    }

    if (typeof this.query.score.high === 'number' && tile.data.exioScore > this.query.score.high) {
      return false;
    }

    return true;
  }

  public matchesListingSearchPrice(tile: Article): boolean {

    if (this.query.type === 'buyer') {
      return true;
    }

    if (!tile.data.hasOwnProperty('listingPrice')) {
      return false;
    }

    if (typeof this.query.price.low === 'number' &&
      typeof tile.data.listingPrice === 'number' && tile.data.listingPrice < this.query.price.low) {
      return false;
    }

    if (typeof this.query.price.high === 'number' &&
      typeof tile.data.listingPrice === 'number' && tile.data.listingPrice > this.query.price.high) {
      return false;
    }

    return true;

  }

  public matchesBuyerSearchPrice(tile: Article): boolean {

    if (typeof tile.data.buyerInterestsPriceMin !== 'number' ||
      typeof tile.data.buyerInterestsPriceMax !== 'number') {
      return false;
    }

    if (typeof this.query.price.low === 'number') {
      return tile.data.buyerInterestsPriceMin <= this.query.price.low && tile.data.buyerInterestsPriceMax >= this.query.price.low;
    }

    return true;

  }

  public matchesSearchKeywords(tile: Article): boolean {

    let missedOne = false;

    let reference = JSON.stringify(tile).toLowerCase();

    this.searchTerms.forEach((term) => {

      if (missedOne) {
        return;
      }

      term = term.trim().toLowerCase();
      if (reference.indexOf(term) < 0) {
        missedOne = true;
      }

    });

    return !missedOne;

  }

  public disableAds(): boolean {
    return this.query.favorites === true || this.searchTerms.length > 0;
  }

  public removeSearchTerm(term: string): void {
    let index = this.searchTerms.indexOf(term);
    if (index < 0) {
      return;
    }
    this.searchTerms.splice(index, 1);
    this.applyFilter();
    this.processResize();
  }

  public setTermTypeMode(): void {

    this.hideAdvanced();
    this.termTypeMode = true;
    this.searchTermsInput = '';
    let $input = this.$el.find('.search-input input');

    window.setTimeout(() => {
      $input.focus();
    });

    this.processResize();

  }

  public unsetTermTypeMode(): void {
    this.termTypeMode = false;

    if (typeof this.searchTermsInput === 'string') {

      if (this.searchTermsInput.trim().length > 0) {

        let terms = this.searchTermsInput.split(/[, ]+/);

        terms.forEach((term) => {
          term = term.trim();
          if (term.length < 1) {
            return;
          }

          if (this.searchTerms.indexOf(term) < 0) {
            this.searchTerms.push(term);
          }

        });

      }

    }


    this.searchTermsInput = '';

    this.applyFilter();

    this.processResize();

  }

  public searchTermsInputKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.unsetTermTypeMode();
    }
    if (event.key === 'Escape') {
      this.searchTermsInput = '';
      this.unsetTermTypeMode();
    }
  }

  public selectTypeFilter(type: 'listing' | 'buyer' | 'advisor'): void {

    if (this.query.type !== type) {
      this.resetQueryTerms();

      this.query.type = type;

      let url = null;

      switch (type) {
        case 'listing':
          url = 'private-sellers';
          break;
        case 'buyer':
          url = 'premier-buyers';
          break;
        case 'advisor':
          url = 'advisors';
          break;
      }

      if (url) {
        this.location.replaceState('/marketplace/' + url);
      }

      this.updateSearchLists();
      this.applyFilter();

    }

  }

  public showGeneralToolbar(): void {
    this.hideAdvanced();
    this.appService.toolbarMenuExpand();
  }

  public showAdvanced(): void {
    this.toggleAdvanced(true);
  }

  public hideAdvanced(): void {
    this.toggleAdvanced(false);
  }

  public toggleAdvanced(show?: boolean): void {

    let $body = $('html body');

    if (show === undefined) {
      this.advancedSearch = !this.advancedSearch;
    } else {
      this.advancedSearch = show;
    }

    if (this.advancedSearch) {

      let $window = $(window);
      let $toolbarTop = this.$el.find('.toolbar-top');
      let $search = this.$el.find('.advanced-search');

      $search.height($window.height() - $toolbarTop.outerHeight(true));

      $body.css({overflow: 'hidden'});

      this.setupSliders();

    } else {
      $body.css({overflow: 'visible'});
    }

  }

  public setupSliders(): void {

    window.setTimeout(() => {

      let $search = this.$el.find('.advanced-search');
      let $sliders = $search.find('.slider');

      $sliders.each((i, slider) => {

        let $slider = $(slider);
        let $labels = $slider.next();
        let $labelMin = $labels.find('.label-min');
        let $labelMax = $labels.find('.label-max');
        let $labelLow = $labels.find('.label-low');
        let $labelHigh = $labels.find('.label-high');

        // if ( $slider.hasClass( 'slider-setup' ) ) {
        // 	return;
        // }
        // $slider.addClass( 'slider-setup' );

        let low = parseInt($slider.data('low'), 10);
        let high = parseInt($slider.data('high'), 10);
        let lowBind = $slider.data('bind-low');
        let highBind = $slider.data('bind-high');
        let step = parseInt($slider.data('step'), 10);
        let roundingFactor = parseInt($slider.data('rounding-factor'), 10);
        if (isNaN(roundingFactor)) {
          roundingFactor = 1;
        }

        let rangeSlider = $labelHigh.length > 0 && typeof highBind === 'string' && highBind.trim().length > 0;

        let ratioPosition = (value): number => {
          return Math.max(0, (value - low) / (high - low));
        };

        let applyRounding = (value: number): number => {

          if (typeof roundingFactor !== 'number' || roundingFactor === 0) {
            return value;
          }

          return Math.round(value / roundingFactor) * roundingFactor;

        };

        let isCollision = ($els: JQuery[]) => {

          let limits = {
            top: null,
            left: null,
            right: null,
            bottom: null
          };

          let collision = false;

          $els.forEach(($el) => {

            if (collision) {
              return;
            }

            let offset = $el.offset();
            let height = $el.height();
            let width = $el.width();

            let myLimits = {
              top: offset.top,
              left: offset.left,
              right: offset.left + width,
              bottom: offset.top + height
            };

            let isTop = (): boolean => {
              return myLimits.top >= limits.top && myLimits.top <= limits.bottom;
            };
            let isLeft = (): boolean => {
              return myLimits.left >= limits.left && myLimits.left <= limits.right;
            };
            let isRight = (): boolean => {
              return myLimits.right >= limits.left && myLimits.right <= limits.right;
            };
            let isBottom = (): boolean => {
              return myLimits.bottom >= limits.top && myLimits.bottom <= limits.bottom;
            };

            if (limits.top !== null) {
              collision =
                (isTop() && isLeft()) ||
                (isTop() && isRight()) ||
                (isBottom() && isLeft()) ||
                (isBottom() && isRight());
            }

            if (collision) {
              return;
            }

            // expand collision area
            [
              'top',
              'left'
            ].forEach((field) => {
              if (limits[field] === null || myLimits[field] < limits[field]) {
                limits[field] = myLimits[field];
              }
            });
            [
              'right',
              'bottom'
            ].forEach((field) => {
              if (limits[field] === null || myLimits[field] > limits[field]) {
                limits[field] = myLimits[field];
              }
            });

          });

          return collision;

        };

        let position = (low: number, high?: number): void => {

          low = applyRounding(low);

          let lowRatio = ratioPosition(low);

          let highRatio = null;
          if (rangeSlider) {
            high = applyRounding(high);
            highRatio = ratioPosition(high);
          }

          $labelLow.css({left: (lowRatio * 100) + '%'});
          if (rangeSlider) {
            $labelHigh.css({left: (highRatio * 100) + '%'});
          }

          this.logicService.setDataValue(this.query, lowBind, low);
          if (rangeSlider) {
            this.logicService.setDataValue(this.query, highBind, high);
          }

          if (isCollision([$labelMin, $labelLow])) {
            $labelMin.addClass('hide-me');
          } else {
            $labelMin.removeClass('hide-me');
          }

          if (rangeSlider) {
            if (isCollision([$labelMax, $labelHigh])) {
              $labelMax.addClass('hide-me');
            } else {
              $labelMax.removeClass('hide-me');
            }
          }

          // window.setTimeout( requestAnimationFrame( () => {
          //
          // 	// slider limit
          // 	// let $labelMin = $labels.find( '.label-min' );
          // 	// let $labelMax = $labels.find( '.label-max' );
          //
          // 	// user input
          // 	// let $labelLow = $labels.find( '.label-low' );
          // 	// let $labelHigh = $labels.find( '.label-high' );
          //
          //
          //
          // } ) );

        };

        let initialLowRaw = this.logicService.getDataValue(this.query, lowBind);
        let initialHighRaw = this.logicService.getDataValue(this.query, highBind);

        let initialLow: number = null;
        let initialHigh: number = null;

        if (initialLowRaw === null) {

          if (rangeSlider) {

            initialLow = low;
          } else {

            initialLow = Math.floor(((low + high) / 2) + 0.5);
          }
        } else {

          initialLow = <number>initialLowRaw;
        }

        if (initialHighRaw === null) {
          initialHigh = high;
        } else {
          initialHigh = <number>initialHighRaw;
        }

        let config: any = {};

        if (rangeSlider) {
          config = {
            range: true,
            min: low,
            max: high,
            values: [initialLow, initialHigh],
            slide: (event, ui) => {
              position(ui.values[0], ui.values[1]);
            }
          };
          // this.logicService.setDataValue( this.query, lowBind, initialLow );
          // this.logicService.setDataValue( this.query, highBind, initialHigh );

        } else {
          config = {
            min: low,
            max: high,
            value: initialLow,
            slide: (event, ui) => {
              position(ui.value);
            }
          };
          // this.logicService.setDataValue( this.query, lowBind, initialLow );
        }

        if (!isNaN(step)) {
          config.step = step;
        }

        $slider.slider(config);

        window.setTimeout(() => {
          if (rangeSlider) {
            position(initialLow, initialHigh);
          } else {
            position(initialLow);
          }
        });

      });
    });

  }

}
