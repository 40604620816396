
<div class="marketplace-toolbar">
  <div class="toolbar-top">
    <div class="home-button" routerLink="/">
      <div class="home-button-icon">
        <div class="component-icon logo-boxes orange"></div>
      </div>
    </div>
    <div class="search-keyword-terms">
      <div class="search-keyword-term-container" *ngFor="let term of searchTerms">
        <div class="search-keyword-term">
          <div class="search-keyword-term-title">{{ term }}</div>
          <div class="search-keyword-term-remove-handle-container">
            <div class="search-keyword-term-remove-handle" (click)="removeSearchTerm(term)">
              <div class="component-icon close-handle-gray"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="search-window">
      <div class="search-icon-container" [class.hide-me]="termTypeMode" (click)="setTermTypeMode()">
        <div class="search-icon">
          <div class="component-icon search"></div>
        </div>
      </div>
      <div class="search-title desktop-view" [class.hide-me]="termTypeMode" (click)="setTermTypeMode()">Keyword Search</div>
      <div class="search-title mobile-view" [class.hide-me]="termTypeMode" (click)="setTermTypeMode()">Keyword</div>
      <div class="search-input" [class.hide-me]="!termTypeMode">
        <input type="text" [(ngModel)]="searchTermsInput" (blur)="unsetTermTypeMode()" (keyup)="searchTermsInputKeyUp($event)">
      </div>
    </div>
    <div class="search-advanced-menu">
      <div class="advanced-title" (click)="toggleAdvanced()" [class.active]="advancedSearch">Advanced</div>
    </div>
    <div class="toolbar-menu">
      <div class="toolbar-menu-icon" (click)="showGeneralToolbar()">
        <div class="component-icon menu-gray-light"></div>
      </div>
    </div>
  </div>
  <div class="advanced-search" [class.show-me]="advancedSearch">
    <div class="close-handle" (click)="hideAdvanced()">
      <div class="component-icon close-handle-gray"></div>
    </div>
    <div class="advanced-search-container">
      <div class="title">Advanced Search</div>
      <div class="search-parameters" *ngIf="query.type === 'listing'">
        <!--.input-row-->
        <!--	.input-title Listing Types-->
        <!--	.input-container-->
        <!--		label All-->
        <!--			input(type="radio", name="listing-types", value="all", [(ngModel)]="query.listingSubType")-->
        <!--		label Market Maker-->
        <!--			input(type="radio", name="listing-types", value="market-maker", [(ngModel)]="query.listingSubType")-->
        <!--		label Incubator-->
        <!--			input(type="radio", name="listing-types", value="incubator", [(ngModel)]="query.listingSubType")-->
        <div class="input-break" *ngIf="industries.length &gt; 0"></div>
        <div class="input-row" *ngIf="industries.length &gt; 0">
          <div class="input-title">Industry</div>
          <div class="input-container">
            <div class="input-type-dropdown" [options]="industries" [sortOptions]="'none'" [(value)]="query.industry"></div>
          </div>
        </div>
        <div class="input-break"></div>
        <div class="input-row">
          <div class="input-title">Price Range</div>
          <div class="input-container">
            <div class="slider" [attr.data-low]="2000000" [attr.data-high]="150000000" [attr.data-step]="1000000" [attr.data-rounding-factor]="1000000" [attr.data-bind-low]="'price.low'" [attr.data-bind-high]="'price.high'"></div>
            <div class="slide-labels-container">
              <div class="slide-labels">
                <div class="label-min">$2M</div>
                <div class="label-max">$150M +</div>
                <div class="label-low">{{ query.price.low / 1000000 | currency:'USD':true:'1.0-0' }}M</div>
                <div class="label-high">{{ query.price.high / 1000000 | currency:'USD':true:'1.0-0' }}M</div>
              </div>
            </div>
          </div>
        </div>
        <div class="input-break"></div>
        <div class="input-row">
          <div class="input-title">Exio Score</div>
          <div class="input-container">
            <div class="slider" [attr.data-low]="0" [attr.data-high]="100" [attr.data-bind-low]="'score.low'" [attr.data-bind-high]="'score.high'"></div>
            <div class="slide-labels-container">
              <div class="slide-labels">
                <div class="label-min">0</div>
                <div class="label-max">100</div>
                <div class="label-low">{{ query.score.low }}</div>
                <div class="label-high">{{ query.score.high }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="input-break"></div>
        <div class="input-row">
          <div class="input-title">Locations</div>
          <div class="input-container">
            <label class="width-20" *ngFor="let option of query.location">{{ option.display }}
              <input type="checkbox" [(ngModel)]="option.checked">
            </label>
          </div>
        </div>
      </div>
      <div class="search-parameters" *ngIf="query.type === 'buyer' || query.type === 'advisor'">
        <div class="input-row">
          <div class="input-title">Your Company's<br>Primary Industry</div>
          <div class="input-container">
            <div class="input-type-dropdown" *ngIf="industries.length &gt; 0" [options]="industries" [sortOptions]="'none'" [(value)]="query.industry"></div>
          </div>
        </div>
        <div class="input-break"></div>
        <div class="input-row">
          <div class="input-title">Your Company's<br>Cash Price</div>
          <div class="input-container">
            <div class="slider" [attr.data-low]="2000000" [attr.data-high]="150000000" [attr.data-step]="1000000" [attr.data-rounding-factor]="1000000" [attr.data-bind-low]="'price.low'"></div>
            <div class="slide-labels-container">
              <div class="slide-labels">
                <div class="label-min">$2M</div>
                <div class="label-max">$150M +</div>
                <div class="label-low">{{ query.price.low / 1000000 | currency:'USD':true:'1.0-0' }}M</div>
              </div>
            </div>
          </div>
        </div>
        <div class="input-break"></div>
        <!--.input-row-->
        <!--	.input-title Your Company's-->
        <!--		br-->
        <!--		| Exio Score-->
        <!--	.input-container-->
        <!--		.slider([attr.data-low]="0", [attr.data-high]="100", [attr.data-bind-low]="'score.low'")-->
        <!--		.slide-labels-container-->
        <!--			.slide-labels-->
        <!--				.label-min 0-->
        <!--				.label-max 100-->
        <!--				.label-low {{ query.score.low }}-->
        <!--.input-break-->
        <div class="input-row">
          <div class="input-title">Your Company's<br>Primary Location(s)</div>
          <div class="input-container">
            <label class="width-20" *ngFor="let option of query.location">{{ option.display }}
              <input type="checkbox" [(ngModel)]="option.checked">
            </label>
          </div>
        </div>
      </div>
      <div class="search-apply-container">
        <div class="search-apply" (click)="applyFilter()">Apply</div>
        <div class="search-cancel" (click)="clearAdvanced()">Clear</div>
      </div>
    </div>
  </div>
  <div class="toolbar-middle">
    <div class="title">{{ getTitle() }}</div>
  </div>
  <div class="toolbar-bottom">
    <div class="article-count" *ngIf="tilesFiltered.length === 1">{{ tilesFiltered.length }} Result</div>
    <div class="article-count" *ngIf="tilesFiltered.length !== 1">{{ tilesFiltered.length }} Results</div>
    <div class="article-type-toggle">
      <div class="article-type-toggle-container">
        <div class="article-type-toggle-item-container" *ngFor="let option of typeOptions" [class.unselected-item]="option[0] !== query.type" [class.selected-item]="option[0] === query.type">
          <div class="article-type-toggle-item" (click)="selectTypeFilter(option[0])">{{ option[1] }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="app-content-tiles" *ngIf="query.type === 'buyer' &amp;&amp; tilesFiltered.length &gt; 0" [articles]="tilesFiltered" [mode]="'normal'" [disableAd]="disableAds()"></div>
<div class="app-content-tiles" *ngIf="query.type === 'listing' &amp;&amp; tilesFiltered.length &gt; 0" [articles]="tilesFiltered" [mode]="'normal'" [disableAd]="disableAds()"></div>
<div class="app-content-tiles" *ngIf="query.type === 'advisor' &amp;&amp; tilesFiltered.length &gt; 0" [articles]="tilesFiltered" [mode]="'normal'" [disableAd]="disableAds()"></div>
<div class="no-listing-results-container" *ngIf="tilesFiltered.length &lt; 1">
  <div class="no-listing-results">No Results Match</div>
</div>
<div class="content-container" *ngIf="article">
  <h1 class="title">{{article.title}}</h1>
  <div class="content-render" [article]="article"></div>
</div>
<app-footer-border></app-footer-border>