
<div class="toolbar-buffer"></div>
<div class="content" *ngIf="profile">
  <div class="content-container">
    <div class="profile-img-container" *ngIf="profile.headshotURL">
      <div class="profile-img">
        <div class="profile-img-background" [style.background-image]="sanitizer.bypassSecurityTrustStyle(imgUrl(profile.headshotURL))"></div>
      </div>
    </div>
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <div class="title">{{profileName()}}</div>
          <div class="social-icon social-icon-linkedin" [class.enabled]="enabledSocial('linkedin')" [class.disabled]="!enabledSocial('linkedin')" (click)="openSocial('linkedin')"></div>
          <div class="social-icon social-icon-twitter" [class.enabled]="enabledSocial('twitter')" [class.disabled]="!enabledSocial('twitter')" (click)="openSocial('twitter')"></div>
          <div class="social-icon social-icon-facebook" [class.enabled]="enabledSocial('facebook')" [class.disabled]="!enabledSocial('facebook')" (click)="openSocial('facebook')"></div>
        </mat-card-title>
        <mat-card-subtitle *ngIf="titleName()">
          <div class="sub-title">{{titleName()}}</div>
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="companyName()">
          <div class="sub-title">{{companyName()}}</div>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p class="bio" [innerHTML]="markdownToHtml(profile.bio || 'Bio Not On File')"></p>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <mat-card-title>Contact</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-grid-list cols="4" gutterSize="0" rowHeight="60px">
          <mat-grid-tile [colspan]="2">
            <mat-form-field class="padding-right">
              <input matInput [readonly]="true" placeholder="Work Phone" [value]="profile.workPhone || 'Not On File'">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="2">
            <mat-form-field>
              <input matInput [readonly]="true" placeholder="Mobile Phone" [value]="profile.mobilePhone || 'Not On File'">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="2">
            <mat-form-field class="padding-right">
              <input matInput [readonly]="true" placeholder="Email" [value]="profile.email || 'Not On File'">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="2">
            <mat-form-field>
              <input matInput [readonly]="true" placeholder="Website" [value]="profile.website || 'Not On File'">
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <mat-card-title>NDAs</mat-card-title>
      </mat-card-header>
      <mat-card-content *ngIf="!contracts">
        <mat-progress-bar mode="query"></mat-progress-bar>
      </mat-card-content>
      <mat-card-content *ngIf="contracts &amp;&amp; contracts.data.length &lt; 1">No NDAs Signed</mat-card-content>
      <mat-card-content *ngIf="contracts &amp;&amp; contracts.data.length &gt; 0">
        <mat-table [dataSource]="contracts">
          <ng-container matColumnDef="timestamp">
            <mat-header-cell *matHeaderCellDef>Timestamp</mat-header-cell>
            <mat-cell *matCellDef="let contract">{{ contract.timestamp | date : 'MMM d, y \'at\' h:mm a' }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="contractName">
            <mat-header-cell class="name" *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell class="name" *matCellDef="let contract">{{ contract.contractName }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="action">
            <mat-header-cell class="action" *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell class="action" *matCellDef="let contract">
              <button mat-button color="accent" *ngIf="downloadAuthorized(contract) &amp;&amp; downloadAvailable(contract)" (click)="downloadContract(contract)">Download</button>
              <button mat-button color="accent" *ngIf="downloadAuthorized(contract) &amp;&amp; !downloadAvailable(contract)" [disabled]="true">Archive Unavailable</button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="['timestamp', 'contractName', 'action']"></mat-header-row>
          <mat-row *matRowDef="let row; columns: ['timestamp', 'contractName', 'action']"></mat-row>
        </mat-table>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <mat-card-title>Questionnaires</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p class="actions">
          <button mat-button color="accent" (click)="openBuyerQuestionnaire()">General Buyer Questionnaire</button>
          <button mat-button color="accent" (click)="openSellerQuestionnaire()">General Seller Questionnaire</button>
        </p>
      </mat-card-content>
      <mat-card-content *ngIf="!questionnaires">
        <mat-progress-bar mode="query"></mat-progress-bar>
      </mat-card-content>
      <!--mat-card-content(*ngIf="questionnaires && questionnaires.data.length < 1") No Questionnaires Completed-->
      <mat-card-content *ngIf="questionnaires &amp;&amp; questionnaires.data.length &gt; 0">
        <mat-table [dataSource]="questionnaires">
          <ng-container matColumnDef="timestamp">
            <mat-header-cell *matHeaderCellDef>Timestamp</mat-header-cell>
            <mat-cell *matCellDef="let questionnaire">{{ questionnaire.updated | date : 'MMM d, y \'at\' h:mm a' }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="listingName">
            <mat-header-cell class="name" *matHeaderCellDef>Listing Name</mat-header-cell>
            <mat-cell class="name" *matCellDef="let questionnaire">{{ questionnaire.article.title }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="action">
            <mat-header-cell class="action" *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell class="action" *matCellDef="let questionnaire">
              <button mat-button color="accent" (click)="reviewQuestionnaire(questionnaire)">Review</button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="['timestamp', 'listingName', 'action']"></mat-header-row>
          <mat-row *matRowDef="let row; columns: ['timestamp', 'listingName', 'action']"></mat-row>
        </mat-table>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <mat-card-title>Address</mat-card-title>
      </mat-card-header>
      <mat-card-content *ngIf="!addressFormatted()">
        <p>Not On File</p>
      </mat-card-content>
      <mat-card-content *ngIf="addressFormatted()">
        <div class="map-container-outer">
          <div class="map-container-inner">
            <iframe [src]="addressEmbedLink" style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <mat-card-title>Site Activity</mat-card-title>
        <mat-card-subtitle>Up to 1 Year Shown</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content *ngIf="analyticsEvents === null">
        <mat-progress-bar mode="query"></mat-progress-bar>
      </mat-card-content>
      <mat-card-content *ngIf="analyticsEvents &amp;&amp; analyticsEvents.data.length &lt; 1">No Events Logged</mat-card-content>
      <mat-card-content *ngIf="analyticsEvents &amp;&amp; analyticsEvents.data.length &gt; 0">
        <mat-table [dataSource]="analyticsEvents">
          <ng-container matColumnDef="timestamp">
            <mat-header-cell class="timestamp" *matHeaderCellDef>Timestamp</mat-header-cell>
            <mat-cell class="timestamp" *matCellDef="let activity">{{ activity.timestamp | date : 'MMM d, y \'at\' h:mm a' }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="action">
            <mat-header-cell class="action" *matHeaderCellDef>Event Type</mat-header-cell>
            <mat-cell class="action" *matCellDef="let activity">{{ activity.action }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="page">
            <mat-header-cell class="user" *matHeaderCellDef>Page</mat-header-cell>
            <mat-cell class="user" *matCellDef="let activity">{{ activity.url || 'N/A' }}</mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="['timestamp', 'action', 'page']"></mat-header-row>
          <mat-row *matRowDef="let row; columns: ['timestamp', 'action', 'page']"></mat-row>
        </mat-table>
      </mat-card-content>
    </mat-card>
  </div>
</div>