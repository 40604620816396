
<h2 class="page-title">Listing Management</h2>
<div class="description" *ngIf="!article || !article.path">Loading...</div>
<div class="description" *ngIf="article &amp;&amp; article.path">Listing: {{ listingName() }}</div>
<div class="description" *ngIf="article &amp;&amp; article.path">URL: <a href="{{article.path}}">{{ article.path }}</a></div>
<mat-tab-group *ngIf="article" dynamicHeight="true" [selectedIndex]="0" (selectedIndexChange)="tabSectionChanged()">
  <mat-tab label="Permissions">
    <div class="permissions-section">
      <h2>Add Subscriptions</h2>
      <ul class="description">
        <li>You can grant immediate access without an NDA here by adding users by email.</li>
        <li>Once you click save any user's with changes will be emailed immediately notifying them of their new permission level. The email will contain a link to the listing which will log them into the site.</li>
        <li>Remember to click save after adding new access or changing existing access.</li>
      </ul>
      <div class="access-controls section-break" [class.active]="accessLevelsChanged()" [class.inactive]="!accessLevelsChanged()">
        <div class="controls-container" *ngIf="savingSubscriptionPermissions">
          <div class="loader-bar"></div>
        </div>
        <mat-form-field>
          <mat-select placeholder="Access Level" [(ngModel)]="activeAccessLevel">
            <mat-option value="private">Private</mat-option>
            <mat-option value="exclusive">Exclusive</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="mailchimp-lists" *ngIf="showMailchimpLists()">
          <mat-select placeholder="Send Using List" [(ngModel)]="selectedMailchimpListActiveSubscriptions">
            <mat-option *ngFor="let list of mailchimpLists" [value]="list">{{ list.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="active-subscriptions">
          <mat-label>Email Address(es)</mat-label>
          <input matInput [(ngModel)]="addSubscriptionEmails" placeholder="john@live.com, cindy@gmail.com, etc">
        </mat-form-field>
        <div class="action" *ngIf="!addingUser" (click)="addSubscriptionUsers()" [class.active]="addSubscriptionEmails.trim().length &gt; 2" [class.inactive]="addSubscriptionEmails.trim().length &lt; 3">Add</div>
        <div class="action" *ngIf="addingUser">
          <div class="loader-bar"></div>
        </div>
      </div><br>
      <mat-form-field class="profile-filter">
        <mat-label>Filter List</mat-label>
        <input matInput [(ngModel)]="profileFilter" (change)="applyProfileFilter()" (keyup)="applyProfileFilter()" placeholder="John Person, j.person@email, etc">
      </mat-form-field>
      <h4>Pending Subscription Approvals</h4>
      <ul class="description">
        <li>These users have requested an upgrade to their access level.</li>
        <li>Go through the entire list clicking approve or decline as needed.</li>
        <li>You do not need to approve or decline all requests. Any you skip will remain unchanged.</li>
        <li>Once you are done approving or declining requests, click save to make the changes permanent.</li>
      </ul>
      <div class="access-container">
        <mat-table [dataSource]="pendingApprovalsEditFilteredDataSource" #pendingPermissionsTableSort matSort>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell *matCellDef="let profile" (click)="openProfile(profile)">{{ displayName(profile) }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header>E-mail</mat-header-cell>
            <mat-cell *matCellDef="let profile" (click)="openProfile(profile)">{{ displayEmail(profile) }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="accessLevel">
            <mat-header-cell *matHeaderCellDef>Requested Access</mat-header-cell>
            <mat-cell *matCellDef="let profile">{{ requestedAccessLevelDisplay(profile) }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell class="actions" *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell class="actions" *matCellDef="let profile">
              <button class="action" mat-button color="accent" (click)="approveAccessRequest(profile)">Approve</button>
              <button class="action" mat-button color="accent" (click)="declineAccessRequest(profile)">Decline</button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="['name', 'email', 'accessLevel', 'actions']"></mat-header-row>
          <mat-row *matRowDef="let row; columns: ['name', 'email', 'accessLevel', 'actions']"></mat-row>
        </mat-table>
      </div><br>
      <h4>Active Subscriptions</h4>
      <ul class="description">
        <li>These users currently have the indicated access level.</li>
        <li>Make any required changes then click save.</li>
      </ul>
      <div class="access-controls section-break" [class.active]="accessLevelsChanged()" [class.inactive]="!accessLevelsChanged()">
        <div class="controls-container" *ngIf="!savingSubscriptionPermissions">
          <div class="control" (click)="saveSubscriptions()">Save</div>
          <div class="control" (click)="cancelSubscriptions()">Cancel</div>
        </div>
      </div>
      <div class="access-container">
        <mat-table [dataSource]="activeSubscriptionProfilesFilteredDataSource" #permissionsTableSort matSort>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell class="clickable" *matCellDef="let profile" (click)="openProfile(profile)">{{ displayName(profile) }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header>E-mail</mat-header-cell>
            <mat-cell class="clickable" *matCellDef="let profile" (click)="openProfile(profile)">{{ displayEmail(profile) }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="accessLevel">
            <mat-header-cell *matHeaderCellDef>Current Access</mat-header-cell>
            <mat-cell *matCellDef="let profile">
              <mat-form-field>
                <mat-select [ngModel]="getAccessLevel(profile.id)" (selectionChange)="setAccessLevel(profile.id, $event.value)">
                  <mat-option value="private">Private</mat-option>
                  <mat-option value="exclusive">Exclusive</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell class="actions" *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell class="actions" *matCellDef="let profile">
              <button class="action" mat-button color="accent" (click)="removeSubscriptionAccess(profile)">Remove</button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="['name', 'email', 'accessLevel', 'actions']"></mat-header-row>
          <mat-row *matRowDef="let row; columns: ['name', 'email', 'accessLevel', 'actions']"></mat-row>
        </mat-table>
        <mat-paginator #permissionsTablePaginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true"></mat-paginator>
      </div>
      <div class="access-controls section-break active">
        <div class="controls-container">
          <div class="control" (click)="downloadCSV()">Download as CSV</div>
        </div>
      </div>
    </div>
  </mat-tab>
  <!--mat-tab(label="Invites")-->
  <!--  .invites-section-->
  <!--    include management.component.invite.include.pug-->
  <mat-tab label="Admin Users">
    <div class="admin-users-section">
      <h2>Admin Users</h2>
      <ul class="description">
        <li>These users have the ability to manage subscriptions and permissions for this listing.</li>
        <li>Remember to click save once you are done adding and removing users.</li>
      </ul>
      <div class="access-controls section-break" [class.active]="adminUsersChanged()" [class.inactive]="!adminUsersChanged()">
        <mat-form-field class="email-addresses">
          <mat-label>Email Address(es)</mat-label>
          <input matInput [(ngModel)]="addAdminEmails" placeholder="john@live.com, cindy@gmail.com, etc">
        </mat-form-field>
        <div class="action" *ngIf="!addingAdminUser" (click)="addAdminUsers()" [class.active]="addAdminEmails.trim().length &gt; 2" [class.inactive]="addAdminEmails.trim().length &lt; 3">Add</div>
        <div class="action" *ngIf="addingAdminUser">
          <div class="loader-bar"></div>
        </div>
      </div>
      <mat-form-field class="profile-filter">
        <mat-label>Filter List</mat-label>
        <input matInput [(ngModel)]="profileFilter" (change)="applyProfileFilter()" (keyup)="applyProfileFilter()" placeholder="John Person, j.person@email, etc">
      </mat-form-field>
      <div class="access-controls section-break" [class.active]="adminUsersChanged()" [class.inactive]="!adminUsersChanged()">
        <div class="controls-container" *ngIf="!savingAdminPermissions">
          <div class="control" (click)="saveAdmins()">Save</div>
          <div class="control" (click)="cancelAdmins()">Cancel</div>
        </div>
        <div class="controls-container" *ngIf="savingAdminPermissions">
          <div class="loader-bar"></div>
        </div>
      </div>
      <div class="access-container">
        <mat-table [dataSource]="adminUserProfilesFilteredDataSource" #adminTableSort matSort>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell class="clickable" *matCellDef="let profile" (click)="openProfile(profile)">{{ displayName(profile) }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header>E-mail</mat-header-cell>
            <mat-cell class="clickable" *matCellDef="let profile" (click)="openProfile(profile)">{{ displayEmail(profile) }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell class="actions" *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell class="actions" *matCellDef="let profile">
              <button class="action" mat-button color="accent" (click)="removeAdminAccess(profile)">Remove</button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="['name', 'email', 'actions']"></mat-header-row>
          <mat-row *matRowDef="let row; columns: ['name', 'email', 'actions']"></mat-row>
        </mat-table>
        <mat-paginator #adminTablePaginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true"></mat-paginator>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Analytics">
    <div class="analytics-section">
      <app-view-listing-analytics *ngIf="article" [article]="article"></app-view-listing-analytics>
    </div>
  </mat-tab>
</mat-tab-group>