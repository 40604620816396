
<div class="section-grid-container" *ngIf="renderMode === 'desktop' &amp;&amp; section &amp;&amp; section.content &amp;&amp; section.content.rows &amp;&amp; section.content.columns" [style.text-align]="section.contentHorizontalAlign" [style.background-color]="section.contentBackgroundColor" [style.color]="section.contentForegroundColor">
  <div class="section-grid-row" *ngFor="let i of iterator(section.content.rows);">
    <div class="section-grid-cell" *ngFor="let j of iterator(section.content.columns);" [style.width]="columnWidth()">
      <div class="section-grid-cell-content-outer" *ngIf="cellExists(i, j)" [class.editing]="section.editing">
        <div class="section-grid-cell-content-inner" *ngIf="!section.editing" [innerHTML]="getFieldContent(i, j)"></div>
        <textarea class="section-grid-cell-content-inner editing" *ngIf="section.editing" [(ngModel)]="section.content.fields[i][j]" placeholder="Enter markdown here"></textarea>
      </div>
    </div>
  </div>
</div>
<div class="section-grid-container-mobile" *ngIf="renderMode === 'mobile' &amp;&amp; section &amp;&amp; section.content" [style.text-align]="section.contentHorizontalAlign" [style.background-color]="section.contentBackgroundColor" [style.color]="section.contentForegroundColor">
  <div class="section-mobile-container">
    <div class="section-mobile-item" *ngFor="let field of mobileFields" [innerHTML]="markdownToHtml(field)"></div>
  </div>
</div>