
<div class="documents-container" *ngIf="files.length &gt;0">
  <div class="section-anchor" [attr.data-section-name]="&quot;Diligence\nDocuments&quot;"></div>
  <h2>Diligence Documents</h2>
  <div class="table-label">Name</div>
  <div class="documents">
    <div class="document" *ngFor="let document of files" (click)="openDocument(document)">
      <div class="document-field document-field-name">{{ document.name }}</div>
      <div class="document-field document-field-actions" *ngIf="document &amp;&amp; document.locked">
        <div class="locked-icon">
          <div class="component-icon lock-gray"></div>
        </div>
      </div>
      <div class="document-field document-field-actions" *ngIf="document &amp;&amp; !document.locked">
        <div class="action action-view" *ngIf="!isLoading(document)">View</div>
        <div class="action-loading" *ngIf="isLoading(document)">
          <div class="loader-bar"></div>
        </div>
      </div>
    </div>
  </div>
</div>