
<div class="content-view" [class.show-padding]="!!title">
  <div class="section-title" *ngIf="title">{{title}}</div>
  <!--.grid.mode-blog(*ngIf="mode === 'blog'")-->
  <!--	.grid-item(*ngFor="let article of articles")-->
  <!--		.post(*ngIf="article && (article.type ==='post' || article.type ==='listing' || || article.type ==='buyer') && article.hasOwnProperty('path')", [routerLink]="pathUrl(article.path)", [fragment]="pathFragment(article.path)")-->
  <!--			.app-content-tiles-blog-tile([article]="article", [mode]="mode")-->
  <div class="grid mode-normal" *ngIf="mode === 'normal'">
    <div class="grid-item" *ngFor="let article of articles; let i = index;">
      <!--.subscribe(*ngIf="article && article.type === 'subscribe'")-->
      <!--	.content-subscribe-->
      <!--		.subscribe-processing(*ngIf="subscribing")-->
      <!--			.loader-spinner-->
      <!--		.subscribe-complete(*ngIf="subscribeComplete")-->
      <!--			.thank-you Thank you for subscribing!-->
      <!--		.subscribe-form(*ngIf="!subscribing && !subscribeComplete")-->
      <!--			.title1 Go Forward.-->
      <!--			.title2 Go Exio.-->
      <!--			.description-->
      <!--				| Sign up today for our-->
      <!--				br-->
      <!--				| Insider Knowledge Newsletter-->
      <!--			form.tile(#subscribeModel="ngForm", (ngSubmit)="subscribe()")-->
      <!--				input(type="email", [attr.placeholder]="placeHolderText", (focus)="placeHolderText = ''", (blur)="placeHolderText = 'Please enter your email address'", name="email", [(ngModel)]="subscribeEmail")-->
      <!--				button(type="submit", [disabled]="!subscribeModel.form.valid") Subscribe-->
      <div class="post" *ngIf="article &amp;&amp; (article.type ==='post' || article.type ==='listing' || article.type ==='buyer'|| article.type ==='advisor') &amp;&amp; article.hasOwnProperty('path')">
        <div class="app-content-tiles-post-tile" [article]="article" [mode]="mode" [index]="i"></div>
      </div>
      <div class="video" *ngIf="article &amp;&amp; article.type ==='video' &amp;&amp; article.hasOwnProperty('path')">
        <div class="app-content-tiles-video-tile" [article]="article" [mode]="mode"></div>
      </div>
    </div>
  </div>
  <div class="grid mode-services" *ngIf="mode === 'services'">
    <div class="grid-item" *ngFor="let article of articles">
      <div class="app-content-tiles-service-tile" [article]="article" [mode]="mode"></div>
    </div>
  </div>
  <div class="grid mode-marketplace" *ngIf="mode === 'marketplace'">
    <div class="grid-item" *ngFor="let article of articles">
      <!--.subscribe(*ngIf="article && article.type === 'subscribe'")-->
      <!--	.content-subscribe-->
      <!--		.title1 We saved a spot for you.-->
      <!--		.title2 Join the Exio ranks today.-->
      <!--		.get-started((click)="signUpSeller()")-->
      <!--			.get-started-content Get Started-->
      <div class="post" *ngIf="article &amp;&amp; (article.type ==='post' || article.type ==='listing' || article.type ==='buyer'|| article.type ==='advisor') &amp;&amp; article.hasOwnProperty('path')">
        <div class="app-content-tiles-post-tile" [article]="article" [mode]="mode" [index]="i"></div>
      </div>
    </div>
  </div>
  <!--.grid.mode-services(*ngIf="mode === 'services'")-->
  <!--	.grid-item(*ngFor="let article of articles")-->
  <!--		.post(*ngIf="article")-->
  <!--			.banner(*ngIf="article.bannerImgUrl", [style.background-color]="article.bannerBackground", [style.background-image]="sanitizer.bypassSecurityTrustStyle(imageUrl(article.bannerImgUrl))")-->
  <!--			.content([class.opacity0]="article.bannerOpacity === '0'", [class.opacity10]="article.bannerOpacity === '10'", [class.opacity20]="article.bannerOpacity === '20'", [class.opacity30]="article.bannerOpacity === '30'", [class.opacity40]="article.bannerOpacity === '40'", [class.opacity50]="article.bannerOpacity === '50'", [class.opacity60]="article.bannerOpacity === '60'", [class.opacity70]="article.bannerOpacity === '70'", [class.opacity80]="article.bannerOpacity === '80'", [class.opacity90]="article.bannerOpacity === '90'", [class.opacity100]="article.bannerOpacity === '100'")-->
  <!--				img.content-icon([src]="sanitizer.bypassSecurityTrustUrl(iconUrl(article))")-->
  <!--				.content-field(*ngFor="let field of fields", [class]="'content-field content-field-' + field", [innerHTML]='markdownToHtml(article[field])')-->
  <!--				.content-actions(*ngFor="let action of article.actions")-->
  <!--					.action(*ngIf="action.actionType === 'url'", [routerLink]="actionUrl(action, article)", [fragment]="actionFragment(action, article)") {{ action.name }}-->
</div>