import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  NgZone,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup, FormBuilder, FormArray, Validators} from '@angular/forms';
import {Location} from '@angular/common';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {AppService} from 'app/services/app';
import {Article, ArticleSection, ArticleFile} from 'app/models/article';
import {Questionnaire} from 'app/models/questionnaire';
import {Listing} from 'app/models/listing';
import {LegalDocument} from 'app/models/legal.document';
import {Profile} from 'app/models/profile';

import {Hash} from 'app/types/containers';

import * as moment from 'moment-timezone';

@Component({
  moduleId: module.id,
  selector: 'app-view-article-editor',
  templateUrl: 'editor.component.html',
  styleUrls: ['editor.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewsArticleEditorComponent implements OnInit, OnDestroy {

  public authenticatedProfile: Profile = null;
  public stateArticleId: string = null;

  public saving: boolean = false;

  // public listingDocuments: ListingFile[] = null;
  public articleDocumentsLoading: boolean = false;

  public listings: Listing[] = null;
  public listingsLoading: boolean = false;

  public articleHistory: Article[] = null;
  public article: Article = null;
  public articleEdited: Article = null;

  public profiles: Profile[] = null;
  public profilesById: Hash<Profile> = {};

  public selectedVersion: string = '-1';

  public accessLevelUserSelected: string = null;

  public fixedMenu: boolean = false;

  public dirty: boolean = false;

  public saveMessage: string = null;
  public saveMessageTimeout: number = null;

  public $el: JQuery = null;

  public subscriptions: any[] = [];

  public submenuDataMode: boolean = false;

  public authors: string[][] = null;

  public legalDocuments: string[][] = null;
  public questionnaires: string[][] = null;

  public articleDocuments: ArticleFile[] = null;

  public generalDirtyCheckHandler: () => void = null;

  public loadingDocuments: boolean = true;

  public bannerLayoutValues = [
    ['blank', 'Blank'],
    ['standard', 'Standard'],
    ['centeredNoSubTitle', 'Centered, No Sub-Title'],
    ['centeredNoPreTitle', 'Centered, No Pre-Title'],
    ['testimonial', 'Testimonial'],
    ['testimonialCentered', 'Testimonial, Centered']
  ];

  // Forms
  public editorMenuGeneralForm: FormGroup = null;

  public editorMenuComponentsInitialized = false;
  public editorMenuComponents: string[][] = [
    ['text', 'Text', 'glyphicon-font'],
    ['heading', 'Heading', 'glyphicon-header'],
    ['img', 'Image', 'glyphicon-picture'],
    ['vimeo', 'Vimeo', 'glyphicon-facetime-video'],
    ['hr', 'Horizontal Rule', 'glyphicon-resize-horizontal'],
    ['swot', 'SWOT', 'glyphicon-random'],
    ['exio-score', 'Scores', 'glyphicon-align-left'],
    // [ 'vr', 'Vertical Rule', 'glyphicon-resize-vertical' ],
    ['carousel', 'Carousel', 'glyphicon-blackboard'],
    ['grid', 'Grid', 'glyphicon-th'],
    ['leadership', 'Leadership', 'glyphicon-user'],
    ['feature', 'Feature', 'glyphicon-list'],
    // [ 'tiles', 'Tiles', 'glyphicon-th-large' ],
  ];

  public bannerPositionValues: string[] = [
    'top left',
    'top center',
    'top right',
    'center left',
    'center center',
    'center right',
    'bottom left',
    'bottom center',
    'bottom right'
  ];
  public bannerOpacityValues: string[] = [];
  public sortHintValues: string[] = [];

  constructor(public app: AppService,
              public route: ActivatedRoute,
              public router: Router,
              public formBuilder: FormBuilder,
              public el: ElementRef,
              public location: Location,
              public sanitizer: DomSanitizer,
              public zone: NgZone,
              public cdRef: ChangeDetectorRef) {

    this.app.contentLoading(true);
    this.app.toolbar.whiteOverContent = false;
    this.app.toolbar.backgroundColor = null;

    for (let i = 0; i <= 100; i++) {
      this.sortHintValues.push(`${i}`);
    }
    for (let i = 0; i <= 100; i += 5) {
      this.bannerOpacityValues.push(`${i}`);
    }

    this.$el = $(this.el.nativeElement);

    this.zone.runOutsideAngular(() => {

      const observer = new MutationObserver(() => {
        this.watchDom();
      });

      observer.observe(this.el.nativeElement, {
        childList: true,
        attributes: false,
        characterData: false,
        subtree: true,
        attributeOldValue: false,
        characterDataOldValue: false
      });
    });

  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public refreshDocuments(): void {
    this.loadDocuments(true);
  }

  public setSaveMessage(message: string): void {

    this.saveMessage = message;

    if (this.saveMessageTimeout) {
      window.clearTimeout(this.saveMessageTimeout);
      this.saveMessageTimeout = null;
    }

    if (message) {

      this.saveMessageTimeout = window.setTimeout(() => {
        this.saveMessageTimeout = null;
        this.saveMessage = null;
      }, 10000);

    }

  }

  public watchDom(): void {

    this.zone.runOutsideAngular(() => {
      this.setupScrollMenuFix();
      this.cdRef.markForCheck();
    });

    if (!this.editorMenuComponentsInitialized) {

      const $editorMenuComponents: JQuery = this.$el
        .find('.editor-components-menu')
        .find('.editor-component.content-type:not(.ui-draggable)');

      if ($editorMenuComponents.length >= this.editorMenuComponents.length) {

        this.editorMenuComponentsInitialized = true;

        this.zone.runOutsideAngular(() => {

          $editorMenuComponents
            .draggable({
              helper: 'clone',
              'start': () => {
                this.zone.run(() => {
                  this.app.content.dragging = true;
                  this.cdRef.markForCheck();
                });
              },
              'stop': () => {
                window.setTimeout(() => {
                  this.zone.run(() => {
                    this.app.content.dragging = false;
                    this.editorDirtyCheck();
                    this.cdRef.markForCheck();
                  });
                });
              }
            });

        });

      }

    }

    this.zone.runOutsideAngular(() => {

      if (this.articleEdited && this.articleEdited.sections) {

        let sectionsDeleted = false;

        this.articleEdited.sections.forEach((section: ArticleSection) => {

          if (sectionsDeleted || section.delete === true) {
            sectionsDeleted = true;
            return;
          }

        });

        if (sectionsDeleted) {

          window.setTimeout(() => {

            this.articleEdited.sections = this.articleEdited.sections.filter((section: ArticleSection) => {
              return !section.delete;
            });

            this.zone.run(() => {
              this.editorDirtyCheck();
              this.cdRef.markForCheck();
            });

          });

        }

      }
    });

    this.editorDirtyCheck();

  }

  public listingSelectChanged(): void {

    // let listingId = this.getSelectedListingId();
    //
    // if ( this.articleEdited && listingId ) {
    //
    // 	if ( !this.articleEdited.data ) {
    // 		this.articleEdited.data = {};
    // 	}
    //
    // 	// if ( !Array.isArray( this.articleEdited.data.listingDocuments ) ) {
    // 	// 	this.articleEdited.data.listingDocuments = [];
    // 	// }
    //
    // 	// this.zone.run( () => {
    // 	// 	this.articleDocumentsLoading = true;
    // 	// 	this.cdRef.markForCheck();
    // 	// } );
    // 	// this.articleDocumentsLoading = true;
    //
    // 	this.app.listingModel.listPublishedUploads( { listingId: listingId, path: '/' } )
    // 		.then( ( listingDocuments: ListingFile[] ) => {
    // 			this.zone.run( () => {
    //
    // 				this.articleDocumentsLoading = false;
    //
    // 				let articleDocumentIndex = {};
    // 				let resultsDocumentIndex = {};
    //
    // 				listingDocuments = listingDocuments.filter( ( document ) => {
    //
    // 					if ( document && document.id ) {
    // 						resultsDocumentIndex[ document.id ] = document;
    // 						return true;
    // 					}
    //
    // 					return false;
    //
    // 				} );
    //
    // 				if ( !Array.isArray( this.articleEdited.data.listingDocuments ) ) {
    // 					this.articleEdited.data.listingDocuments = [];
    // 				}
    //
    // 				this.articleEdited.data.listingDocuments = (<ListingFile[]>this.articleEdited.data.listingDocuments).filter( ( document ) => {
    //
    // 					if ( document && document.id && resultsDocumentIndex.hasOwnProperty( document.id ) ) {
    //
    // 						articleDocumentIndex[ document.id ] = document;
    //
    // 						document.name = resultsDocumentIndex[ document.id ].name;
    // 						document.webViewLink = resultsDocumentIndex[ document.id ].webViewLink;
    // 						document.downloadLink = resultsDocumentIndex[ document.id ].downloadLink;
    //
    // 						return true;
    // 					}
    //
    // 					return false;
    //
    // 				} );
    //
    // 				listingDocuments.forEach( ( document ) => {
    //
    // 					if ( !articleDocumentIndex.hasOwnProperty( document.id ) ) {
    //
    // 						document.accessLevel = 'exclusive';
    // 						articleDocumentIndex[ document.id ] = document;
    // 						(<ListingFile[]>this.articleEdited.data.listingDocuments).push( document );
    // 					}
    //
    // 				} );
    //
    // 				(<ListingFile[]>this.articleEdited.data.listingDocuments).sort( ( a, b ) => {
    // 					if ( a.name < b.name ) return -1;
    // 					if ( a.name > b.name ) return 1;
    // 					return 0;
    // 				} );
    //
    // 				this.cdRef.markForCheck();
    //
    // 			} );
    // 		} )
    // 		.catch( ( e ) => {
    // 			console.error( 'Error: listPublishedUploads', e );
    // 		} );
    //
    // } else {
    //
    // 	if ( this.articleEdited && this.articleEdited.data && this.articleEdited.data.listingDocuments ) {
    // 		this.articleEdited.data.listingDocuments = [];
    // 	}
    //
    // }

  }

  public fileAccessChanged(): void {

    this.editorDirtyCheck();

  }

  public articleCount(): number {
    const list = this.getArticleDocuments();

    return list === null ? -1 : list.length;

  }

  public getArticleDocuments(): ArticleFile[] {

    if (!this.articleEdited) {
      return null;
    }

    if (!this.articleEdited.data) {
      this.articleEdited.data = {};
    }

    if (!Array.isArray(this.articleEdited.data.documents)) {
      this.articleEdited.data.documents = <ArticleFile[]>[];
    }

    return <ArticleFile[]>this.articleEdited.data.documents;

  }

  // public getListingDocuments(): ListingFile[] {
  //
  // 	if ( this.articleEdited.data && this.articleEdited.data.listingDocuments ) {
  // 		return <ListingFile[]>this.articleEdited.data.listingDocuments;
  // 	}
  //
  // 	return [];
  // }

  public getSelectedListingId(): string {

    if (this.articleEdited &&
      (
        this.articleEdited.type === 'listing' ||
        this.articleEdited.type === 'buyer'
      ) &&
      this.articleEdited.data &&
      this.articleEdited.data.listingId !== 'none') {
      return <string>this.articleEdited.data.listingId;
    }

    return null;
  }

  public getListings(): Listing[] {

    if (this.listings === null && !this.listingsLoading) {

      this.listingsLoading = true;
      this.cdRef.markForCheck();

      this.app.listingModel.query({deleted: false})
        .then((listings: Listing[]) => {

          this.zone.run(() => {
            this.listingsLoading = false;
            this.listings = listings;
            this.cdRef.markForCheck();
          });

        })
        .catch((e) => {
          console.error('error querying listings', e);
        });

    }

    return this.listings;

  }

  public updateAuthor(): void {

    if (!this.authenticatedProfile || !this.articleEdited) {
      return;
    }

    if (typeof this.articleEdited.author !== 'string' || this.articleEdited.author.trim().length < 1) {
      this.articleEdited.author = this.authenticatedProfile.id;
    }

  }

  public ngOnInit(): void {

    document.body.scrollTop = 0;

    this.app.questionnaireModel.query()
      .then((questionnaires: Questionnaire[]) => {

        this.questionnaires = questionnaires.map(questionnaire => {
          return [questionnaire.id, questionnaire.title];
        });

        // alphabetize list by display value
        this.questionnaires.sort((a: string[], b: string[]) => {

          if (a[1] < b[1]) {
            return -1;
          }
          if (b[1] < a[1]) {
            return 1;
          }

          return 0;

        });

      })
      .catch((e) => {
        console.error('failed to load questionnaires');
      });

    this.app.legalDocumentModel.list()
      .then((legalDocuments: LegalDocument[]) => {
        this.legalDocuments = legalDocuments.map((legalDocument) => {
          return [legalDocument.id, legalDocument.name];
        });

        // alphabetize list by display value
        this.legalDocuments.sort((a: string[], b: string[]) => {

          if (a[1] < b[1]) {
            return -1;
          }
          if (b[1] < a[1]) {
            return 1;
          }

          return 0;

        });

      })
      .catch((e) => {
        console.error('failed to load legal documents', e);
      });

    this.app.profileModel.listEmployees()
      .then((profiles: Profile[]) => {

        this.authors = profiles.map((profile) => {
          return [
            profile.id,
            `${profile.lastName}, ${profile.firstName} <${profile.email}>`
          ];
        });

        // alphabetize list by display value
        this.authors.sort((a: string[], b: string[]) => {

          if (a[1] < b[1]) {
            return -1;
          }
          if (b[1] < a[1]) {
            return 1;
          }

          return 0;


        });

      })
      .catch((e) => {
        console.error('failed to load employee profiles', e);
      });


    if (this.profiles === null) {
      this.profiles = [];

      this.app.profileModel.list()
        .then((profiles: Profile[]) => {

          this.zone.run(() => {
            this.profiles = profiles;
            profiles.forEach((profile) => {
              this.profilesById[profile.id] = profile;
            });
            this.cdRef.markForCheck();
          });
        })
        .catch((e) => {
          console.error('error listing profiles', e);
        });

    }

    this.subscriptions.push(this.app.getAuthenticatedProfile({
      next: (profile) => {

        if (typeof profile === 'boolean' && !profile) {

          this.app.contentLoading(true);

        } else if (!profile || !profile.isEmployee) {

          this.router.navigate(['/']);

        } else {

          this.updateAuthor();

          // init with all articles
          this.subscriptions.push(this.route.params.subscribe((params: { articleId: string, copyArticleId?: string }) => {

            if (params && params.articleId) {
              this.resetArticle(params.articleId === 'copy' ? 'new' : params.articleId, params.copyArticleId || null);
            }

          }));

        }

      },
      error: () => {
        this.router.navigate(['/']).then();
      },
      complete: () => {
        this.router.navigate(['/']).then();
      }
    }));

  }

  public isFixedComponentMenu(): boolean {
    return this.fixedMenu;
  }

  public setupScrollMenuFix(): void {

    const $editorComponentsMenu: JQuery = this.$el.find('.editor-components-menu');

    if ($editorComponentsMenu.length < 1 || $editorComponentsMenu.hasClass('ui-scroll-top-menu')) {
      return;
    }

    $editorComponentsMenu.addClass('ui-scroll-top-menu');

    const $editorComponentsMenuPlaceHolder: JQuery = this.$el.find('.editor-components-menu-placeholder');

    const $window: JQuery = $(window);
    const menuScrollTop: number = $editorComponentsMenu.offset().top;


    const triggerScrollHeight = (windowScrollTop: number): void => {

      const fixedMenu = windowScrollTop - menuScrollTop >= -30;

      if (fixedMenu) {
        $editorComponentsMenu.addClass('fixed-menu');
        $editorComponentsMenuPlaceHolder.addClass('fixed-menu');
      } else {
        $editorComponentsMenu.removeClass('fixed-menu');
        $editorComponentsMenuPlaceHolder.removeClass('fixed-menu');
      }

      if (fixedMenu !== this.fixedMenu) {
        this.zone.run(() => {
          this.fixedMenu = fixedMenu;
          this.cdRef.markForCheck();
        });
      }

    };

    this.zone.runOutsideAngular(() => {
      $window.scroll(() => {
        triggerScrollHeight($window.scrollTop());
      });
    });

  }

  public resetArticle(articleId: string, copyArticleId?: string): void {

    this.zone.run(() => {
      if (articleId === this.stateArticleId) {
        return;
      }
      this.stateArticleId = articleId;

      this.article = null;
      this.articleEdited = null;

      if (articleId === 'new' && !copyArticleId) {

        this.app.contentLoading(false);

        const article: Article = <Article>{
          id: null,
          path: null,
          author: null,
          type: 'post',
          deleted: false,
          data: {},
          published: false,
          hidden: false,
          title: null,
          sortHint: 1,
          redirectPaths: [],
          categories: [],
          actions: [],
          submenu: [],
          sections: [],
          accessLevel: 'public',
          accessLevelExclusive: [],
          accessRequirements: {
            public: null,
            private: null,
            exclusive: null
          }
        };

        this.article = this.app.articleModel.clone(article);

        const date = new Date().toISOString();
        const title = 'New Article ' + date;
        const path = title.toLowerCase().replace(/ /g, '-');

        article.title = title;
        article.path = path;

        this.articleEdited = this.app.articleModel.clone(article);

        this.renderEditor();

      } else {

        let copy = false;

        if (articleId === 'new') {
          copy = true;
          articleId = copyArticleId;
        }

        this.app.contentLoading(true);
        this.app
          .articleModel
          .get(articleId)
          .then((article: Article) => {

            this.app.contentLoading(false);

            if (article) {

              if (!article.data) {
                article.data = {};
              }

              if (article.created) {
                article.created = moment(article.created).format('MM/DD/YYYY');
              } else {
                article.created = moment().format('MM/DD/YYYY');
              }

              this.article = this.app.articleModel.clone(article);

              // don't clone the VDR link for the previous listing
              if (copyArticleId && this.article.data) {
                delete this.article.data.vdrUrl;
              }
              this.articleEdited = this.app.articleModel.clone(article);

              this.loadDocuments();

              if (copy) {
                this.location.replaceState('/articles-editor/new');
                this.stateArticleId = null;
                this.article.id = null;
                this.articleEdited.id = null;
                this.articleEdited.title += ' Copy';
                this.articleEdited.path += '-copy';
                this.articleEdited.published = false;
                this.articleEdited.hidden = false;
                this.articleEdited.accessLevelExclusive = [];
                this.articleEdited.accessLevelPrivate = [];
              }

              // this.listingSelectChanged();

            }

            this.renderEditor();

          })
          .catch(() => {
            this.renderEditor();
          });

        if (copy) {
          articleId = 'new';
        }

      }
      this.cdRef.markForCheck();
    });
  }

  public loadDocuments(cacheBust?: boolean): void {

    this.loadingDocuments = true;

    this.app
      .articleModel
      .listPublishedUploads(this.article.id, !!cacheBust)
      .then((articleDocuments: ArticleFile[]) => {

        this.articleDocumentsLoading = false;

        const articleDocumentIndex = {};
        const resultsDocumentIndex = {};

        articleDocuments = articleDocuments.filter((document) => {

          if (document && document.id) {
            resultsDocumentIndex[document.id] = document;
            return true;
          }

          return false;

        });

        this.articleEdited.data.documents = (<ArticleFile[]>this.articleEdited.data.documents).filter((document) => {

          if (document && document.id && resultsDocumentIndex.hasOwnProperty(document.id)) {

            articleDocumentIndex[document.id] = document;

            document.name = resultsDocumentIndex[document.id].name;
            document.webViewLink = resultsDocumentIndex[document.id].webViewLink;
            document.webContentLink = resultsDocumentIndex[document.id].webContentLink;
            document.parentId = resultsDocumentIndex[document.id].parentId;
            delete document.downloadLink;

            return true;
          }

          return false;

        });

        articleDocuments.forEach((document) => {

          if (!articleDocumentIndex.hasOwnProperty(document.id)) {

            document.accessLevel = 'exclusive';
            articleDocumentIndex[document.id] = document;
            (<ArticleFile[]>this.articleEdited.data.documents).push(document);

          }

        });

        (<ArticleFile[]>this.articleEdited.data.documents).sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        this.cdRef.markForCheck();
        this.loadingDocuments = false;

      })
      .catch((e) => {

        console.error('error loading documents', e);
        this.loadingDocuments = false;

      });

  }

  public renderEditor(): void {

    this.updateAuthor();

    if (this.article &&
      typeof this.article.id === 'string' &&
      this.article.id.length > 0 &&
      this.article.id !== 'new' &&
      this.article.id !== this.stateArticleId) {

      this.location.replaceState('/articles-editor/' + this.article.id);

    }

    this.app.articleModel.getVersions(this.article.id)
      .then((articleHistory) => {
        if (Array.isArray(articleHistory)) {
          this.zone.run(() => {
            this.articleHistory = articleHistory;
            this.cdRef.markForCheck();
          });
        }
      })
      .catch((e) => {
        console.error('error listing article versions', e);
      });

    this.renderEditorMenu();

  }

  public generalChangeDetection(): void {
    this.editorDirtyCheck();
  }

  public renderEditorMenu(): void {
    this.renderEditorMenuGeneral();
  }

  public renderEditorMenuGeneral(): void {

    this.zone.run(() => {

      if (!this.articleEdited) {
        this.editorMenuGeneralForm = null;
        return;
      }

      this.editorMenuGeneralForm = this.formBuilder.group({
        'listingBuyerFee': [
          this.articleEdited.data.listingBuyerFee || 0, []
        ],
        'listingCompanyName': [
          this.articleEdited.data.listingCompanyName || null, []
        ],
        'listingCompanyIndustry': [
          this.articleEdited.data.listingCompanyIndustry || null, []
        ],
        'projectCodeName': [
          this.articleEdited.data.projectCodeName || null, []
        ],
        'buyerInterestsByState': [
          this.articleEdited.data.buyerInterestsByState || null, []
        ],
        'buyerInterestsPriceMin': [
          this.articleEdited.data.buyerInterestsPriceMin || null, []
        ],
        'buyerInterestsPriceMax': [
          this.articleEdited.data.buyerInterestsPriceMax || null, []
        ],
        'listingPrice': [
          this.articleEdited.data.listingPrice || null, []
        ],
        'exioScore': [
          this.articleEdited.data.exioScore || null, []
        ],
        'naicsCode': [
          this.articleEdited.data.naicsCode || null, []
        ],
        'buyerInterestsNAICS': [
          this.articleEdited.data.buyerInterestsNAICS || null, []
        ],
        'listingLocation': [
          this.articleEdited.data.listingLocation || null, []
        ],
        'listingLocationState': [
          this.articleEdited.data.listingLocationState || null, []
        ],
        'listingLink': [
          this.articleEdited.data.listingId || 'none', []
        ],
        'published': [
          this.articleEdited.published, []
        ],
        'hidden': [
          this.articleEdited.hidden, []
        ],
        'path': [
          this.articleEdited.path, [
            Validators.required,
            Validators.pattern(/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/)
          ]
        ],
        'type': [
          this.articleEdited.type, [
            Validators.required,
            Validators.pattern(/^video|listing|post$/)
          ]
        ],
        'categories': this.formBuilder.array([]),
        'redirectPaths': this.formBuilder.array([]),
        'created': [
          this.articleEdited.created, [
            Validators.required
          ]
        ], 'title': [
          this.articleEdited.title, [
            Validators.required,
            Validators.pattern(/^[a-zA-Z0-9 _\-\*]+$/)
          ]
        ],
        'tagline': [
          this.articleEdited.tagline, [
            Validators.required,
            Validators.pattern(/^[a-zA-Z0-9 _\-\*]+$/)
          ]
        ],
        'description': [
          this.articleEdited.description, [
            Validators.required,
            Validators.pattern(/^[a-zA-Z0-9 _\-\*\n]+$/)
          ]
        ],
        'sortHint': [
          this.articleEdited.sortHint, [
            Validators.required,
            Validators.pattern(/^[0-9]+$/)
          ]
        ],
        'author': [
          this.articleEdited.author, []
        ],
        'nda': [
          this.articleEdited.nda, []
        ],
        'formId': [
          this.articleEdited.formId || null, []
        ],
        'tileImgUrl': [
          this.articleEdited.tileImgUrl, [
            Validators.pattern(/^(?:\s*[a-zA-Z0-9\-]+\s*,\s*)*(?:\s*[a-zA-Z0-9\-]+\s*)$/)
          ]
        ],
        'tileImgLargeUrl': [
          this.articleEdited.tileImgLargeUrl, [
            Validators.pattern(/^(?:\s*[a-zA-Z0-9\-]+\s*,\s*)*(?:\s*[a-zA-Z0-9\-]+\s*)$/)
          ]
        ],
        'bannerOpacity': [
          this.articleEdited.bannerOpacity, [
            Validators.required,
            Validators.pattern(/^[0-9]*$/)
          ]
        ],
        'bannerOpacityMobile': [
          this.articleEdited.bannerOpacityMobile, [
            Validators.required,
            Validators.pattern(/^[0-9]*$/)
          ]
        ],
        'bannerImgPosition': [
          this.articleEdited.bannerImgPosition, []
        ],
        'bannerImgPositionMobile': [
          this.articleEdited.bannerImgPositionMobile, []
        ],
        'bannerImgUrl': [
          this.articleEdited.bannerImgUrl, [
            Validators.pattern(/^(?:\s*[a-zA-Z0-9\-]+\s*,\s*)*(?:\s*[a-zA-Z0-9\-]+\s*)$/)
          ]
        ],
        'bannerImgUrlMobile': [
          this.articleEdited.bannerImgUrlMobile, [
            Validators.pattern(/^(?:\s*[a-zA-Z0-9\-]+\s*,\s*)*(?:\s*[a-zA-Z0-9\-]+\s*)$/)
          ]
        ],
        'bannerLayout': [
          this.articleEdited.bannerLayout || 'blank'
        ],
        'bannerLayoutMobile': [
          this.articleEdited.bannerLayoutMobile || 'blank'
        ],
        'actions': this.formBuilder.array([]),
        'submenu': this.formBuilder.array([])
      });

      this.articleEdited.categories.forEach((category) => {
        if (typeof category === 'string' && category.trim().length > 0) {
          this.addCategory(category);
        }
      });

      this.articleEdited.redirectPaths.forEach((redirectPath) => {
        if (typeof redirectPath === 'string' && redirectPath.trim().length > 0) {
          this.addUrlAlias(redirectPath);
        }
      });

      this.articleEdited.actions.forEach((action) => {

        const url = action.actionParams ? (<any>action.actionParams).href : this.articleEdited.path;

        this.addAction(action.name, url);

      });

      this.articleEdited.submenu.forEach((submenuItem) => {

        this.addSubmenuItem(submenuItem.name, submenuItem.url);

      });

      this.subscriptions.push(this.editorMenuGeneralForm.valueChanges.subscribe(() => {
        this.syncFormToModel(this.editorMenuGeneralForm);
      }));

      this.cdRef.markForCheck();

    });

  }

  public addAction(name?: string, url?: string): void {

    if (typeof name !== 'string') {
      name = 'New Action';
    }

    if (typeof url !== 'string') {
      url = this.articleEdited.path || this.article.path || '/';
    }

    const control = <FormArray>this.editorMenuGeneralForm.controls['actions'];
    control.push(this.formBuilder.group({
      name: [name, [Validators.required]],
      url: [url, [Validators.required]]
    }));
  }

  public removeAction(i: number): void {
    const control = <FormArray>this.editorMenuGeneralForm.controls['actions'];
    control.removeAt(i);
  }

  public addSubmenuItem(name?: string, url?: string): void {

    if (typeof name !== 'string') {
      name = 'New Item';
    }

    if (typeof url !== 'string') {
      url = this.articleEdited.path || this.article.path || '/';
    }
    url = '/' + url.replace(/^\//, '');

    const control = <FormArray>this.editorMenuGeneralForm.controls['submenu'];
    control.push(this.formBuilder.group({
      name: [name, [Validators.required]],
      url: [url, [Validators.required]]
    }));
  }

  public removeSubmenuItem(i: number): void {
    const control = <FormArray>this.editorMenuGeneralForm.controls['submenu'];
    control.removeAt(i);
  }

  public addUrlAlias(redirectPath?: string): void {

    if (typeof redirectPath !== 'string') {
      redirectPath = '';
    }

    const control = <FormArray>this.editorMenuGeneralForm.controls['redirectPaths'];
    control.push(this.formBuilder.control(redirectPath, [
      Validators.required,
      Validators.pattern(/^(?:\s*[a-zA-Z0-9\-]+\s*,\s*)*(?:\s*[a-zA-Z0-9\-]+\s*)$/)
    ]));

  }

  public removeUrlAlias(i: number): void {
    const control = <FormArray>this.editorMenuGeneralForm.controls['redirectPaths'];
    control.removeAt(i);
  }

  public addCategory(category?: string): void {

    if (typeof category !== 'string') {
      category = '';
    }

    const control = <FormArray>this.editorMenuGeneralForm.controls['categories'];
    control.push(this.formBuilder.control(category, [
      Validators.required,
      Validators.pattern(/^(?:\s*[a-zA-Z0-9\-]+\s*,\s*)*(?:\s*[a-zA-Z0-9\-]+\s*)$/)
    ]));

  }

  public removeCategory(i: number): void {
    const control = <FormArray>this.editorMenuGeneralForm.controls['categories'];
    control.removeAt(i);
  }

  public backgroundImgUrl(url: string): string {
    if (typeof url !== 'string') {
      url = '';
    }
    return `url("${url}")`;
  }

  public syncFormToModel(form: FormGroup): void {

    for (const field in form.value) {
      if (form.value.hasOwnProperty(field)) {

        switch (field) {
          case 'listingBuyerFee':
            this.articleEdited.data.listingBuyerFee = parseFloat(form.value[field]);
            break;
          case 'listingCompanyName':
            this.articleEdited.data.listingCompanyName = form.value[field];
            break;
          case 'listingCompanyIndustry':
            this.articleEdited.data.listingCompanyIndustry = form.value[field];
            break;
          case 'projectCodeName':
            this.articleEdited.data.projectCodeName = form.value[field];
            break;
          case 'buyerInterestsByState':
            this.articleEdited.data.buyerInterestsByState = form.value[field];
            break;
          case 'listingLocation':
            this.articleEdited.data.listingLocation = form.value[field];
            break;
          case 'listingLocationState':
            this.articleEdited.data.listingLocationState = form.value[field];
            break;
          case 'listingPrice':
            this.articleEdited.data.listingPrice = parseInt(form.value[field], 10);
            break;
          case 'buyerInterestsPriceMin':
            this.articleEdited.data.buyerInterestsPriceMin = parseInt(form.value[field], 10);
            break;
          case 'buyerInterestsPriceMax':
            this.articleEdited.data.buyerInterestsPriceMax = parseInt(form.value[field], 10);
            break;
          case 'exioScore':
            this.articleEdited.data.exioScore = parseInt(form.value[field], 10);
            break;
          case 'naicsCode':
            this.articleEdited.data.naicsCode = form.value[field];
            break;
          case 'buyerInterestsNAICS':
            this.articleEdited.data.buyerInterestsNAICS = form.value[field];
            break;
          case 'listingLink':
            this.articleEdited.data.listingId = form.value[field];
            break;
          case 'actions':

            this.articleEdited[field] = [];

            form.value.actions.forEach((action: { name: string, url: string }) => {

              action.name = action.name.trim();
              action.url = action.url.trim();

              if (action.name.length > 0 || action.url.length > 0) {
                this.articleEdited[field].push({
                  name: action.name,
                  actionType: 'url',
                  actionParams: {
                    href: action.url
                  }
                });
              }

            });

            break;
          case 'submenu':

            this.articleEdited[field] = [];

            form.value.submenu.forEach((submenuItem: { name: string, url: string }) => {

              submenuItem.name = submenuItem.name.trim();
              submenuItem.url = submenuItem.url.trim();

              if (submenuItem.name.length > 0 || submenuItem.url.length > 0) {
                this.articleEdited[field].push({
                  name: submenuItem.name,
                  url: submenuItem.url
                });
              }

            });

            break;
          case 'redirectPaths':

            this.articleEdited[field] = [];

            form.value.redirectPaths.forEach((name: string) => {

              name = name.toLowerCase().trim();

              if (name.length > 0 && this.articleEdited[field].indexOf(name) < 0) {
                this.articleEdited[field].push(name);
              }

            });

            break;
          case 'categories':

            this.articleEdited[field] = [];

            form.value.categories.forEach((name: string) => {

              name = name.toLowerCase();

              const parts = name.split('-');

              parts.forEach((part, i) => {
                parts[i] = part.trim();
              });

              name = parts.join(' ');


              if (name.length > 0 && this.articleEdited[field].indexOf(name) < 0) {
                this.articleEdited[field].push(name);
              }

            });

            break;
          default:
            this.articleEdited[field] = form.value[field];
            break;
        }

      }
    }

    this.editorDirtyCheck();

  }

  public getEditorDirtyCheckHandler() {

    if (typeof this.generalDirtyCheckHandler !== 'function') {

      const self = this;
      this.generalDirtyCheckHandler = () => {
        self.editorDirtyCheck();
      };
    }

    return this.generalDirtyCheckHandler;

  }

  public editorDirtyCheck() {

    if (this.articleEdited && Array.isArray(this.articleEdited.sections)) {
      this.articleEdited.sections.forEach((section) => {

        if (section.delete) {
          // console.log( 'section delete 1', section );
        }

      });
    }

    const newState = JSON.stringify(this.article) !== JSON.stringify(this.articleEdited);

    // only trigger timeout if state changed
    if (newState !== this.dirty) {

      window.setTimeout(() => {
        this.zone.run(() => {
          this.dirty = newState;
          this.cdRef.markForCheck();
        });
      });

    }

    return newState;

  }

  public saveEnabled(): boolean {
    return this.dirty;
  }

  public resetEnabled(): boolean {
    return this.dirty;
  }

  public sanitizeForSave(article: Article): Article {

    article = this.app.articleModel.clone(article);

    if (Array.isArray(article.sections)) {
      article.sections.forEach((section) => {
        delete (<{ editing: boolean }>section).editing;
      });
    }

    if (typeof article.created === 'string' && article.created.match(/[0-9]+\/[0-9]+\/[0-9]+/)) {
      const parts = article.created.match(/([0-9]+)\/([0-9]+)\/([0-9]+)/);
      article.created = `${parts[3]}-${parts[1]}-${parts[2]}`
    }

    article.created = moment(article.created).toISOString();

    // these fields are managed elsewhere do not overwrite them here when saving.
    delete article.adminUsers;
    delete article.accessLevelExclusive;
    delete article.accessLevelExclusivePending;
    delete article.accessLevelPrivate;
    delete article.accessLevelPrivatePending;
    delete article.accessLevelInvites;

    return article;

  }

  public save(): void {

    if (this.editorMenuGeneralForm) {
      this.syncFormToModel(this.editorMenuGeneralForm);
    }

    this.saving = true;

    this.app
      .articleModel
      .save(this.sanitizeForSave(this.articleEdited))
      .then((article: Article) => {

        this.zone.run(() => {
          this.saving = false;

          if (article) {
            if (article.id !== this.articleEdited.id || article.id !== this.stateArticleId) {
              this.location.replaceState('/articles-editor/' + article.id);
            }
          }

          this.articleEdited.id = article.id;
          this.articleEdited.data = article.data;
          this.stateArticleId = article.id;

          this.setReferenceArticle(this.articleEdited);
          this.editorDirtyCheck();

          this.cdRef.markForCheck();
        });

      })
      .catch((err) => {

        if (err) {
          this.setSaveMessage(err.message || 'An unknown error occurred');
        }

        this.zone.run(() => {
          this.saving = false;
          this.cdRef.markForCheck();
        });
      });

  }

  public setReferenceArticle(article: Article): void {
    this.article = this.app.articleModel.clone(article);

    if (this.editorDirtyCheck()) {
      this.renderEditor();
    }

  }

  public setEditedArticle(article: Article): void {

    this.articleEdited = this.app.articleModel.clone(article);

    if (this.editorDirtyCheck()) {
      this.renderEditor();
    }

  }

  public setArticle(article: Article): void {

    if (article) {
      this.setReferenceArticle(article);
      this.setEditedArticle(article);
    }

  }

  public cancel(): void {

    this.resetArticle(this.article.id);

  }

  public viewListingReference(): void {

    const selectedListing = this.$el.find('.article-listing-reference select').val();

    window.open('/action/view-listing/begin/target-init/' + selectedListing, 'Listing Reference');

  }

  public viewListingVdr(): void {

    const selectedListing = this.$el.find('.article-listing-reference select').val();

    let listing = null;

    this.listings.forEach((listingCheck) => {

      if (listingCheck.id === selectedListing) {
        listing = listingCheck;
      }
    });

    if (listing && listing.data && listing.data.vdrUrl) {
      window.open(listing.data.vdrUrl, '_blank');
    } else {
      alert('Error opening VDR, please refresh and try again');
    }

  }

  public hasArticleVdr(): boolean {
    return !!this.article && !!this.article.data && typeof this.article.data.vdrUrl === 'string';
  }

  public viewArticleVdr(): void {

    if (this.hasArticleVdr()) {
      window.open(<string>this.article.data.vdrUrl, '_blank');
    } else {
      alert('Error opening VDR, please refresh and try again');
    }

  }


  public getProfileName(id: string): string {

    const profile = this.getProfile(id);

    if (!profile) {
      return 'Loading...';
    }

    return `${profile.lastName}, ${profile.firstName} <${profile.email}>`;

  }

  public getProfile(id: string): Profile {

    if (typeof id !== 'string' || id.length < 1) {
      return null;
    }

    if (!this.profilesById.hasOwnProperty(id)) {

      this.profilesById[id] = null;

      this.app.profileModel.get(id)
        .then((profile: Profile) => {
          this.zone.run(() => {
            this.profilesById[id] = profile;
          });
        })
        .catch((e) => {
          console.error('error listing profile: ' + id, e);
        });

    }

    return this.profilesById[id];

  }


  // public accessLevelProfilesReady(): boolean {
  //
  // 	return Array.isArray( this.profiles );
  // }

  public accessLevelProfiles(): Profile[] {
    return (this.profiles || []).filter((profile) => {
      return !profile.isEmployee;
    });
  }

  // public accessLevelProfileOptions(): string[][] {
  //
  // 	let options = [];
  //
  // 	let profiles = this.accessLevelProfiles();
  //
  // 	if ( !Array.isArray( this.articleEdited.accessLevelExclusive ) ) {
  // 		this.articleEdited.accessLevelExclusive = [];
  // 	}
  //
  // 	profiles.forEach( ( profile ) => {
  //
  // 		if ( this.articleEdited.accessLevelExclusive.indexOf( profile.id ) > -1 ) {
  // 			return;
  // 		}
  //
  // 		options.push( [ profile.id, `${profile.lastName}, ${profile.firstName} <${profile.email}>` ] )
  // 	} );
  //
  // 	if ( options.length < 1 ) {
  // 		this.accessLevelUserSelected = null;
  // 	}
  //
  // 	return options;
  //
  // }

  public revertVersion(): void {

    const index: number = parseInt(this.selectedVersion, 10);

    if (index < 0 || index - 1 > this.articleHistory.length) {
      return;
    }

    this.zone.run(() => {
      if (!this.confirm('Are you sure you want to revert and discard unsaved changes?')) {
        return;
      }

      this.selectedVersion = '-1';
      this.setEditedArticle(this.articleHistory[index]);
      this.cdRef.markForCheck();
    });

  }

  public confirm(message): boolean {

    if (!this.dirty) {
      return true;
    }

    return window.confirm(message);

  }

  // public accessLevelUserAdd( level: 'exclusive' | 'private' ): void {
  //
  // 	if ( !this.articleEdited ) {
  // 		return;
  // 	}
  //
  // 	let $input = this.$el.find( '.article-access-level-selection-container' ).find( 'select' );
  // 	let profileId = $input.val();
  //
  // 	if ( typeof profileId !== 'string' || profileId.trim().length < 1 ) {
  // 		return;
  // 	}
  //
  // 	if ( !Array.isArray( this.articleEdited.accessLevelExclusive ) ) {
  // 		this.articleEdited.accessLevelExclusive = [];
  // 	}
  // 	if ( !Array.isArray( this.articleEdited.accessLevelPrivate ) ) {
  // 		this.articleEdited.accessLevelPrivate = [];
  // 	}
  //
  // 	this.accessLevelUserRemove( profileId ); // only put user on one list at a time
  //
  // 	if ( level === 'exclusive' ) {
  // 		if ( this.articleEdited.accessLevelExclusive.indexOf( profileId ) < 0 ) {
  // 			this.articleEdited.accessLevelExclusive.push( profileId )
  // 		}
  // 	} else if ( level === 'private' ) {
  // 		if ( this.articleEdited.accessLevelPrivate.indexOf( profileId ) < 0 ) {
  // 			this.articleEdited.accessLevelPrivate.push( profileId )
  // 		}
  // 	}
  //
  // }

  // public accessLevelUserRemove( profileId: string ): void {
  //
  // 	if ( !this.articleEdited ) {
  // 		return;
  // 	}
  //
  // 	if ( !Array.isArray( this.articleEdited.accessLevelExclusive ) ) {
  // 		this.articleEdited.accessLevelExclusive = [];
  // 	}
  //
  // 	let index = this.articleEdited.accessLevelExclusive.indexOf( profileId );
  // 	if ( index > -1 ) {
  // 		this.articleEdited.accessLevelExclusive.splice( index, 1 );
  // 	}
  // 	index = this.articleEdited.accessLevelPrivate.indexOf( profileId );
  // 	if ( index > -1 ) {
  // 		this.articleEdited.accessLevelPrivate.splice( index, 1 );
  // 	}
  //
  // }

  public tileImgUrl(large: boolean): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(large ? this.articleEdited.tileImgLargeUrl : this.articleEdited.tileImgUrl);
  }

  public showSubMenuCode(): void {
    this.submenuDataMode = true;
    window.setTimeout(() => {

      const $textarea = this.$el.find('.article-submenus .article-submenus-code textarea');

      if (!Array.isArray(this.articleEdited.submenu)) {
        this.articleEdited.submenu = [];
      }

      $textarea.val(JSON.stringify(this.articleEdited.submenu, null, 4));

    });
  }

  public showSubMenuForm(): void {

    if (!this.isValidSubMenuData()) {
      return;
    }

    this.submenuDataMode = false;

    const $textarea = this.$el.find('.article-submenus .article-submenus-code textarea');

    const control = <FormArray>this.editorMenuGeneralForm.controls['submenu'];
    while (control.length > 0) {
      control.removeAt(control.length - 1);
    }

    this.articleEdited.submenu = JSON.parse($textarea.val());
    if (!Array.isArray(this.articleEdited.submenu)) {
      this.articleEdited.submenu = [];
    }

    this.articleEdited.submenu.forEach((submenuItem) => {
      this.addSubmenuItem(submenuItem.name, submenuItem.url);
    });

  }


  public isValidSubMenuData(): boolean {

    let result = false;

    const $textarea = this.$el.find('.article-submenus .article-submenus-code textarea');

    try {
      JSON.parse($textarea.val());
      result = true;
    } catch (e) {
      result = false;
    }

    return result;

  }

}
