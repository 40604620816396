
<div class="img-container">
  <div class="img-layout-container">
    <div class="img-layout-mobile img-layout-carousel" *ngIf="outputArticleUrls.length &gt;= 1">
      <div class="app-content-banner-carousel" [articles]="outputArticleUrls" [navigationMode]="'gallery'" [topPadding]="0" [locked]="locked" [lockedCallback]="lockedCallback"></div>
    </div>
    <div class="img-layout img-layout-carousel" *ngIf="layout==='carousel' &amp;&amp; outputArticleUrls.length &gt;= 1">
      <div class="app-content-banner-carousel" [articles]="outputArticleUrls" [navigationMode]="'gallery'" [topPadding]="0" [locked]="locked" [lockedCallback]="lockedCallback"></div>
    </div>
    <div class="img-layout img-layout-1by2-reverse" *ngIf="layout==='1by2-reverse' &amp;&amp; getUrls().length &gt;= 3">
      <div class="img-content-container">
        <div class="img-content" [class.show-locked]="locked" [style.background-image]="cleanUrl(0)" [style.background-position]="cleanPosition(0)">
          <div class="locked-icon" (click)="clickedLock()">
            <div class="component-icon lock-white"></div>
          </div>
        </div>
        <div class="img-content" [class.show-locked]="locked" [style.background-image]="cleanUrl(1)" [style.background-position]="cleanPosition(1)">
          <div class="locked-icon" (click)="clickedLock()">
            <div class="component-icon lock-white"></div>
          </div>
        </div>
      </div>
      <div class="img-content-container">
        <div class="img-content" [class.show-locked]="locked" [style.background-image]="cleanUrl(2)" [style.background-position]="cleanPosition(2)">
          <div class="locked-icon" (click)="clickedLock()">
            <div class="component-icon lock-white"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="img-layout img-layout-1by2" *ngIf="layout==='1by2' &amp;&amp; getUrls().length &gt;= 3">
      <div class="img-content-container">
        <div class="img-content" [class.show-locked]="locked" [style.background-image]="cleanUrl(0)" [style.background-position]="cleanPosition(0)">
          <div class="locked-icon" (click)="clickedLock()">
            <div class="component-icon lock-white"></div>
          </div>
        </div>
      </div>
      <div class="img-content-container">
        <div class="img-content" [class.show-locked]="locked" [style.background-image]="cleanUrl(1)" [style.background-position]="cleanPosition(1)">
          <div class="locked-icon" (click)="clickedLock()">
            <div class="component-icon lock-white"></div>
          </div>
        </div>
      </div>
      <div class="img-content-container">
        <div class="img-content" [class.show-locked]="locked" [style.background-image]="cleanUrl(2)" [style.background-position]="cleanPosition(2)">
          <div class="locked-icon" (click)="clickedLock()">
            <div class="component-icon lock-white"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="img-layout img-layout-3across" *ngIf="layout==='3across' &amp;&amp; getUrls().length &gt;= 3">
      <div class="img-content-container">
        <div class="img-content" [class.show-locked]="locked" [style.background-image]="cleanUrl(0)" [style.background-position]="cleanPosition(0)">
          <div class="locked-icon" (click)="clickedLock()">
            <div class="component-icon lock-white"></div>
          </div>
        </div>
      </div>
      <div class="img-content-container">
        <div class="img-content" [class.show-locked]="locked" [style.background-image]="cleanUrl(1)" [style.background-position]="cleanPosition(1)">
          <div class="locked-icon" (click)="clickedLock()">
            <div class="component-icon lock-white"></div>
          </div>
        </div>
      </div>
      <div class="img-content-container">
        <div class="img-content" [class.show-locked]="locked" [style.background-image]="cleanUrl(2)" [style.background-position]="cleanPosition(2)">
          <div class="locked-icon" (click)="clickedLock()">
            <div class="component-icon lock-white"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="img-layout img-layout-23split" *ngIf="layout === '2/3split' &amp;&amp; getUrls().length &gt;= 2">
      <div class="img-content-container">
        <div class="img-content" [class.show-locked]="locked" [style.background-image]="cleanUrl(0)" [style.background-position]="cleanPosition(0)">
          <div class="locked-icon" (click)="clickedLock()">
            <div class="component-icon lock-white"></div>
          </div>
        </div>
      </div>
      <div class="img-content-container">
        <div class="img-content" [class.show-locked]="locked" [style.background-image]="cleanUrl(1)" [style.background-position]="cleanPosition(1)">
          <div class="locked-icon" (click)="clickedLock()">
            <div class="component-icon lock-white"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="img-layout img-layout-23split-reverse" *ngIf="layout === '2/3split-reverse' &amp;&amp; getUrls().length &gt;= 2">
      <div class="img-content-container">
        <div class="img-content" [class.show-locked]="locked" [style.background-image]="cleanUrl(0)" [style.background-position]="cleanPosition(0)">
          <div class="locked-icon" (click)="clickedLock()">
            <div class="component-icon lock-white"></div>
          </div>
        </div>
      </div>
      <div class="img-content-container">
        <div class="img-content" [class.show-locked]="locked" [style.background-image]="cleanUrl(1)" [style.background-position]="cleanPosition(1)">
          <div class="locked-icon" (click)="clickedLock()">
            <div class="component-icon lock-white"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>