
<div class="app-content-banner-carousel mobile-layout" [articles]="carousel" [navigationMode]="'dots'" [topPadding]="0"></div>
<div class="app-content-banner-carousel desktop-layout" [articles]="carousel" [navigationMode]="'dots'" [topPadding]="68"></div>
<div class="assertions">
  <div class="description-container">
    <div class="description">
      <p>Bridging the gap between compelling companies for sale and qualified, vetted buyers looking to acquire, Exio is the <strong>Ultimate Matchmaking System ™ and the world’s most effective approach to deal making</strong></p>
    </div>
  </div>
</div>
<div class="service-assertions" id="services">
  <div class="services-container">
    <div class="service-container">
      <div class="service">
        <div class="service-icon">
          <div class="component-icon sell-your-company"></div>
        </div>
        <h1 class="service-hint">Sell Your<br>Company</h1>
        <div class="service-description">Attract Premier Buyers in our complete<br>and confidential Private Marketplace<br>with the unique story of your business.</div><a class="service-call-to-action" [href]="'https://form.jotform.com/TeamExio/exio-seller-intake-form'">Start Selling</a><a class="seo-link" [href]="'https://form.jotform.com/TeamExio/exio-seller-intake-form'">Start Selling</a>
        <div class="marketplace-call-to-action" [routerLink]="'/selling'">Learn More</div><a class="seo-link" [href]="'/selling'">Learn More</a>
      </div>
    </div>
    <div class="service-container">
      <div class="service">
        <div class="service-icon">
          <div class="component-icon buy-a-company"></div>
        </div>
        <h1 class="service-hint">Buy A<br>Company</h1>
        <div class="service-description">Build your Premier Buyer platform,<br>get a first look at our Private Sellers<br>and promote your story of success.</div><a class="service-call-to-action" [routerLink]="'/marketplace/private-sellers'">Start Buying</a><a class="seo-link" [href]="'/marketplace/private-sellers'">Start Buying</a>
        <div class="marketplace-call-to-action" [routerLink]="'/buying'">Join Premier Buyer Program</div><a class="seo-link" [href]="'/buying'">Join Premier Buyer Program</a>
      </div>
    </div>
    <div class="service-container">
      <div class="service">
        <div class="service-icon">
          <div class="component-icon grow-your-company"></div>
        </div>
        <h1 class="service-hint">Educational<br>Resources</h1>
        <div class="service-description">Discover exit strategy best<br>practices, including tips on<br>maximizing your exit ROI.</div>
        <div class="service-call-to-action" [routerLink]="'/blog'">Start Learning</div><a class="seo-link" [href]="'/blog'">Start Learning</a>
        <div class="marketplace-call-to-action" [routerLink]="'/video-library'">Video Library</div><a class="seo-link" [href]="'/video-library'">Video Library</a>
      </div>
    </div>
  </div>
</div>
<div class="featured-content-container">
  <div class="featured-content">Featured Companies For Sale</div>
</div>
<div class="app-content-homepage-tiles"></div>