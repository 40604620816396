
<app-content-banner [article]="bannerArticle"></app-content-banner>
<app-submenu *ngIf="bannerArticle &amp;&amp; bannerArticle.submenu &amp;&amp; bannerArticle.submenu.length &gt; 0" [items]="bannerArticle.submenu"></app-submenu>
<!--.video-toolbar-->
<!--	.title Video Library-->
<!--.loader-spinner(*ngIf="this.loadingVideoArticlesContent")-->
<!--ul.grid(*ngIf="!this.loadingVideoArticlesContent")-->
<!--	li.grid-item(*ngFor="let video of sortedVideoArticles()", [style.background-image]="sanitizer.bypassSecurityTrustStyle(imgUrl(video.thumbnail))")-->
<!--		//.content-subscribe(*ngIf="video.subscribe")-->
<!--		//	.subscribe-processing(*ngIf="subscribing")-->
<!--		//		.loader-spinner-->
<!--		//	.subscribe-complete(*ngIf="subscribeComplete")-->
<!--		//		.thank-you Thank you for subscribing!-->
<!--		//	.subscribe-form(*ngIf="!subscribing && !subscribeComplete")-->
<!--		//		.title1 Stay in tune-->
<!--		//		.title2 with exio-->
<!--		//		.description Sign up today and be the first to receive Exio video content-->
<!--		//		form.tile(#subscribeModel="ngForm", (ngSubmit)="subscribe()")-->
<!--		//			input(type="email", [attr.placeholder]="placeHolderText", (focus)="placeHolderText = ''", (blur)="placeHolderText = 'Please enter your email address'", name="email", [(ngModel)]="subscribeEmail")-->
<!--		//			button(type="submit", [disabled]="!subscribeModel.form.valid") Subscribe-->
<!--		.vimeo-player-service(*ngIf="video.hasOwnProperty('id')", [videoId]="video.id", [fillType]="'height'")-->
<!--			.tags-->
<!--				.tag(*ngFor="let tag of video.tags") {{ tag }}-->
<!--			.title(*ngIf="video.name") {{video.name}}-->
<!--			.description(*ngIf="video.description") {{video.description}}-->
<div class="video-container">
  <div class="video-toolbar">
    <div class="article-count" *ngIf="getArticles().length &gt; 0">{{articleCountText()}}</div>
    <div class="selects">
      <div class="filter-select">
        <select [(ngModel)]="filterCategory">
          <option *ngFor="let option of filterOptions" value="{{option[0]}}">{{ option[1] }}&nbsp;&nbsp;&nbsp;&nbsp;</option>
        </select>
        <div class="component-icon-container">
          <div class="component-icon arrow-down"></div>
        </div>
      </div>
      <div class="sort-select">
        <select [(ngModel)]="sortField">
          <option *ngFor="let option of sortOptions" value="{{option[0]}}">{{ option[1] }}&nbsp;&nbsp;&nbsp;&nbsp;</option>
        </select>
        <div class="component-icon-container">
          <div class="component-icon arrow-down"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="loader-spinner" *ngIf="loadingContent"></div>
  <div class="video-list-container"><a class="seo-link" *ngFor="let article of getArticles();" [href]="'/video-library/' + article.id">{{ article.title }}</a>
    <div class="video-entry" *ngFor="let article of getArticles();" (click)="go(article)">
      <div class="video-entry-container">
        <div class="video-entry-new" *ngIf="is(article, 'new')">
          <div class="video-entry-new-container">New</div>
          <div class="video-entry-new-tail"></div>
        </div>
        <div class="video-entry-contents">
          <div class="banner-image" [style.background-image]="backgroundImageStyle(article)">
            <div class="play-icon">
              <div class="component-icon vimeo-play"></div>
            </div>
          </div>
          <div class="video-copy">
            <div class="video-created-date">{{ article.created | date:'longDate' }}</div>
            <div class="video-title">{{ article.title }}</div>
            <div class="video-break"></div>
            <div class="video-description">{{ article.description }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>