
<div class="vimeo-modal vmieo-hide" (click)="closeModal()"></div>
<div class="vimeo-container vimeo-hide">
  <div class="vimeo-message vimeo-hide"></div>
  <div class="vimeo-wait vimeo-hide">
    <div class="loader-spinner"></div>
  </div>
  <div class="vimeo-content vimeo-hide">
    <div class="vimeo-full-screen">
      <div class="logo"></div>
      <div class="close-full-screen" (click)="closeModal()">
        <div class="component-icon close-handle"></div>
      </div>
    </div>
    <div class="vimeo-controls vimeo-custom-hide vimeo-hide">
      <div class="play-pause">
        <div class="play vimeo-hide"></div>
        <div class="pause vimeo-hide"></div>
      </div>
      <div class="time-parts">
        <div class="time-played"></div>
        <div class="time-progress-bar">
          <div class="buffer-progress-fill-container">
            <div class="buffer-progress-fill"></div>
          </div>
          <div class="time-progress-fill-container">
            <div class="time-progress-fill"></div>
          </div>
          <input class="time-progress-slider" type="range" min="0" max="100" step="0.1" value="0">
        </div>
        <div class="time-total"></div>
      </div>
      <div class="full-screen vimeo-hide">
        <div class="full-screen-icon"></div>
      </div>
    </div>
    <div class="vimeo-close-full-screen vimeo-hide"></div>
    <div class="vimeo-thumbnail vimeo-custom-hide">
      <div class="vimeo-thumbnail-play"></div>
    </div>
    <div class="vimeo-video"></div>
  </div>
</div>