import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'app/services/app';
import { ArticleGridSection, ArticleSectionContentGrid } from 'app/models/article';

@Component( {
	moduleId: module.id,
	selector: '.section-content.section-grid',
	templateUrl: 'grid.component.html',
	styleUrls: [ 'grid.component.less' ]
} )
export class ContentRenderGridComponent implements OnInit {

	@Input()
	public section: ArticleGridSection;

	@Input()
	public mode: 'normal' | 'edit' = 'normal';

	@Input()
	public renderMode: 'desktop' | 'mobile' = 'desktop';

	public mobileFields: string[] = [];

	constructor( public appService: AppService ) {
	}

	public ngOnInit(): void {

		let content = this.getContent();

		for ( let i = 0; i < content.fields.length; i++ ) {
			for ( let j = 0; j < content.fields[ i ].length; j++ ) {
				this.mobileFields.push( content.fields[ i ][ j ] );
			}
		}

	}

	public editMode(): boolean {
		return this.mode === 'edit';
	}

	public getContent(): ArticleSectionContentGrid {

		if ( this.section && this.section.content ) {
			return <ArticleSectionContentGrid>this.section.content;
		}

		return null;

	}

	public columnWidth(): string {

		if ( this.renderMode === 'mobile' ) {
			return '50%';
		}

		return Math.floor( 100 / this.getContent().columns ) + '%';
	}

	public iterator( count: number ): number[] {

		count = Math.max( 0, Math.floor( count ) );

		return Array( count ).fill( 1 ).map( ( x, i ) => {
			return i;
		} );

	}

	public cellExists( i: number, j: number ): boolean {

		i = Math.max( 0, Math.floor( i ) );
		j = Math.max( 0, Math.floor( j ) );

		if ( !this.section || !this.section.content ) {
			return false;
		}

		let content = this.getContent();

		return Array.isArray( content.fields ) && Array.isArray( content.fields[ i ] ) && typeof content.fields[ i ][ j ] === 'string';

	}

	public getFieldContent( i: number, j: number ): string {

		if ( this.cellExists( i, j ) ) {

			let content = this.getContent();

			if ( this.editMode() ) {

				if ( typeof content.fields[ i ][ j ] === 'string' && content.fields[ i ][ j ].trim().length > 0 ) {

					return this.markdownToHtml( content.fields[ i ][ j ] );
				} else {
					return 'Double click to edit';
				}

			} else {
				return this.markdownToHtml( content.fields[ i ][ j ] );
			}

		}

		return null;

	}

	public markdownToHtml( markdownStr: string ): string {

		if ( typeof markdownStr !== 'string' ) {
			markdownStr = '';
		}

		return this.appService.markdownToHtml( markdownStr );

	}

}
