
<div class="page-title">Edit Contract</div>
<div class="editor-controls">
  <button class="save-document" *ngIf="documentEdited" (click)="save()" [class.enabled]="dirty">Save</button>
  <button class="cancel-document" *ngIf="documentEdited" (click)="reset()" [class.enabled]="dirty">Reset</button>
  <div class="go-instances control-link" *ngIf="this.documentEdited &amp;&amp; this.documentEdited.id &amp;&amp; this.documentEdited.id !=='new'" [routerLink]="[ '/contracts', documentEdited.id, 'instance' ]">Go to Instances</div>
</div>
<div class="editor-body" *ngIf="documentEdited">
  <div class="input-label">Name</div>
  <input class="document-name" [(ngModel)]="documentEdited.name" placeholder="Document Name">
  <div class="input-label">URL</div>
  <input class="document-url" [(ngModel)]="documentEdited.url" placeholder="non-disclosure-agreement | privacy-agreement | confidentiality | etc">
  <div class="input-label">Instructions</div>
  <textarea class="document-instructions" [(ngModel)]="documentEdited.instructions" placeholder="Instructions or other message for time of signing"></textarea>
  <div class="input-label">Title
    <input class="document-title" [(ngModel)]="documentEdited.title" placeholder="The title of the document, which displays in final signed document">
  </div>
  <div class="input-label">Parties</div>
  <input class="document-title" [(ngModel)]="documentEdited.parties" placeholder="The general parties description at the top of the contract [optional, leave blank for default party list]">
  <div class="input-label">Description</div>
  <textarea class="document-description" [(ngModel)]="documentEdited.description" placeholder="The description of the purpose of the document, which displays in the final signed document"></textarea>
  <div class="input-label">Signature Success</div>
  <textarea class="document-signature-success" [(ngModel)]="documentEdited.signatureSuccess" placeholder="The URL to redirect to upon signature success, or text to display"></textarea>
  <div class="input-label">Sections
    <div class="document-section-action control-link" (click)="addSection()">Add</div>
  </div>
  <div class="document-sections-container">
    <div class="document-sections-none" *ngIf="documentEdited.sections.length &lt; 1">No Sections Yet</div>
    <div class="document-sections" *ngIf="documentEdited.sections.length &gt; 0">
      <div class="document-section" *ngFor="let section of documentEdited.sections; let i = index;">
        <div class="document-section-container">
          <label>Title</label>
          <input class="document-section-title" [(ngModel)]="section.title" placeholder="Definitions | Jurisdiction | etc">
          <label>Contents</label>
          <textarea class="document-section-contents" [(ngModel)]="section.contents" placeholder="Section contents"></textarea>
          <div class="document-section-actions">
            <div class="document-section-action control-link" (click)="removeSection(i)">Remove</div>
            <div class="document-section-action" *ngIf="i &gt; 0">|</div>
            <div class="document-section-action control-link" *ngIf="i &gt; 0" (click)="moveSectionUp(i)">Move Up</div>
            <div class="document-section-action" *ngIf="i &lt; documentEdited.sections.length - 1">|</div>
            <div class="document-section-action control-link" *ngIf="i &lt; documentEdited.sections.length - 1" (click)="moveSectionDown(i)">Move Down</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="input-label" *ngIf="contacts &amp;&amp; contacts.length &gt; 0">Pre-Signers</div>
  <div class="pre-signers-container" *ngIf="!contacts || contacts.length &lt; 1">Loading...</div>
  <div class="pre-signers-container" *ngIf="contacts &amp;&amp; contacts.length &gt; 0">
    <select class="pre-signers-selector change-detection-tracking" *ngIf="getContactOptions().length &gt; 0">
      <option *ngFor="let option of getContactOptions()" [value]="option[0]">{{ option[1] }}</option>
    </select>
    <div class="control-link" *ngIf="getContactOptions().length &gt; 0" (click)="addPreSigner()">Add</div>
    <div class="pre-signers">
      <div class="pre-signer" *ngFor="let signer of getPreSigners(); let i = index;">
        <div class="pre-signer-name col-xs-4" *ngIf="signer.lastName">{{ signer.lastName }}, {{ signer.firstName }}</div>
        <div class="pre-signer-name col-xs-4" *ngIf="!signer.lastName">{{ signer.firstName }}</div>
        <div class="pre-signer-name col-xs-4" *ngIf="signer.email"><a [href]="'mailto:' + signer.email">{{ signer.email }}</a></div>
        <div class="pre-signer-name col-xs-4" *ngIf="!signer.email">No Email On File</div>
        <div class="pre-signer-controls col-xs-4">
          <div class="pre-signer-control control-link" (click)="removePreSigner(signer.id)">Remove</div>
          <div class="pre-signer-control control-link" *ngIf="i &gt; 0;">|</div>
          <div class="pre-signer-control control-link" *ngIf="i &gt; 0;" (click)="moveSignerUp(signer.id)">Move Up</div>
          <div class="pre-signer-control control-link" *ngIf="i &lt; documentEdited.preSigners.length - 1">|</div>
          <div class="pre-signer-control control-link" *ngIf="i &lt; documentEdited.preSigners.length - 1" (click)="moveSignerDown(signer.id)">Move Down</div>
        </div>
      </div>
    </div>
  </div>
  <div class="input-label">Version</div>
  <input class="document-version" [(ngModel)]="documentEdited.version">
  <div class="input-label">Notifications</div>
  <div class="notifications-container" *ngIf="!employeeProfiles || employeeProfiles.length &lt; 1">Loading...</div>
  <div class="notifications-container" *ngIf="employeeProfiles &amp;&amp; employeeProfiles.length &gt; 0">
    <select class="notifications-selector change-detection-tracking" *ngIf="getNotificationOptions().length &gt; 0">
      <option *ngFor="let option of getNotificationOptions()" [value]="option[0]">{{ option[1] }}</option>
    </select>
    <div class="control-link" *ngIf="getNotificationOptions().length &gt; 0" (click)="addNotification()">Add</div>
    <div class="notifications">
      <div class="notification" *ngFor="let notification of getNotifications(); let i = index;">
        <div class="notification-name col-xs-4" *ngIf="notification.lastName">{{ notification.lastName }}, {{ notification.firstName }}</div>
        <div class="notification-name col-xs-4" *ngIf="!notification.lastName">{{ notification.firstName }}</div>
        <div class="notification-name col-xs-4" *ngIf="notification.email"><a [href]="'mailto:' + notification.email">{{ notification.email }}</a></div>
        <div class="notification-email col-xs-4" *ngIf="!notification.email">No Email On File</div>
        <div class="notification-controls col-xs-4">
          <div class="notification-control control-link" (click)="removeNotification(notification.id)">Remove</div>
        </div>
      </div>
    </div>
  </div>
</div>