import {Component, ElementRef, OnInit, OnDestroy, AfterViewChecked, NgZone} from '@angular/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {AppService} from 'app/services/app';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {Profile} from 'app/models/profile';
import {LogicService} from 'app/services/logic';
import {Hash} from 'app/types/containers';
import * as Cookie from 'js-cookie';
import * as Bluebird from 'bluebird';

type UpdateStatsFieldsValues = 'views' | 'page' | 'section';

import {
  Workflow,
  WorkFlowTypes,
  WorkflowSection,
  WorkflowPage,
  WorkflowItem,
  WorkflowState,
  WorkflowTarget,
  WorkflowTargetOnValues
} from 'app/models/workflow';

interface QueryParams {
  completionRedirect?: string;
}

interface URLParams {
  workflow?: string;
  section?: string;
  page?: string;
  params?: string
}

@Component({
  moduleId: module.id,
  selector: 'app-view-workflow',
  templateUrl: 'main.component.html',
  styleUrls: ['main.component.less']
})
export class ViewsWorkflowMainComponent implements OnInit, OnDestroy, AfterViewChecked {


  // blur debounce
  public blurDeBounce: number = null;

  // public globalState: HashTree<string | number | boolean> = {};
  public workflow: string = null;
  public section: string = null;
  public page: string = null;

  public authenticatedProfile: Profile = null;

  public lastSection: string = null;
  public lastPage: string = null;

  public completionRedirectOverride: string = null;
  public urlParams: any = null;

  public currentSection: WorkflowSection = null;
  public currentPage: WorkflowPage = null;
  public workflowError: string = null;

  public socialLoginWait: number = null;
  public socialLoginWaitTimeout: boolean = false;

  public workflowTargetState: WorkflowState = {};
  public workflowTargetStatePrevious: WorkflowState = null;

  public workflowDefinition: Workflow = null;
  public workflowDefinitionRaw: Workflow = null;
  public workflowTargets: Hash<WorkflowTarget[]> = {};

  public subscriptions: any[] = [];

  public $el: JQuery;

  constructor(public app: AppService,
              public logicService: LogicService,
              public el: ElementRef,
              public route: ActivatedRoute,
              public location: Location,
              public router: Router,
              public sanitizer: DomSanitizer,
              public zone: NgZone) {

    this.app.contentLoading(true);
    this.app.content.backgroundLight = false;
    this.app.toolbar.whiteOverContent = true;
    this.app.toolbar.backgroundColor = '#4797EE';
    this.app.footer.showMenu = false;

    this.$el = $(el.nativeElement);

    this.app.titleService.setTitle(`Exio`);

    const profileEvent = (profile?: Profile | boolean) => {

      if (typeof profile === 'boolean') {
        return;
      }

      this.authenticatedProfile = <Profile>profile;

    };

    this.subscriptions.push(this.app.getAuthenticatedProfile({
      next: profileEvent,
      error: () => {
        profileEvent(null);
      },
      complete: () => {
        profileEvent(null);
      }
    }));

  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public ngOnInit(): void {

    this.app.contentLoading(true);

    document.body.scrollTop = 0;

    // this.subscriptions.push( this.location.subscribe( ( data: any ) => {
    // 	console.log( 'location data', data );
    // } ) );

    this.subscriptions.push(this.route.params.subscribe((params: URLParams) => {
      this.setupNewState(params);
    }));

    this.subscriptions.push(this.route.queryParams.subscribe((params: QueryParams) => {
      if (!params) {
        return;
      }

      if (params.completionRedirect) {
        this.completionRedirectOverride = params.completionRedirect;
      }

    }));

  }

  public type(): WorkFlowTypes {

    if (!this.workflowDefinition) {
      return 'linear';
    }

    return this.workflowDefinition.type;
  }

  public isType(type: WorkFlowTypes): boolean {
    return type === this.type();
  }

  public getMetaState(): any {
    return {
      workflowComplete: this.isCompleteWorkflow(),
    }
  }

  public updateWorkflowDefinition(): void {

    this.zone.run(() => {

      const raw = this.workflowDefinitionRaw;

      if (!raw) {
        this.workflowDefinition = null;
        return;
      }

      const def: Workflow = {
        id: raw.id || null,
        title: raw.title || null,
        type: raw.type || null,
        stats: raw.stats || null,
        completionText: raw.completionText || null,
        continueText: raw.continueText || null,
        completionRedirect: this.completionRedirectOverride || raw.completionRedirect || null,
        targetInit: raw.targetInit || null,
        targets: raw.targets || [],
        sections: []
      };

      const metaState = this.getMetaState();

      raw.sections.forEach((sectionRaw) => {

        if (sectionRaw.hasOwnProperty('show') && !this.logicService.eval({
          state: metaState,
          authenticatedProfile: this.authenticatedProfile,
          targetState: this.workflowTargetState
        }, sectionRaw.show)) {
          return;
        }

        const sectionDef = {
          id: sectionRaw.id || null,
          title: sectionRaw.title || null,
          hideComplete: sectionRaw.hideComplete || false,
          icon: sectionRaw.icon || null,
          show: sectionRaw.show || null,
          link: sectionRaw.link || null,
          continueDisabled: sectionRaw.continueDisabled || false,
          nextDisabled: sectionRaw.nextDisabled || false,
          menuShow: sectionRaw.hasOwnProperty('menuShow') ? sectionRaw.menuShow : true,
          continueText: sectionRaw.continueText || null,
          completionText: sectionRaw.completionText || null,
          pages: []
        };

        if (Array.isArray(sectionRaw.pages)) {

          sectionRaw.pages.forEach((pageRaw) => {

            if (pageRaw.hasOwnProperty('show') && !this.logicService.eval({
              state: metaState,
              authenticatedProfile: this.authenticatedProfile,
              targetState: this.workflowTargetState
            }, pageRaw.show)) {
              return;
            }

            const pageDef = JSON.parse(JSON.stringify(pageRaw));
            pageDef.items = [];

            pageRaw.items.forEach((item) => {
              if (item.hasOwnProperty('show') && !this.logicService.eval({
                state: metaState,
                authenticatedProfile: this.authenticatedProfile,
                targetState: this.workflowTargetState
              }, item.show)) {
                return;
              }

              pageDef.items.push(item);

            });

            if (pageDef.items.length > 0) {
              sectionDef.pages.push(pageDef);
            }

          });
        }

        if (sectionDef.pages.length > 0 || sectionDef.link) {
          def.sections.push(sectionDef);
        }

      });

      this.workflowDefinition = def;

      this.updateCurrentState();

    });

  }

  public setupNewState(params: URLParams): void {

    this.app.content.backgroundLight = false;
    this.app.toolbar.whiteOverContent = true;
    this.app.toolbar.backgroundColor = '#4797EE';
    this.app.footer.showMenu = false;

    this.app.toolbar.whiteOverContent = true;

    if (!params || typeof params.workflow !== 'string' || params.workflow.length < 1) {
      this.router.navigate(['/']);
      return;
    }

    const lastWorkflow = this.workflow;
    // let lastWorkflowSection = this.section;
    // let lastUrlParams = this.urlParams;

    this.workflow = params.workflow;
    this.section = params.section;
    this.page = params.page;
    this.urlParams = params.params || '';

    if (typeof this.urlParams === 'string' && this.urlParams.length > 0) {

      try {
        this.urlParams = JSON.parse(this.urlParams);
      } catch (e) {
        // NO-OP, must be literal string and not actually JSON
      }

    }

    if (lastWorkflow !== params.workflow) {

      this.app.contentLoading(true);
      this.workflowDefinition = null;
      this.workflowDefinitionRaw = null;
      this.workflowTargetState = {};
      this.workflowTargetStatePrevious = null;
      this.workflowTargets = {};

      this.app.workflowModel.get(this.workflow)
        .then((workflow: Workflow) => {

          if (!workflow || workflow.id !== this.workflow) {
            this.app.contentLoading(false);
            this.workflowError = `Something went wrong.\n\nPlease refresh your page in a moment and try again.`;
            return;
          }

          const go = () => {
            this.app.contentLoading(false);
            this.workflowDefinitionRaw = workflow;

            this.updateWorkflowDefinition();
            this.setupStateTargets();
            this.updateStats(['views']);
          };

          if (workflow.targetInit) {

            let targetInit = {};

            if (workflow.targetInit.params) {

              if (workflow.targetInit.params === '{{urlParams}}') {
                targetInit = this.urlParams;
              } else {
                targetInit = workflow.targetInit.params;
              }

            }

            if (workflow.targetInit.default) {

              const defaultState = workflow.targetInit.default || {};

              // set defaults if any
              // for ( let field in defaultState ) {
              // 	if ( defaultState.hasOwnProperty( field ) && !targetInit.hasOwnProperty( field ) ) {
              // 		targetInit[ field ] = defaultState[ field ];
              // 	}
              // }

            }

            const promise = this.triggerCall(workflow.targetInit, targetInit);

            if (promise) {

              return promise
                .then((data: any) => {

                  if (data) {

                    data = JSON.stringify(data);
                    this.workflowTargetState = JSON.parse(data);
                    this.workflowTargetStatePrevious = JSON.parse(data);

                  }

                  return go();

                })
                .catch((e) => {
                  console.error('error loading target init', workflow.targetInit, e);
                  return go();
                });
            }

          } else {
            window.setTimeout(go);
          }

          return null;

        })
        .catch((e) => {
          console.error('error getting workflow', params.workflow, e);
        });
    } else {
      this.updateCurrentState();
    }

  }

  public getSectionIcon(section: WorkflowSection): string {

    if (section && typeof section.icon === 'string') {
      return section.icon.trim().replace(/^\./, '').split('.').join(' ');
    }

    return '';
  }

  public updateCurrentState(): void {

    if (!this.workflowDefinition) {
      this.currentSection = null;
      this.currentPage = null;
      return;
    }

    if (!this.isType('menu')) { // only auto-select section if not in menu mode

      if (!this.section) {
        if (Array.isArray(this.workflowDefinition.sections) && this.workflowDefinition.sections.length > 0) {
          this.section = this.workflowDefinition.sections[0].id;
        }
      }
      if (!this.section) {
        this.router.navigate(['/']);
      }

    }

    this.currentSection = this.getActiveSection();

    if (this.currentSection) {

      if (!this.page) {
        if (Array.isArray(this.currentSection.pages) && this.currentSection.pages.length > 0) {
          this.page = this.currentSection.pages[0].id;
        }

      }
      if (!this.page) {
        this.router.navigate(['/']);
      }
      this.currentPage = this.getActivePage();
    } else {
      this.page = null;
      this.currentPage = null;
    }

    this.syncUrlState();

    if (this.currentSection && this.currentSection.title) {
      this.app.titleService.setTitle(`Exio - ${this.currentSection.title}`);
    } else if (this.workflowDefinition && this.workflowDefinition.title) {
      this.app.titleService.setTitle(`Exio - ${this.workflowDefinition.title}`);
    } else {
      this.app.titleService.setTitle(`Exio`);
    }

    this.updateStats(['page', 'section']);

  }

  public setStatsValue(path: string, value: any): void {

    if (!this.workflowDefinition || !this.workflowDefinition.stats) {
      return;
    }

    path = this.workflowDefinition.stats + '.' + path;
    this.setTargetValue(path, value);

  }

  public getStatsValue(path: string): any {

    if (!this.workflowDefinition || !this.workflowDefinition.stats) {
      return undefined;
    }

    path = this.workflowDefinition.stats + '.' + path;
    return this.getTargetValue(path);

  }

  public incrementStatsValue(path: string, increment?: number): void {

    let value = this.getStatsValue(path);

    if (typeof increment !== 'number' || isNaN(increment)) {
      increment = 1;
    }

    if (!value) {
      value = 0;
    }

    value += increment;

    this.setStatsValue(path, value);

  };

  public updateStats(fields: UpdateStatsFieldsValues[]): void {

    if (!this.workflowDefinition) {
      return;
    }

    if (typeof this.workflowDefinition.stats !== 'string') {
      return;
    }

    let stats = this.getTargetValue(this.workflowDefinition.stats);

    if (!stats || typeof stats !== 'object') {
      stats = {};

      this.setTargetValue(this.workflowDefinition.stats, stats);
    }

    if (!this.getStatsValue('_meta.initialized')) {
      this.setStatsValue('_meta.initialized', true);
      this.setStatsValue('views', 0);
    }

    if (!this.lastSection) {
      this.lastSection = this.getStatsValue('lastSection');
    }
    if (!this.lastPage) {
      this.lastPage = this.getStatsValue('lastPage');
    }

    if (fields.indexOf('views') > -1) {
      this.incrementStatsValue('views');
    }

    if (fields.indexOf('section') > -1) {
      this.setStatsValue('lastSection', this.getActiveSection().id);
    }

    if (fields.indexOf('page') > -1) {
      this.setStatsValue('lastPage', this.getActivePage().id);
    }

  }

  public setupStateTargets(): void {

    if (!this.workflowDefinition || !this.workflowDefinition.targets || !this.workflowDefinition.targets.forEach) {
      return;
    }

    this.workflowDefinition.targets.forEach((target) => {
      if (!this.workflowTargets.hasOwnProperty(target.on)) {
        this.workflowTargets[target.on] = [];
      }
      this.workflowTargets[target.on].push(target);
    });

  }

  public ngAfterViewChecked(): void {

    if (this.workflowDefinition && Array.isArray(this.workflowDefinition.sections)) {
      this.workflowDefinition.sections.forEach((section) => {

        if (!Array.isArray(section.pages)) {
          return;
        }

        section.pages.forEach((page) => {

          if (!Array.isArray(page.items)) {
            return;
          }

          page.items.forEach((item, i) => {

            const id = this.inputIdRender(section, page, i);

            const $itemEl = this.$el.find(`#${id}-item`);

            if ($itemEl.length < 1 || $itemEl.hasClass('item-custom-setup')) {
              return;
            }
            $itemEl.addClass('item-custom-setup');

            let $inputEl: JQuery = $itemEl.find('input');

            if ($inputEl.length < 1) {
              $inputEl = $itemEl.find('select');
            }
            if ($inputEl.length < 1) {
              $inputEl = $itemEl.find('textarea');
            }
            if ($inputEl.length < 1) {
              $inputEl = $itemEl.find('app-content-render-legal-document-sign');
            }

            let spanClass = 'col-md-12';
            if (typeof item.cells === 'number' && item.cells > 0 && item.cells <= 12) {
              spanClass = `col-md-${item.cells}`;
            }
            $itemEl.addClass(spanClass);

            // native elements
            const currentValue = this.getTargetValue(item.target);
            if (currentValue !== undefined) {
              $inputEl.val(currentValue);
              $inputEl.trigger('change');
            }

            const handleBlur = () => {

              if (this.blurDeBounce) {
                window.clearTimeout(this.blurDeBounce);
                this.blurDeBounce = null;
              }

              this.blurDeBounce = window.setTimeout(() => {
                this.blurDeBounce = null;
                this.triggerStateChange('page');
              }, 5000);

            };

            switch (item.type) {
              case 'legal-document':
                $inputEl.change(() => {
                  this.setTargetValue(item.target, $inputEl.val());
                  handleBlur();
                });
                break;
              case 'checkbox':
                $inputEl.change(() => {
                  this.setTargetValue(item.target, $inputEl.is(':checked'));
                  handleBlur();
                });
                break;
              case 'dropdown':
                $inputEl.change(() => {
                  this.setTargetValue(item.target, $inputEl.val());
                  handleBlur();
                });
                break;
              default:
                $inputEl.keyup(() => {
                  this.setTargetValue(item.target, $inputEl.val());
                });
                break;
            }

            $inputEl.blur(handleBlur);

          });
        });
      });
    }

  }

  public getTargetValue(target: string): any {
    return this.logicService.getDataValue(this.workflowTargetState, target);
  }

  public setTargetValue(target: string, value: any): void {
    this.logicService.setDataValue(this.workflowTargetState, target, value);
    this.checkChanges();
  }

  public checkChanges(): void {

    const complete = this.isCompleteWorkflow();
    const before = JSON.stringify(this.workflowTargetStatePrevious);
    const after = JSON.stringify(this.workflowTargetState);

    if (before !== after || complete !== this.isCompleteWorkflow()) {

      if (this.workflowTargetStatePrevious) {
        this.triggerStateChange('item');
      }

      this.workflowTargetStatePrevious = JSON.parse(after);
      this.updateWorkflowDefinition();

    }

  }

  public triggerStateChange(type: WorkflowTargetOnValues): void {

    if (this.currentPage && this.currentPage.target && this.currentPage.target.on === type) {
      this.triggerTarget(type, this.currentPage.target);
    } else {

      if (Array.isArray(this.workflowTargets[type])) {
        this.triggerTargets(type, this.workflowTargets[type]);
      }

      if (Array.isArray(this.workflowTargets['*'])) {
        this.triggerTargets(type, this.workflowTargets['*']);
      }

    }

  }

  public triggerTargets(type: string, targets: WorkflowTarget[]): void {
    targets.forEach((target) => {
      this.triggerTarget(type, target);
    });
  }

  public triggerTarget(eventType: string, eventTarget: WorkflowTarget): void {

    let state = {};

    if (eventTarget.params) {
      state = eventTarget.params;
    } else {
      const defaultState = eventTarget.default || {};

      // set defaults if any
      for (const field in defaultState) {
        if (defaultState.hasOwnProperty(field) && !state.hasOwnProperty(field)) {
          state[field] = defaultState[field];
        }
      }

      // clone state so defaults don't pollute the native state
      for (const field in this.workflowTargetState) {
        if (this.workflowTargetState.hasOwnProperty(field)) {
          state[field] = this.workflowTargetState[field];
        }
      }

    }

    if (eventTarget.type === 'console') {

      // tslint:disable-next-line:no-console
      console.info('workflow: target: triggered:', eventType, eventTarget, JSON.stringify(state, null, 4));

      return;
    }

    const promise = this.triggerCall(eventTarget, state);

    if (promise) {
      promise
        .catch((e) => {
          console.error('error for workflow ' + eventTarget.type + ' target', eventTarget, 'for state', state, 'for event', eventType, e);
        });
    }

  }

  public triggerCall(target: WorkflowTarget, state: Object): Bluebird<any> {

    switch (target.type) {

      case 'service':

        const serviceMethod = target.method.split('.');
        const service = serviceMethod[0];
        const serviceCall = serviceMethod[1];

        if (this.hasOwnProperty(service) && typeof this[service][serviceCall] === 'function') {
          return this[service][serviceCall](state);
        }

        break;
      case 'model':

        const modelMethod = target.method.split('.');
        const model = modelMethod[0] + 'Model';
        const modelCall = modelMethod[1];

        if (this.app.hasOwnProperty(model) && typeof this.app[model][modelCall] === 'function') {
          return this.app[model][modelCall](state);
        }

        break;
    }

    return null;

  }

  public getActiveSection(): WorkflowSection {

    let result: WorkflowSection = null;
    let backupResult: WorkflowSection = null;

    if (!this.workflowDefinition) {
      return result;
    }

    this.workflowDefinition.sections.forEach((section) => {

      // first section is backup
      if (!backupResult) {
        backupResult = section;
      }

      if (result) {
        return;
      }

      if (section.id === this.section) {
        result = section;
      }

    });

    if (!this.isType('menu')) {
      result = result || backupResult;
    }

    // in case we used backup result
    // this.section = result.id;

    return result;

  }

  public getActivePage(): WorkflowPage {

    const section = this.getActiveSection();

    let result: WorkflowPage = null;
    let backupResult: WorkflowPage = null;

    if (!Array.isArray(section.pages)) {
      return result;
    }

    section.pages.forEach((page) => {

      if (!backupResult) {
        backupResult = page;
      }

      // stop looping
      if (result) {
        return;
      }

      if (page.id === this.page) {
        result = page;
      }

    });

    if (!this.isType('menu')) {
      result = result || backupResult;
    }

    return result;

  }

  public isActiveSection(section: WorkflowSection): boolean {

    const currentSection = this.getActiveSection();

    return currentSection && currentSection.id === section.id;

  }

  public isCompleteWorkflow(): boolean {


    if (!this.workflowDefinition) {
      return false;
    }

    let isComplete = true;

    if (!Array.isArray(this.workflowDefinition.sections)) {
      return true;
    }

    this.workflowDefinition.sections.forEach((section) => {

      if (!isComplete) {
        return;
      }

      isComplete = this.isCompleteSection(section);

    });

    return isComplete;

  }

  public showComplete(section: WorkflowSection): boolean {

    if (!section) {
      return false;
    }

    return !section.hideComplete;

  }

  public isCompleteSection(section: WorkflowSection): boolean {

    if (!section) {
      return false
    }

    if (!Array.isArray(section.pages)) {
      return true;
    }

    let isComplete = true;

    section.pages.forEach((page) => {

      if (!isComplete) {
        return;
      }

      isComplete = this.isCompletePage(page);

    });

    return isComplete;

  }


  public isCompletePage(page: WorkflowPage): boolean {

    if (!page) {
      return false
    }

    if (!Array.isArray(page.items)) {
      return true;
    }

    let isComplete = true;

    page.items.forEach((item) => {

      if (!isComplete) {
        return;
      }

      isComplete = this.isCompleteItem(item);

    });

    return isComplete;

  }

  public isRequiredItem(item: WorkflowItem): boolean {
    return !item.hasOwnProperty('required') || item.required;
  }

  public isCompleteItem(item: WorkflowItem): boolean {

    // if not required, item is always complete
    if (!this.isRequiredItem(item)) {
      return true;
    }

    if (!this.isAnsweredItem(item)) {
      return false;
    }

    // if no completionCheck, item is always complete
    if (!item.hasOwnProperty('completeCheck')) {
      return true;
    }

    let logic = item.completeCheck;

    if (typeof logic === 'string') {

      let regex = logic;

      if (!(<string>regex).match(/^\//)) {
        regex = '/' + regex + '/';
      }

      logic = {
        '$regex': [
          regex,
          item.target
        ]
      };

    }

    return this.logicService.eval(this.workflowTargetState, logic);

  }

  public isAnsweredItem(item: WorkflowItem): boolean {

    // if not an input type, is answered
    if (!item.hasOwnProperty('type') || !item.hasOwnProperty('target') || item.allowBlank === true) {
      return true;
    }

    const currentVal = this.getTargetValue(item.target);

    if (currentVal === null || currentVal === undefined) {
      return false;
    }

    if (typeof currentVal === 'string') {
      return currentVal.trim().length > 0;
    }

    if (typeof currentVal === 'number') {
      return currentVal !== 0;
    }

    if (typeof currentVal === 'boolean') {
      return true;
    }

    if (isNaN(currentVal)) {
      return false;
    }

    return !!currentVal;

  }

  public incompleteText(item: WorkflowItem): string {
    return typeof item.completeHint === 'string' && item.completeHint.trim().length > 0 ? item.completeHint.trim() : 'Required Field';
  }

  public goNext(): void {

    if (!this.workflowDefinition) {
      return;
    }

    this.updateWorkflowDefinition();


    this.triggerStateChange('page');

    // for menus, go next is actually a save and return
    if (this.isType('menu')) {
      this.section = null;
      this.page = null;
      this.updateCurrentState();
      return;
    }

    if (this.isLastSection() && this.isLastPage()) {
      this.completeWorkflow();
      return;
    }

    let nextSection: WorkflowSection = null;
    const maxSectionIndex = this.workflowDefinition.sections.length - 1;

    this.workflowDefinition.sections.forEach((section, i) => {

      if (section.id === this.currentSection.id && i < maxSectionIndex) {
        nextSection = this.workflowDefinition.sections[i + 1];
      }

    });

    if (this.isLastPage()) {
      this.section = nextSection.id;
      this.page = null;
      this.updateCurrentState();
    } else {

      let nextPage: WorkflowPage = null;

      this.currentSection.pages.forEach((page, j) => {

        const maxPageIndex = this.currentSection.pages.length - 1;

        if (page.id === this.currentPage.id && j < maxPageIndex) {
          nextPage = this.currentSection.pages[j + 1];
        }

      });

      this.page = nextPage.id;
      this.updateCurrentState();
    }

  }

  public syncUrlState(): void {

    let urlState = '/action/' + this.workflow;

    if (this.section) {
      urlState += '/' + this.section;
    }

    if (this.section) {
      urlState += '/' + this.section;
    }

    if (this.section && this.urlParams) {

      let params = this.urlParams;

      if (typeof params !== 'string') {
        params = JSON.stringify(params);
      }

      urlState += '/' + params;

    }

    // this.location.go( urlState );

    // this.router.navigate( [ urlState ] );

  }

  public completeWorkflow(): void {

    if (!this.workflowDefinition) {
      return;
    }

    this.triggerStateChange('complete');

    // timeout ensures navigation starts after other events have been fired
    window.setTimeout(() => {

      if (!this.workflowDefinition) {
        return;
      }

      if (this.workflowDefinition.completionRedirect) {
        this.app.contentLoading(true);
        this.router.navigate([
          this.workflowDefinition.completionRedirect, {
            complete: this.isCompleteWorkflow()
          }
        ]);
      }

    }, 250);

  }

  public clearChanges(): void {
    this.workflowTargetState = JSON.parse(JSON.stringify(this.workflowTargetStatePrevious));
  }

  public goPrevious(): void {

    this.triggerStateChange('page');

    if (this.isType('menu')) {
      this.section = null;
      this.page = null;
      this.updateCurrentState();
      return;
    }

    if (!this.workflowDefinition) {
      return;
    }

    if (this.isFirstSection() && this.isFirstPage()) {
      return;
    }

    let previousSection: WorkflowSection = null;
    let previousPage: WorkflowPage = null;
    const minSectionIndex = 0;

    this.workflowDefinition.sections.forEach((section, i) => {

      if (section.id === this.currentSection.id && i > minSectionIndex) {
        previousSection = this.workflowDefinition.sections[i - 1];
      }

      if (!this.isFirstPage() && Array.isArray(section.pages)) {

        section.pages.forEach((page, j) => {

          const minPageIndex = 0;

          if (page.id === this.currentPage.id && j > minPageIndex) {
            previousPage = section.pages[j - 1];
          }

        });
      }

    });

    if (this.isFirstPage()) {
      this.section = previousSection.id;
      this.page = previousSection.pages[previousSection.pages.length - 1].id;
      this.updateCurrentState();
    } else {
      this.page = previousPage.id;
      this.updateCurrentState();
    }

  }

  public goSection(section: WorkflowSection): void {

    this.triggerStateChange('page');

    if (section.link) {

      const link = section.link;

      if (typeof link === 'string') {
        this.router.navigate([link]);
      } else {
        this.router.navigate(link);
      }

      return;

    }

    this.section = section.id;
    this.page = null;
    this.updateCurrentState();

  }

  public goSectionPage(section: WorkflowSection, page: WorkflowPage): void {
    this.section = section.id;
    this.page = page.id;
    this.updateCurrentState();
  }

  public goPageHandler(): (WorkflowPage) => void {

    return (page: WorkflowPage) => {
      this.goSectionPage(this.currentSection, page);
    };

  }

  public getTarget(item: WorkflowItem): string {
    return item.target;
  }

  public inputId(i: number): string {
    return this.inputIdRender(this.currentSection, this.currentPage, i);
  }

  public inputIdRender(section: WorkflowSection, page: WorkflowPage, i: number): string {

    const sectionId = typeof section.id === 'string' ? section.id : '';
    const pageId = typeof page.id === 'string' ? page.id : '';

    return `item-${sectionId.replace(/-/g, '_')}-${pageId.replace(/-/g, '_')}-${i}`;

  }

  public textAlign(item: WorkflowItem): SafeStyle {

    const value = typeof item.labelAlign === 'string' ? item.labelAlign : 'left';

    return this.sanitizer.bypassSecurityTrustStyle(value);

  }

  public isFirstSection(): boolean {

    if (!this.workflowDefinition) {
      return false;
    }

    return this.currentSection && this.currentSection.id === this.workflowDefinition.sections[0].id;

  }

  public isFirstPage(): boolean {
    if (!this.workflowDefinition) {
      return false;
    }
    return this.currentSection && this.currentPage && this.currentPage.id === this.currentSection.pages[0].id;
  }

  public isLastSection(): boolean {

    if (!this.workflowDefinition) {
      return false;
    }

    return this.currentSection &&
      this.currentSection.id === this.workflowDefinition.sections[this.workflowDefinition.sections.length - 1].id;

  }

  public isLastPage(): boolean {

    if (!this.workflowDefinition) {
      return false;
    }

    return this.currentSection && this.currentPage &&
      this.currentPage.id === this.currentSection.pages[this.currentSection.pages.length - 1].id;

  }

  public continueButtonLabel(): string {

    if (!this.workflowDefinition) {
      return null;
    }


    const currentSection = this.currentSection;
    const currentPage = this.currentPage;

    const lastSection = this.isLastSection();
    const lastPage = this.isLastPage();

    let result = this.isType('menu') ? 'Save' : 'Continue';

    if (lastSection && lastPage) {

      if (typeof currentPage.completionText === 'string' && currentPage.completionText.length > 0) {
        result = currentPage.completionText;
      } else if (typeof this.workflowDefinition.completionText === 'string' && this.workflowDefinition.completionText.length > 0) {
        result = this.workflowDefinition.completionText;
      } else if (typeof currentSection.completionText === 'string' && currentSection.completionText.length > 0) {
        result = currentSection.completionText;
      }

    } else if (typeof currentPage.continueText === 'string' && currentPage.continueText.length > 0) {
      result = currentPage.continueText;
    } else if (!this.currentPageComplete()) {
      result = 'Finish Later';
    }


    return result;

  }

  public pageWidth(page: WorkflowPage): string {
    return (typeof page.width === 'number' ? page.width : 60) + '%';
  }

  public markdownToHtml(markdownStr: string): string {

    if (!markdownStr) {
      return '';
    }

    return this.app.markdownToHtml(markdownStr).replace(/\n/g, "<br/>");
  }

  public currentPageComplete(): boolean {
    return this.isCompletePage(this.currentPage);
  }

  public socialLogin(network: string, loginTarget?: string): void {

    // The user is about to login, clear the FullStory session
    const FS = (<any>window).FS;

    if (FS) {
      FS.clearUserCookie();
    }

    const lastRouteHistory = this.app.navigationHistory.filter((url) => {
      return !url.match(/^\/action\/login/) && !url.match(/^\/action\/signup/);
    });

    const lastRoute: string = lastRouteHistory.length > 0 ? lastRouteHistory[lastRouteHistory.length - 1] : null;

    loginTarget = this.workflowDefinition.completionRedirect || loginTarget || Cookie.get('login-target') || lastRoute;
    Cookie.remove('login-target');

    if (typeof loginTarget === 'string' && loginTarget.trim().length > 0) {
      loginTarget = loginTarget.trim();
    } else {
      loginTarget = null;
    }

    network = network.toLowerCase();

    if (network === 'email') {

      const url = 'login/email';

      const params: {
        redirect?: string;
      } = {};
      if (loginTarget) {
        params.redirect = loginTarget;
      }

      this.router.navigate([url, params]);
      return;

    }

    let queryStr = undefined;
    if (loginTarget) {
      queryStr = `login-target=${encodeURIComponent(loginTarget)}`;
    }

    const goUrl = this.app.api.getFullUrl('/auth/social/' + network, queryStr);

    window.open(goUrl, '_self');

  }

  public getMenuSectionsGroups(groups: number): WorkflowSection[][] {

    const results = [];
    const sections = this.getMenuSections();

    sections.forEach((section) => {

      if (results.length < 1) {
        results.push([]);
      }

      if (results[results.length - 1].length > groups) {
        results.push([]);
      }

      results[results.length - 1].push(section);

    });


    return results;
  }

  public getMenuSections(): WorkflowSection[] {

    if (!this.workflowDefinition || !Array.isArray(this.workflowDefinition.sections)) {
      return [];
    }

    return this.workflowDefinition.sections.filter((section: WorkflowSection) => {
      return section.menuShow;
    });

  }

  public goResume(): void {

    this.section = this.lastSection;
    this.page = this.lastPage;

    this.updateCurrentState();
    this.goNext();

  }

  public leftMenuShowing(): boolean {

    return !!this.workflowDefinition &&
      !!this.workflowDefinition.sections &&
      this.getMenuSections().length > 1 &&
      this.workflowDefinition.type === 'linear' && this.getActiveSection().menuShow;

  }

}
