
<div class="toolbar-container">
  <!--.profile-picture(*ngIf="profile", [style.background-image]="profilePicture()", (click)="goProfile()")-->
  <!--.profile-tag(*ngIf="profile", (click)="goProfile()")-->
  <!--	span {{ profile.firstName }}-->
  <!--	| &nbsp;from Exio-->
  <div class="chat-close" (click)="closed()">
    <div class="component-icon close-handle-gray"></div>
  </div>
</div>
<div class="chat-container" *ngIf="hasCurrentPage()">
  <div class="chat-title" *ngIf="currentTitle()" [class.large-title]="currentTitle().length &gt; 16">{{ currentTitle() }}</div>
  <div class="chat-body" [class.no-continue]="isLastPage()" [class.no-title]="currentTitle() === null">
    <div class="chat-body-container">
      <div class="chat-body-item" *ngFor="let item of pageItems()" [class.login-item-container]="item.type === 'emailLogin'">
        <div class="login-item" *ngIf="item.type === 'emailLogin'">
          <div class="login-error-message" *ngIf="loginErrorMessage">{{ loginErrorMessage }}</div>
          <input class="input-text" [(ngModel)]="workflowOptions.email" type="text" placeholder="Enter a valid email" required (focus)="inputGotFocus($event.srcElement)" (blur)="inputLostFocus($event.srcElement)">
          <div class="login-link" (click)="emailLogin()">Send Email Link</div>
        </div>
        <div class="paragraph-item br" *ngIf="item.type === 'br'"><br></div>
        <div class="paragraph-item p" *ngIf="item.type === 'paragraph'" [innerHTML]="markdown(item.params.content)"></div>
        <div class="login-item" *ngIf="item.type === 'login' &amp;&amp; authenticatedProfile !== null">{{ continueDisabled(false) }}
          <div class="login-wait">
            <div class="complete-notice">You are already logged in.</div>
            <div class="action-notice" (click)="continueDisabled(true); authenticatedProfile = null">Switch Account</div>
          </div>
        </div>
        <div class="login-item" *ngIf="item.type === 'login' &amp;&amp; authenticatedProfile === null">{{ continueDisabled(true) }}
          <div class="login-wait" *ngIf="isLoggingIn()">
            <div class="complete-notice">Complete Login Process in the Pop Up window.</div>
            <div class="action-notice" (click)="clearSocialLogin()">Cancel</div>
            <div class="action-notice" (click)="lostLoginWindow()">Lost The Window?</div>
          </div>
          <div class="login-group" *ngIf="!isLoggingIn()">
            <div class="social-login-button-container" *ngFor="let network of socialNetworks">
              <div class="social-login-button" (click)="socialLogin(network)" [class]="'social-login-button social-icon social-icon-' + network.toLowerCase()"></div>
            </div>
          </div>
        </div>
        <div class="checklist-item" *ngIf="item.type === 'checklist'">
          <div class="checklist-row" *ngFor="let row of item.params">
            <div class="check-list-name">{{ row.name }}</div>
            <div class="check-list-check" [class.check-list-checked]="row.checked">
              <div class="component-icon check active"></div>
            </div>
          </div>
        </div>
        <div class="contract-item" *ngIf="item.type === 'contract'">
          <div class="contract-container loading" *ngIf="!contractReady(item.params.path)">Loading contract...</div>
          <div class="contract-container display" *ngIf="contractReady(item.params.path)">
            <div class="contract-title" *ngIf="legalDocumentState === 'sign' || legalDocumentState === 'next'">Signature Page</div>
            <div class="contract-information" *ngIf="legalDocumentState === 'sign'|| legalDocumentState === 'next'"><span class="red">*</span>Denotes a required field.</div>
            <div class="contract-signature-container" *ngIf="legalDocumentState === 'sign' || legalDocumentState === 'next'">
              <input class="input-text" [(ngModel)]="authenticatedProfile.company" type="text" [placeholder]="item.params?.placeholder?.company || 'Your Company Name *'" required (focus)="inputGotFocus($event.srcElement)" (blur)="inputLostFocus($event.srcElement)">
              <input class="input-text" [(ngModel)]="profileFullName" type="text" placeholder="Full Name *" required (focus)="inputGotFocus($event.srcElement)" (blur)="inputLostFocus($event.srcElement)">
              <div class="signature-display" *ngIf="hasSignature()">{{ profileFullName }}</div>
              <div class="signature-display" *ngIf="!hasSignature()">Your Signature</div>
              <input class="input-text" [(ngModel)]="authenticatedProfile.title" type="text" placeholder="Your Title *" required (focus)="inputGotFocus($event.srcElement)" (blur)="inputLostFocus($event.srcElement)">
              <input class="input-text" [(ngModel)]="authenticatedProfile.email" type="text" [placeholder]="item.params?.placeholder?.email ||'Email *'" required (focus)="inputGotFocus($event.srcElement)" (blur)="inputLostFocus($event.srcElement)">
              <input class="input-text" *ngIf="!item.params?.hideInputs?.address" [(ngModel)]="authenticatedProfile.address.street1" type="text" placeholder="Address Line 1 *" required (focus)="inputGotFocus($event.srcElement)" (blur)="inputLostFocus($event.srcElement)">
              <input class="input-text" *ngIf="!item.params?.hideInputs?.address" [(ngModel)]="authenticatedProfile.address.street2" type="text" placeholder="Address Line 2" (focus)="inputGotFocus($event.srcElement)" (blur)="inputLostFocus($event.srcElement)">
              <input class="input-text" *ngIf="!item.params?.hideInputs?.address" [(ngModel)]="authenticatedProfile.address.city" type="text" placeholder="City *" required (focus)="inputGotFocus($event.srcElement)" (blur)="inputLostFocus($event.srcElement)">
              <input class="input-text" *ngIf="!item.params?.hideInputs?.address" [(ngModel)]="authenticatedProfile.address.state" type="text" placeholder="State *" required (focus)="inputGotFocus($event.srcElement)" (blur)="inputLostFocus($event.srcElement)">
              <input class="input-text" *ngIf="!item.params?.hideInputs?.address" [(ngModel)]="authenticatedProfile.address.zip" type="text" placeholder="Zip *" required (focus)="inputGotFocus($event.srcElement)" (blur)="inputLostFocus($event.srcElement)">
              <input class="input-text" [(ngModel)]="authenticatedProfile.mobilePhone" type="text" [placeholder]="item.params?.placeholder?.phone ||'Phone *'" required (focus)="inputGotFocus($event.srcElement)" (blur)="inputLostFocus($event.srcElement)">
              <div class="contract-information" *ngIf="item.params?.postInput">{{item.params?.postInput}}</div>
              <div class="check-group" *ngIf="!item.params?.checked">
                <label>Documents, reports and other materials on this website are provided by sellers/buyers and/or their advisors. Exio does not review or verify the accuracy of any information provided. Your use of, and access to the content on this website is subject to the terms and conditions described in Exio’s Terms & Conditions which you may view by clicking this <a href="https://goexio.com/terms-and-conditions" target="_blank">link</a> or find at the bottom of each web page.</label>
              </div>
              <div class="check-group" *ngIf="!item.params?.checked">
                <div class="required-label required">*</div>
                <input class="input-check" [(ngModel)]="legalDocumentTermsOfServiceAgree" id="legal-document-terms-of-service-agree" type="checkbox" required (change)="isContractFormComplete()" (focus)="inputGotFocus($event.srcElement)" (blur)="inputLostFocus($event.srcElement)">
                <label class="electronic-agreement" *ngIf="!item.params?.buyerFee" for="legal-document-terms-of-service-agree">By checking this box you acknowledge the foregoing and agree to our Terms & Conditions.</label>
                <label class="electronic-agreement" *ngIf="item.params?.buyerFee" for="legal-document-terms-of-service-agree">By checking this box you acknowledge the foregoing and agree to our Terms & Conditions,<strong> including the NDA & Access Fee Agreement</strong>.</label>
              </div>
              <div class="check-group" *ngIf="!item.params?.checked">
                <div class="required-label required">*</div>
                <input class="input-check" [(ngModel)]="legalDocumentElectronicSignatureAgree" id="legal-document-electronic-signature-agree" type="checkbox" required (change)="isContractFormComplete()" (focus)="inputGotFocus($event.srcElement)" (blur)="inputLostFocus($event.srcElement)">
                <label class="electronic-agreement" for="legal-document-electronic-signature-agree">I agree that by clicking "I Accept", I am signing this agreement electronically. I agree that my electronic signature is the legal equivalent of my manual signature of this agreement.</label>
              </div>
            </div>
            <div class="contract-information" *ngIf="!item.params?.checked &amp;&amp; (legalDocumentState === 'sign'|| legalDocumentState === 'next')">Once you accept the agreement and confirm your email you will receive a PDF copy of the executed document with your signature.</div>
            <div class="contract-contents-container" *ngIf="legalDocumentState === 'complete'">
              <div class="legal-document-statement">You have already signed this NDA. Your approval is pending. Please use the contact form in the top right menu if you do not receive approval within 5 business days. You can close this window by clicking the X.</div>
            </div>
            <div class="contract-contents-container" *ngIf="legalDocumentState === 'display'">
              <div class="legal-document-statement" *ngIf="!legalDocument.parties">This {{ legalDocument.title }} ("Agreement") is made as of {{ legalDocumentInstance.created | date: 'longDate' }} (the "Effective Date") between Exit Strategy Ltd. ("Exio") and signer(s) ("Other Party")</div>
              <div class="legal-document-statement" *ngIf="legalDocument.parties" [innerHTML]="markdown(legalDocument.parties)"></div>
              <div class="legal-document-description" [innerHTML]="markdown(legalDocument.description)"></div>
              <div class="legal-document-sections-container" *ngIf="legalDocument.sections">
                <div class="legal-document-sections">
                  <div class="legal-document-section-container" *ngFor="let section of legalDocument.sections; let i = index;">
                    <div class="legal-document-section">
                      <div class="legal-document-section-contents" [innerHTML]="markdown(sectionContent(i, section))"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="chat-continue" *ngIf="!processing &amp;&amp; currentContinue()" (click)="continue()" [class.continue-enabled]="!currentContinueDisabled()" [class.continue-disabled]="currentContinueDisabled()">{{ currentContinue() }}</div>
  <div class="chat-continue-wait" *ngIf="processing">
    <div class="loader-bar"></div>
  </div>
</div>