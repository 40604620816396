
<form class="search-controls" #searchForm="ngForm" (ngSubmit)="search()" [class.show-search]="showSearch">
  <div class="control-categories" ngModelGroup="categories">
    <div class="control-categories-title">Categories</div>
    <div class="control-categories-input">
      <div class="not-found" *ngIf="categories.length &lt; 1">No Categories Found</div>
      <label *ngFor="let category of categories">{{ categoryDisplayName(category) }}
        <input type="checkbox" name="{{category}}" [ngModel]="false">
      </label>
    </div>
  </div>
  <div class="control-authors" ngModelGroup="authors">
    <div class="control-authors-title">Authors</div>
    <div class="control-authors-input">
      <div class="not-found" *ngIf="authorIds.length &lt; 1">No Authors Found</div>
      <label *ngFor="let authorId of sortedAuthorIds()">{{ authorById(authorId)}}
        <input type="checkbox" name="{{authorId}}" [ngModel]="false">
      </label>
    </div>
  </div>
  <button class="control-search" type="submit" [class.active]="searchEnabled()" [disabled]="!searchEnabled()">Search</button>
  <button class="control-search" type="reset" (click)="clear()" [class.active]="resetEnabled()" [disabled]="!resetEnabled()">Clear</button>
</form>
<div class="article-keyword-filter">
  <input type="text" (change)="updateFilteredResults()" [(ngModel)]="keywordFilter" placeholder="Keyword Filter">
</div>
<div class="search-results">
  <div class="search-results-headers" *ngIf="!articlesLoading &amp;&amp; resultsFiltered &amp;&amp; resultsFiltered.articles.length &gt; 0">
    <div class="search-results-counts" *ngIf="resultsFiltered.count === resultsFiltered.total">Displaying {{ resultsFiltered.count }} articles</div>
    <div class="search-results-counts" *ngIf="resultsFiltered.count !== resultsFiltered.total">Displaying {{ resultsFiltered.count }} of {{ resultsFiltered.total }} articles</div>
  </div>
  <div class="search-results-list-oading" *ngIf="articlesLoading">
    <div class="loader-spinner"></div>
  </div>
  <div class="search-results-list" *ngIf="!articlesLoading &amp;&amp; resultsFiltered &amp;&amp; resultsFiltered.articles.length &gt; 0">
    <div class="search-result">
      <div class="header-field article-date">
        <div class="top-field">Created</div>
        <div class="bottom-field">Updated</div>
      </div>
      <div class="header-field article-title">
        <div class="top-field">Title</div>
        <div class="bottom-field">URL</div>
      </div>
      <div class="header-field article-categories">Categories</div>
      <div class="header-field article-author">Author</div>
      <div class="header-field article-actions">
        <div class="top-field">Actions</div>
      </div>
    </div>
    <div class="search-result" *ngFor="let article of resultsFiltered.articles">
      <div class="article-date">
        <div class="created-date">{{ article.created | date:'mediumDate' }}</div>
        <div class="updated-date" *ngIf="article.updated &amp;&amp; dateOnly(article.created) !== dateOnly(article.updated)">{{ article.updated | date:'mediumDate' }}</div>
        <div class="updated-date" *ngIf="!article.updated || dateOnly(article.created) === dateOnly(article.updated)">&nbsp;</div>
      </div>
      <div class="article-title">
        <div class="article-title-content" (click)="manageListing(article)">{{ article.title }}</div>
        <div class="article-title-link"><a [href]="sanitizer.bypassSecurityTrustResourceUrl(article.path)" target="_blank">{{ article.path }}</a></div>
      </div>
      <div class="article-categories"><span class="article-category" *ngFor="let category of article.categories">{{ categoryDisplayName(category) }}</span></div>
      <div class="article-author">{{ authorById(article.author) }}</div>
      <div class="article-actions" *ngIf="deleteArticleCheck !== article.id">
        <!--.article-action((click)="editArticle(article)") Edit-->
        <div class="article-action" (click)="manageListing(article)">Manage</div>
        <div class="article-action" (click)="accessVDR(article)" *ngIf="hasVDR(article)">VDR</div>
      </div>
    </div>
  </div>
  <div class="search-results-not-found" *ngIf="!articlesLoading &amp;&amp; (!resultsFiltered || resultsFiltered.articles.length &lt; 1)">No Articles Found</div>
</div>