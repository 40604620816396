
<div class="legal-document-sign" *ngIf="errorMessage">
  <div class="legal-document-instructions" [innerHTML]="markdownToHtml(errorMessage)"></div>
</div>
<div class="legal-document-sign" *ngIf="loading">
  <div class="loading-document">
    <div class="loader-spinner"></div>
  </div>
</div>
<div class="legal-document-sign" *ngIf="!loading &amp;&amp; authorized &amp;&amp; profile &amp;&amp; !document">
  <div class="error-message">
    <p>The contract document you requested does not exist.</p>
    <p>Please proceed to the<a [routerLink]="['/contact']"> contact us</a> page if you think this message is in error.</p>
  </div>
</div>
<div class="legal-document-sign" *ngIf="!loading &amp;&amp; authorized &amp;&amp; profile &amp;&amp; document &amp;&amp; instance &amp;&amp; signed()">
  <div class="legal-document-instructions" *ngIf="document.signatureSuccess" [innerHTML]="markdownToHtml(document.signatureSuccess)"></div>
  <div class="legal-document-instructions" *ngIf="!document.signatureSuccess">
    <p>Thank you for completing this agreement.</p>
    <p>A representative from Exio will contact you shortly.</p>
  </div>
</div>
<div class="legal-document-sign" *ngIf="!loading &amp;&amp; authorized &amp;&amp; profile &amp;&amp; document &amp;&amp; instance &amp;&amp; !signed()">
  <div class="legal-document-instructions" *ngIf="document.instructions" [innerHTML]="markdownToHtml(document.instructions)"></div>
  <div class="legal-document-instructions" *ngIf="!document.instructions">Please review the following document and if you agree to the arrangement click 'Accept' to digitally sign.</div>
  <div class="content-render-legal-document-container" [class.content-collapsed]="!documentExpanded">
    <div class="content-render-legal-document" [document]="document" [instance]="instance"></div>
  </div>
  <div class="content-render-legal-document-container-control">View<a *ngIf="documentExpanded" (click)="collapse()">Collapsed</a><a *ngIf="!documentExpanded" (click)="expand()">Expanded</a></div>
  <div class="legal-document-requested-signatures" *ngIf="showWaitingSignatures &amp;&amp; requestedSigners.length &gt; 0">
    <div class="col-xs-4"></div>
    <div class="legal-document-requested-signatures-title col-xs-4">Waiting for the Following Signatures</div>
    <div class="col-xs-4"></div>
    <div class="legal-document-requested-signature col-xs-12" *ngFor="let signer of requestedSigners">
      <div class="legal-document-requested-signature-contents col-xs-12" *ngIf="signerProfile(signer)">
        <div class="legal-document-requested-signature-name col-xs-6">{{ signerProfile(signer).firstName }} {{ signerProfile(signer).lastName }}</div>
        <div class="legal-document-requested-signature-requested-date col-xs-6">Sent: {{ signer.requested | date: 'longDate' }}</div>
      </div>
      <div class="legal-document-requested-signature-contents col-xs-12" *ngIf="!signerProfile(signer)">Loading...</div>
    </div>
  </div>
  <div class="legal-document-instructions">Confirm your contact information.</div>
  <app-forms-signup [(profile)]="profile" [onChange]="onFormChangeHandler" [showSignature]="true"></app-forms-signup>
  <div class="legal-document-actions" *ngIf="signing">
    <div class="loader-bar-container">
      <div class="loader-bar"></div>
    </div>
  </div>
  <div class="legal-document-actions" *ngIf="!signing &amp;&amp; mode === 'normal' &amp;&amp; formComplete()">
    <div class="legal-document-action align-left">
      <button class="enabled control-button" (click)="accept()">Accept</button>
    </div>
    <div class="legal-document-action align-right">
      <button class="enabled control-button" (click)="decline()">Decline</button>
    </div>
  </div>
  <div class="legal-document-instructions">* required fields which must be completed to accept</div>
  <div class="legal-document-instructions">Once you accept the agreement you will be emailed with a PDF copy of the executed document with your signature.</div>
</div>
<div class="legal-document-sign" *ngIf="!loading &amp;&amp; !profile">
  <div class="error-message">
    <p>You do not appear to be logged in. Please log in order to view and sign this agreement.</p>
    <p>If you are logged in and believe you should have access, please proceed to the<a [routerLink]="['/contact']"> contact us</a> page for support.</p>
  </div>
</div>
<div class="legal-document-sign" *ngIf="!loading &amp;&amp; profile &amp;&amp; !authorized">
  <div class="error-message">
    <p>You do not appear to be authorized to sign this contract. Please ensure you are signed in to the correct account and try again.</p>
    <p>If you are logged in and believe you should have access, please proceed to the<a [routerLink]="['/contact']"> contact us</a> page to request access.</p>
  </div>
</div>