
<mat-form-field class="street" *ngIf="answer.answer.addr_line1">
  <input matInput placeholder="Street" [readonly]="true" [value]="answer.answer.addr_line1">
</mat-form-field>
<mat-form-field class="street" *ngIf="answer.answer.addr_line2">
  <input matInput placeholder="Street 2" [readonly]="true" [value]="answer.answer.addr_line2">
</mat-form-field>
<mat-form-field class="city" *ngIf="answer.answer.city">
  <input matInput placeholder="City" [readonly]="true" [value]="answer.answer.city">
</mat-form-field>
<mat-form-field class="state" *ngIf="answer.answer.state">
  <input matInput placeholder="State" [readonly]="true" [value]="getStateFormat()">
</mat-form-field>
<mat-form-field class="zip" *ngIf="answer.answer.postal">
  <input matInput placeholder="Zip" [readonly]="true" [value]="answer.answer.postal">
</mat-form-field>
<mat-form-field class="country" *ngIf="answer.answer.country">
  <input matInput placeholder="Country" [readonly]="true" [value]="answer.answer.country">
</mat-form-field>