<app-chat-window></app-chat-window>
<div class="vimeo-player" [mode]="'service'"></div>
<app-toolbar></app-toolbar>
<div class="loading-content" *ngIf="appService.content.loading">
  <div class="loader-message">Working...</div>
  <div class="loader-spinner"></div>
</div>
<!--.routeroutlet-->
<!--  p {{ appService.content.loading }}-->
<router-outlet [class.loading-content]="appService.content.loading"></router-outlet>
<app-footer *ngIf="!this.appService.footer.hide &amp;&amp; !appService.content.loading"></app-footer>
<div class="content-stable" *ngIf="!appService.content.loading" style="display: none;">The presence of this tag illustrates that content is stable. It is a useful hook for pre-rendering content, such as with phantomjs.</div>
