
<div class="vimeo-container">
  <div class="vimeo-message vimeo-hide"></div>
  <div class="vimeo-wait vimeo-hide">
    <div class="loader-spinner"></div>
  </div>
  <div class="vimeo-content">
    <div class="vimeo-thumbnail">
      <div class="vimeo-thumbnail-play" *ngIf="!locked"></div>
      <div class="vimeo-thumbnail-play-locked" *ngIf="locked"></div>
      <div class="vimeo-title" *ngIf="hasTitle()">{{ metaData.name.trim() }}</div>
      <div class="vimeo-description" *ngIf="hasDescription()" [innerHTML]="getDescription()"></div>
    </div>
  </div>
</div>