import {Component, OnInit, OnDestroy, ElementRef} from '@angular/core';
import {Router} from '@angular/router';

import {AppService} from 'app/services/app';
import {Profile} from 'app/models/profile';

import * as Cookie from 'js-cookie';

type MenuLink = {
  title: string;
  url: string;
};
type MenuSection = {
  title: string;
  links: Array<MenuLink>;
};
type MenuSections = Array<MenuSection>;

@Component({
  moduleId: module.id,
  selector: 'app-toolbar',
  templateUrl: 'toolbar.component.html',
  styleUrls: ['toolbar.component.less']
})
export class MainToolbarComponent implements OnInit, OnDestroy {

  public profile: Profile = null;
  public subscriptions: any[] = [];

  public $window: JQuery = null;
  public $body: JQuery = null;
  public $container: JQuery = null;
  public $menu: JQuery = null;

  public sections: MenuSections = [];

  public resizeHandler: any = null;

  public expandedSection: MenuSection = null;

  constructor(public appService: AppService, public element: ElementRef, public router: Router) {
    this.$window = $(window);
    this.$body = $('html body');
  }

  public handleState(state: string): void {

    switch (state) {
      case 'expand':
        this.show();
        break;
      case 'collapse':
        this.hide();
        break;
    }

  }

  public ngOnInit(): void {

    this.$container = $(this.element.nativeElement).find('.toolbar-container');
    this.$menu = this.$container.find('.app-toolbar-menu');


    this.generateSections();

    this.subscriptions.push(this.appService.onToolbarMenuState((state) => {

      this.handleState(state);

    }));

    this.subscriptions.push(this.appService.getAuthenticatedProfile({
      next: (profile) => {

        this.profile = profile || null;
        this.generateSections();

      },
      error: () => {
        this.profile = null;
        this.generateSections();
      },
      complete: () => {
        this.profile = null;
        this.generateSections();
      }
    }));

    this.resizeHandler = () => {
      this.setMenuSize();
    };
    this.$window.resize(this.resizeHandler);

    this.resizeHandler();

  }

  public generateSections(): void {

    let sections: MenuSections = [
      {
        title: 'Marketplace',
        links: [
          {
            title: 'Private Sellers',
            url: '/marketplace/private-sellers'
          },

          {
            title: 'Premier Buyers',
            url: '/marketplace/premier-buyers'
          },
          {
            title: 'M&A Advisors',
            url: '/marketplace/advisors'
          }
        ]
      },
      {
        title: 'Services',
        links: [
          {
            title: 'Sell Your Company',
            url: '/selling'
          },
          {
            title: 'Buy A Company',
            url: '/buying'
          },
          // {
          // 	title: 'Grow Your Company',
          // 	url: '/incubator'
          // }
        ]
      },
      {
        title: 'Learn',
        links: [
          {
            title: 'About Exio',
            url: '/learn'
          },
          {
            title: 'Leadership',
            url: '/leadership'
          },
          {
            title: 'Case Study',
            url: '/case-study'
          },
          {
            title: 'Video Library',
            url: '/video-library'
          },
          {
            title: 'Blog',
            url: '/blog'
          }
        ]
      },
      // {
      // 	title: 'Partners',
      // 	links: [
      // 		{
      // 			title: 'Programs',
      // 			url: '/partner-program'
      // 		},
      // 		{
      // 			title: 'Our Partners',
      // 			url: '/our-partners'
      // 		}
      // 	]
      // },
      {
        title: 'Contact',
        links: [
          {
            title: 'Contact Us',
            url: '/contact'
          }
        ]
      }
    ];


    if (this.profile) {

      if (this.profile.isEmployee) {

        sections.push({
          title: 'Account',
          links: [
            {
              title: 'Articles',
              url: '/articles'
            },
            {
              title: 'Assets',
              url: '/assets'
            },
            {
              title: 'Contracts',
              url: '/contracts'
            },
            {
              title: 'Listing Management',
              url: '/listing-management'
            },
            {
              title: 'User Reset',
              url: '/user-reset'
            }
          ]
        });

      } else {

        sections.push({
          title: 'Account',
          links: [
            {
              title: 'Dashboard',
              url: '/action/dashboard'
            }
          ]
        });

      }

      sections[sections.length - 1].links.push({
        title: 'Log Out',
        url: 'logout'
      });

    } else {

      sections.push({
        title: 'Account',
        links: [
          // {
          // 	title: 'Sign Up',
          // 	url: '/signup'
          // },
          {
            title: 'Log In',
            url: '/action/login'
          }
        ]
      });

    }

    this.sections = sections;

  }

  public toggleExpandedSection(section: MenuSection): void {
    if (this.isSectionExpanded(section)) {
      this.expandedSection = null;
    } else {
      this.expandedSection = section;
    }
  }

  public isSectionExpanded(section: MenuSection): boolean {
    return this.expandedSection && section && this.expandedSection.title === section.title;
  }

  public ngOnDestroy(): void {

    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.$window.off('resize', this.resizeHandler);
    this.resizeHandler = null;

  }

  public setMenuSize(): void {

    let containerHeight = 0;
    let menuHeight = 0;

    if (!this.appService.toolbar.collapsed) {
      containerHeight = this.$window.height();
      menuHeight = this.$window.height();
    }

    this.$container.height(containerHeight);
    this.$menu.height(menuHeight);

  }

  public showGray(): boolean {
    return this.isCollapsed() && (this.appService.content.loading || !this.appService.toolbar.whiteOverContent);
  }

  public toggleOpened(): void {
    if (this.appService.toolbar.collapsed) {
      this.show();
    } else {
      this.hide();
    }
  }

  public isCollapsed(): boolean {
    return this.appService.toolbar.collapsed;
  }

  public show(): void {
    this.$body.css('overflow', 'hidden');
    this.expandedSection = null;
    this.appService.toolbar.collapsed = false;
    this.setMenuSize();
  }

  public hide(): void {
    this.$body.css('overflow', 'inherit');
    this.expandedSection = null;
    this.appService.toolbar.collapsed = true;
    this.setMenuSize();
  }

  public goDashboard(): void {
    this.goLink({title: 'Dashboard', url: '/action/dashboard'});
  }

  public goHome(): void {
    this.goLink({title: 'Home', url: '/'});
  }

  public goLink(link: MenuLink): void {

    this.hide();

    if (!link || typeof link.url !== 'string') {
      return;
    }

    if (link.url === 'logout') {
      this.appService.logout();
    } else if (link.url.match(/^\//)) {
      this.router.navigate([link.url]);
    } else {
      window.open(link.url, '_blank');
    }

  }

  public menuSections(): MenuSections {

    return this.sections;

  }

  public clearCookies(): void {

    Cookie.remove('_sid');
    Cookie.remove('previous-access');
    Cookie.remove('login-target');
    Cookie.remove('signup-reason');
    Cookie.remove('previous-login');
    Cookie.remove('previous-authenticated-email');

    this.appService.logout();

  }

}
