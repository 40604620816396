
<div class="banner-container" *ngIf="article">
  <div class="banner">
    <div class="background-img desktop-layout" [style.background-color]="article.bannerBackground || article.bannerBackgroundMobile" [style.background-image]="bannerImgUrl()" [style.background-position]="article.bannerImgPosition || 'top center'" [style.background-size]="'cover'"></div>
    <div class="background-img mobile-layout" [style.background-color]="article.bannerBackgroundMobile || article.bannerBackground" [style.background-image]="bannerImgUrlMobile()" [style.background-position]="article.bannerImgPositionMobile || 'top center'" [style.background-size]="'cover'"></div>
    <div class="content-container-background" [ngClass]="getContentContainerClasses()">
      <div class="content-container" [style.top]="getTopPadding()">
        <div class="content" [ngClass]="getContentClasses()">
          <div class="content-field pre-title" *ngIf="showPreTitle()">{{ article.tagline }}</div>
          <div class="content-field title" *ngIf="showTitle()" [innerHTML]="format(article.title, true)"></div>
          <div class="content-field quote quote-left" *ngIf="showQuoteLeft()"><span class="left-quote">“&nbsp;</span><span class="quote-body" [innerHTML]="getTitleQuote()"></span></div>
          <div class="content-field quote quote-center" *ngIf="showQuoteCenter()"><span class="quote-body" [innerHTML]="getTitleQuote()"></span></div>
          <div class="content-field subtitle" *ngIf="showSubTitle()"><span class="subtitle-body render-desktop" [innerHTML]="format(article.description, true)"></span><span class="subtitle-body render-mobile" [innerHTML]="format(article.description, false)"></span></div>
          <div class="content-field actions" *ngIf="showArticleAction()">
            <div class="action" (click)="goAction()">
              <div class="label-container">
                <div class="label">{{ articleAction().name }}</div>
              </div>
            </div>
          </div>
          <div class="content-field upgrade-access" *ngIf="showUpgradeAccess()">
            <div class="action" (click)="upgradeAccess()">
              <div class="label-container">
                <div class="label">{{ _privateAccessLabel() }}</div>
              </div>
            </div>
          </div>
          <div class="content-field save-listing" *ngIf="showSaveListing()">
            <div class="action" (click)="saveListing()">
              <div class="label-container">
                <div class="label">Save Listing</div>
              </div>
            </div>
          </div>
          <div class="content-field save-listing" *ngIf="showUnSaveListing()">
            <div class="action" (click)="unSaveListing()">
              <div class="label-container">
                <div class="label">Un-Save Listing</div>
              </div>
            </div>
          </div>
          <div class="content-field upgrade-pending" *ngIf="privateUpgradePendingEmail()">
            <div class="label-container">
              <div class="label">Please Click The Link In Your Email to Confirm Your Submission</div>
            </div>
          </div>
          <div class="content-field upgrade-pending" *ngIf="privateUpgradePending()">
            <div class="label-container">
              <div class="label">Your Private Upgrade Is Pending</div>
            </div>
          </div>
          <div class="content-field upgrade-pending" *ngIf="exclusiveUpgradePending()">
            <div class="label-container">
              <div class="label">{{ _pendingExclussiveAccessLabel() }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>