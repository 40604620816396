import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'app/services/app';

@Component( {
	moduleId: module.id,
	selector: '.app-view-legal-document-view',
	templateUrl: 'view.component.html',
	styleUrls: [ 'view.component.less' ]
} )
export class ViewsLegalDocumentPrintComponent {

	public path: string = null;
	public instanceId: string = null;

	constructor( public app: AppService, public route: ActivatedRoute ) {

		this.app.toolbar.whiteOverContent = false;
		this.app.toolbar.backgroundColor = null;
		this.app.contentLoading( true );
		this.app.footer.hide = true;

	}

	public subscriptions: any[] = [];

	public ngOnDestroy(): void {
		this.subscriptions.forEach( ( subscription ) => {
			subscription.unsubscribe();
		} );
	}

	public ngOnInit(): void {

		document.body.scrollTop = 0;

		this.subscriptions.push( this.route.params.subscribe( ( params: { path: string; instanceId?: string } ) => {
			this.path = params.path;
			this.instanceId = params.instanceId;
		} ) );

	}

}
