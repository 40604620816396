
<div class="page-container">
  <div class="pages-review">
    <div class="page-review col-md-4 col-xs-12" *ngFor="let page of getPages()">
      <div class="page-title">{{ pageTitle(page) }}</div>
      <div class="items-review">
        <div class="item-review" *ngFor="let item of getItems(page)">{{ item.value }}</div>
      </div>
      <div class="page-action" (click)="goToPage(page)">{{ editTitle(page) }}</div>
    </div>
  </div>
</div>