
<div class="sections-container render-mode" *ngIf="isArray(sections) &amp;&amp; !editMode()">
  <!--.section(*ngFor="let section of sections; let i = index;", [ngClass]="ngClassSet(section)", [style.padding-top]="contentPaddingTop(section)", [style.padding-bottom]="contentPaddingBottom(section)")-->
  <div class="section" *ngFor="let section of sections; let i = index;" [ngClass]="ngClassSet(section)">
    <div class="section-anchor" *ngIf="section.contentsName" [attr.data-section-name]="section.contentsName"></div>
    <div class="section-content section-hidden" *ngIf="section.type ==='hidden' &amp;&amp; authenticatedUserAccessLevel() !== 'exclusive'">
      <div class="section-hidden-container">
        <div class="lock-container" (click)="onLockedClick()">
          <div class="component-icon lock-gray"></div>
        </div>
        <div class="section-hidden-mask-paragraph" *ngFor="let paragraph of getHiddenMasks(i);">
          <div class="section-hidden-mask-character" *ngFor="let character of paragraph"></div>
        </div>
      </div>
    </div>
    <div class="section-content section-text" *ngIf="section.type ==='text'" [innerHTML]="markdownToHtml(section.content)" [style.text-align]="section.contentHorizontalAlign" [style.background-color]="section.contentBackgroundColor" [style.color]="section.contentForegroundColor"></div>
    <div class="section-content section-heading" *ngIf="isHeading(section.type)" [innerHTML]="'&lt;' + section.type + '&gt;' + applyNewLines(section.content) + '&lt;/' + section.type + '&gt;'" [style.text-align]="section.contentHorizontalAlign" [style.background-color]="section.contentBackgroundColor" [style.color]="section.contentForegroundColor"></div>
    <div class="section-content section-br" *ngIf="section.type ==='br'"><br></div>
    <div class="section-content section-hr" *ngIf="section.type ==='hr'">
      <hr [style.background-color]="section.contentBackgroundColor">
    </div>
    <div class="section-content section-vr" *ngIf="section.type ==='vr'">
      <div class="vertical-rule" [style.background-color]="section.contentBackgroundColor"></div>
    </div>
    <div class="section-content section-exio-score" *ngIf="section.type === 'exio-score'">
      <div class="score-metrics">
        <div class="score-metric-container" *ngFor="let metric of section.content.metrics; let i = index;">
          <div class="score-metric-fill" [style.width]="metric.value + '%'">
            <div class="score-metric-name">{{ metric.name }}</div>
            <div class="score-metric-value">{{ metric.value }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-content section-swot" *ngIf="section.type ==='swot'">
      <div class="swot-container">
        <div class="swot-item item-strength">
          <div class="swot-item-container">
            <div class="swot-icon">
              <div class="component-icon barbell"></div>
            </div>
            <div class="swot-title">Strengths</div>
            <div class="swot-content">{{ section.content.strength }}</div>
          </div>
        </div>
        <div class="swot-item item-weakness">
          <div class="swot-item-container">
            <div class="swot-icon">
              <div class="component-icon empty-battery"></div>
            </div>
            <div class="swot-title">Weaknesses</div>
            <div class="swot-content">{{ section.content.weakness }}</div>
          </div>
        </div>
        <div class="swot-item item-opportunity">
          <div class="swot-item-container">
            <div class="swot-icon">
              <div class="component-icon bulb"></div>
            </div>
            <div class="swot-title">Opportunities</div>
            <div class="swot-content">{{ section.content.opportunity }}</div>
          </div>
        </div>
        <div class="swot-item item-threat">
          <div class="swot-item-container">
            <div class="swot-icon">
              <div class="component-icon alert"></div>
            </div>
            <div class="swot-title">Threats</div>
            <div class="swot-content">{{ section.content.threat }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-content section-img" *ngIf="section.type ==='img'">
      <div class="app-content-image-gallery" [layout]="section.content.layout" [urls]="getImgContent(section).urls" [locked]="!hasSectionAccess(section)" [lockedCallback]="lockedCallback"></div>
    </div>
    <div class="section-content section-grid render-mobile" *ngIf="section.type === 'grid'" [section]="section" [mode]="mode" [renderMode]="'mobile'"></div>
    <div class="section-content section-grid render-desktop" *ngIf="section.type === 'grid'" [section]="section" [mode]="mode" [renderMode]="'desktop'"></div>
    <div class="section-content section-carousel" *ngIf="section.type ==='carousel'">
      <div class="app-content-banner-carousel" [categories]="carouselCategories(section)" [navigationMode]="'arrows'" [topPadding]="0"></div>
    </div>
    <div class="section-content section-leadership" *ngIf="section.type ==='leadership'">
      <div class="content-render-leadership"></div>
    </div>
    <div class="section-content section-vimeo vimeo-player-service" *ngIf="section.type ==='vimeo' &amp;&amp; section.content" [videoId]="section.content" [metaData]="section.meta" [locked]="!hasSectionAccess(section)" [lockedCallback]="lockedCallback"></div>
    <div class="section-content section-vimeo vimeo-hidden" *ngIf="section.type ==='hidden-vimeo'">
      <div class="content-container-outer">
        <div class="content-container-inner">
          <div class="content-title">Ready to experience this company's customized,</div>
          <div class="content-title">studio-quality video?</div>
          <div class="content-action">
            <div class="content-action-container">
              <div class="content-action-title">Gain Access</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="sections-container edit-mode" *ngIf="isArray(sections) &amp;&amp; editMode()">
  <div class="section" *ngIf="sections.length &lt; 1" [attr.data-section-id]="'-1'" [class.drag-active]="dragActive">
    <div class="section-content section-drop-target" [attr.data-target-position]="'bottom'">
      <div class="section-drop-target-contents">
        <div class="section-drop-target-contents"><span>Drop New Content</span></div>
      </div>
    </div>
  </div>
  <div class="section" *ngFor="let section of sections; let i = index; let f = first; let l = last;" [ngClass]="ngClassSet(section)" [attr.data-section-id]="i" [class.drag-active]="dragActive">
    <div class="section-content section-drop-target" *ngIf="i === 0" [attr.data-target-position]="'top'">
      <div class="section-drop-target-contents"><span>Drop New Content</span></div>
    </div>
    <!-- Text Area Controls-->
    <div class="content-render-editor content-render-editor-text" *ngIf="section.type ==='text'" [section]="section"></div>
    <div class="section-content section-text" *ngIf="section.type ==='text' &amp;&amp; !section.editing" (dblclick)="setSectionEditing(i)" [innerHTML]="markdownToHtml(section.content || 'Double click to edit content')" [style.text-align]="section.contentHorizontalAlign" [style.background-color]="section.contentBackgroundColor" [style.color]="section.contentForegroundColor"></div>
    <div class="section-content section-text" *ngIf="section.type ==='text' &amp;&amp; section.editing">
      <textarea [(ngModel)]="section.content" (blur)="clearSectionEditing()" (keyup)="keyUpCheck($event.code)" autofocus (change)="changeDetection()"></textarea>
    </div>
    <!-- Heading Field Controls-->
    <div class="content-render-editor content-render-editor-heading" *ngIf="isHeading(section.type)" [section]="section"></div>
    <div class="section-content section-heading" *ngIf="isHeading(section.type) &amp;&amp; !section.editing" (dblclick)="setSectionEditing(i)" [innerHTML]="'&lt;' + section.type + '&gt;' + (applyNewLines(section.content) || 'Double click to edit content' ) + '&lt;/' + section.type + '&gt;'" [style.text-align]="section.contentHorizontalAlign" [style.background-color]="section.contentBackgroundColor" [style.color]="section.contentForegroundColor"></div>
    <div class="section-content section-heading" *ngIf="isHeading(section.type) &amp;&amp; section.editing">
      <textarea [(ngModel)]="section.content" (blur)="clearSectionEditing()" (keyup)="keyUpCheck($event.code)" autofocus (change)="changeDetection()"></textarea>
    </div>
    <div class="content-render-editor content-render-editor-br" *ngIf="section.type ==='br'" [section]="section"></div>
    <div class="section-content section-br" *ngIf="section.type ==='br'"><br></div>
    <div class="content-render-editor content-render-editor-vr" *ngIf="section.type ==='vr'" [section]="section"></div>
    <div class="section-content section-vr" *ngIf="section.type ==='vr'">
      <div class="vertical-rule" [style.background-color]="section.contentBackgroundColor"></div>
    </div>
    <div class="content-render-editor content-render-editor-hr" *ngIf="section.type ==='hr'" [section]="section"></div>
    <div class="section-content section-hr" *ngIf="section.type ==='hr'">
      <hr [style.background-color]="section.contentBackgroundColor">
    </div>
    <div class="content-render-editor content-render-editor-exio-score" *ngIf="section.type ==='exio-score'" [section]="section"></div>
    <div class="section-content section-exio-score" *ngIf="section.type ==='exio-score'">
      <div class="score-metrics" *ngFor="let metric of section.content.metrics; let i = index;">
        <div class="score-metric">
          <div class="score-field score-title">
            <input [type]="text" [(ngModel)]="metric.name" placeholder="Liquidity, Profitability, Sales, etc" (change)="changeDetection()">
          </div>
          <div class="score-field score-value">
            <input [type]="number" [(ngModel)]="metric.value" placeholder="Integer, 0-100" (change)="changeDetection()">
          </div>
          <div class="score-field score-actions"><span class="glyphicon glyphicon-minus" (click)="removeScoreContent(section, i)"></span></div>
        </div>
      </div><span class="glyphicon glyphicon-plus" (click)="addScoreContent(section)"></span>
    </div>
    <div class="content-render-editor content-render-editor-swot" *ngIf="section.type ==='swot'" [section]="section"></div>
    <div class="section-content section-swot" *ngIf="section.type ==='swot'">
      <input [type]="text" [(ngModel)]="section.content.strength" placeholder="Strengths" (change)="changeDetection()">
      <input [type]="text" [(ngModel)]="section.content.weakness" placeholder="Weaknesses" (change)="changeDetection()">
      <input [type]="text" [(ngModel)]="section.content.opportunity" placeholder="Opportunities" (change)="changeDetection()">
      <input [type]="text" [(ngModel)]="section.content.threat" placeholder="Threats" (change)="changeDetection()">
    </div>
    <div class="content-render-editor content-render-editor-img" *ngIf="section.type ==='img'" [section]="section"></div>
    <div class="section-content section-img" *ngIf="section.type ==='img'">
      <div class="img-blocks">
        <div class="img-block-header">
          <div class="img-block-cell">Image URL&nbsp;<span class="glyphicon glyphicon-plus" (click)="addImgContent(section)"></span></div>
        </div>
        <div class="img-block" *ngFor="let imgBlock of getImgContent(section).urls; let i = index;">
          <div class="img-block-cell">
            <input class="content-render-editor-inline-img" [type]="'text'" [(ngModel)]="imgBlock.url" (change)="changeDetection()">
          </div>
          <div class="img-block-cell-actions"><span class="glyphicon glyphicon-minus" (click)="removeImgContent(section, i)"></span>
            <select [(ngModel)]="imgBlock.position">
              <option *ngFor="let value of bannerPositionValues" value="{{value}}">{{ value }}</option>
            </select>
          </div>
        </div>
        <!--.img-block-->
        <!--	.img-block-cell-actions-->
        <!--		span.glyphicon.glyphicon-plus((click)="addImgContent(section)")-->
      </div><br><span class="title">Displayed Preview</span>
      <div class="app-content-image-gallery" [layout]="section.content.layout" [urls]="getImgContent(section).urls" [locked]="false"></div><br><span class="title">Mobile Displayed Preview</span>
      <div class="app-content-image-gallery" [layout]="'carousel'" [urls]="getImgContent(section).urls" [locked]="false"></div><br><span class="title">Hidden Preview</span>
      <div class="app-content-image-gallery" [layout]="section.content.layout" [urls]="getImgContent(section).urls" [locked]="true"></div><br><span class="title">Mobile Hidden Preview</span>
      <div class="app-content-image-gallery" [layout]="'carousel'" [urls]="getImgContent(section).urls" [locked]="true"></div>
    </div>
    <div class="content-render-editor content-render-editor-grid" *ngIf="section.type === 'grid'" [section]="section"></div>
    <div class="section-content section-grid" *ngIf="section.type === 'grid'" (dblclick)="setSectionEditing(i)" (blur)="clearSectionEditing()" (keyup)="keyUpCheck($event.code)" [section]="section" [mode]="mode"></div>
    <div class="content-render-editor content-render-editor-carousel" *ngIf="section.type ==='carousel'" [section]="section"></div>
    <div class="section-content section-carousel" *ngIf="section.type ==='carousel'">
      <div class="app-content-banner-carousel" [categories]="carouselCategories(section)" [navigationMode]="'arrows'" [topPadding]="0"></div>
    </div>
    <div class="content-render-editor content-render-editor-leadership" *ngIf="section.type ==='leadership'" [section]="section"></div>
    <div class="section-content section-leadership" *ngIf="section.type ==='leadership'">
      <div class="content-render-leadership"></div>
    </div>
    <div class="content-render-editor content-render-editor-vimeo" *ngIf="section.type ==='vimeo'" [section]="section"></div>
    <div class="section-content section-vimeo vimeo-player-service" *ngIf="section.type ==='vimeo' &amp;&amp; section.content" [videoId]="section.content"></div>
    <div class="section-content section-vimeo" *ngIf="section.type ==='vimeo' &amp;&amp; !section.content">Paste Vimeo Video ID on left</div>
    <div class="content-render-editor content-render-editor-feature" *ngIf="section.type ==='feature'" [section]="section"></div>
    <div class="section-content section-heading" *ngIf="section.type ==='feature' &amp;&amp; section.contentsName &amp;&amp; section.contentsIcon">
      <h1 [style.text-align]="'center'">Feature: {{ section.contentsName }}</h1>
    </div>
    <div class="section-content section-feature" *ngIf="section.type ==='feature' &amp;&amp; (!section.contentsName || !section.contentsIcon)">Please enter content name and select a content icon.</div>
    <div class="content-render-position">
      <button class="delete-content active" (click)="deleteSection(section, i)">Delete</button>
      <button class="active" *ngIf="!f" type="button" (click)="moveSectionUp(i)">Move Up</button>
      <button class="active" *ngIf="!l" type="button" (click)="moveSectionDown(i)">Move Down</button>
    </div>
    <div class="section-content section-drop-target" [attr.data-target-position]="'bottom'">
      <div class="section-drop-target-contents"><span>Drop New Content</span></div>
    </div>
  </div>
</div>