import { Component, OnInit, OnDestroy, ElementRef, NgZone } from '@angular/core';
import { AppService } from 'app/services/app';
import { Profile } from 'app/models/profile';
import { ActivatedRoute, Router } from '@angular/router';

import { Article } from 'app/models/article';

interface RouteParams {
  return?: string;
  subject?: string;
  comments?: string;
  scroll?: string;
}

@Component( {
  moduleId: module.id,
  selector: 'app-view-contact-main',
  templateUrl: 'main.component.html',
  styleUrls: [ 'main.component.less' ]
} )
export class ViewsContactMainComponent implements OnInit, OnDestroy {

  public submitted: boolean = false;
  public processing: boolean = false;
  public errorMessage: string = null;

  public $el: JQuery = null;
  public article: Article = null;

  public scroll: boolean = false;

  public params: RouteParams = {};
  public profile: Profile = null;

  public model: {
    firstName?: string;
    lastName?: string;
    email?: string;
    company?: string;
    subject?: string;
    comments?: string;
    to?: [ string ]
  } = {};

  public subscriptions: any[] = [];

  constructor( public app: AppService,
               public route: ActivatedRoute,
               public router: Router,
               public element: ElementRef,
               public zone: NgZone ) {

    this.app.contentLoading( false );
    this.app.toolbar.whiteOverContent = true;
    this.app.toolbar.backgroundColor = null;

    this.app.titleService.setTitle( 'Exio - Contact' );

    this.$el = $( this.element.nativeElement );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach( ( subscription ) => {
      subscription.unsubscribe();
    } );
  }

  public ngOnInit(): void {

    const paramHandler = ( params: RouteParams ) => {

      if ( !params ) {
        return;
      }

      if ( params.subject ) {
        this.params.subject = params.subject;
      }

      if ( params.comments ) {
        this.params.comments = params.comments;
      }

      if ( params.return ) {
        this.params.return = params.return;
      }

      if ( params.scroll ) {
        this.scroll = true;

        this.goScroll();
      }

      this.reset();

    };

    this.subscriptions.push( this.route.params.subscribe( paramHandler ) );
    this.subscriptions.push( this.route.queryParams.subscribe( paramHandler ) );

    this.subscriptions.push( this.app.getAuthenticatedProfile( {
      next: ( profile ) => {

        this.profile = profile || null;
        this.reset();

      },
      error: () => {
        this.profile = null;
        this.reset();
      },
      complete: () => {
        this.profile = null;
        this.reset();
      }
    } ) );

    this.loadContent();

  }

  public goScroll(): void {

    if ( !this.scroll ) {
      return;
    }

    // let HTML settle
    window.setTimeout( () => {

      const $target = this.$el.find( '.contact-content' );

      if ( this.article && $target.length > 0 ) {
        const top = $target.offset().top - 86;

        this.zone.runOutsideAngular( () => {
          requestAnimationFrame( () => {

            $( 'html, body' ).stop().animate( { scrollTop: top }, 1000 );
          } );
        } );

      }

    } );
  }

  public loadContent(): void {

    this.app
      .articleModel
      .getByPath( {
        path: 'contact'
      } )
      .then( ( article: Article ) => {
        this.app.contentLoading( false );
        if ( article ) {
          this.article = article;
          this.goScroll();
        } else {
          this.article = null;
        }
      } )
      .catch( () => {
        this.article = null;
      } );

  }

  public reset(): void {

    this.processing = false;
    this.errorMessage = null;
    this.model = {};

    if ( this.profile ) {
      [
        'firstName',
        'lastName',
        'email',
        'company'
      ].forEach( ( field ) => {
        if ( this.profile.hasOwnProperty( field ) && typeof this.profile[ field ] === 'string' ) {
          this.model[ field ] = this.profile[ field ];
        }
      } )
    }

    if ( this.params ) {
      [
        'subject',
        'comments'
      ].forEach( ( field ) => {
        if ( this.params.hasOwnProperty( field ) && typeof this.params[ field ] === 'string' ) {
          this.model[ field ] = this.params[ field ];
        }
      } )
    }


  }

  public check(): boolean {

    let valid = true;

    [
      'firstName',
      'lastName',
      'email',
      'company',
      'subject',
    ].forEach( ( field ) => {
      if ( typeof this.model[ field ] !== 'string' || this.model[ field ].trim().length < 1 ) {
        this.errorMessage = 'Please fill in all required fields marked with *';
        valid = false;
      }
    } );

    return valid;

  }

  public send(): void {

    // console.log( 'this.model', this.model );

    this.app.freshSalesEvent( 'Submitted contact form', this.model );

    if ( !this.check() ) {
      return;
    }

    this.errorMessage = null;
    this.processing = true;
    this.submitted = false;

    const always = ( e?: Error ) => {
      this.processing = false;

      if ( e ) {
        this.errorMessage = 'Failed to submit. Please try again in a moment.';
      } else {

        this.submitted = true;
        window.setTimeout( () => {
          this.submitted = false;

          this.reset();

          if ( this.params && this.params.return ) {
            this.router.navigate( [ this.params.return ] );
          }

        }, 10000 );

      }

    };

    this.app.exio.user.note( this.model )
      .then( () => {
        always();
      } )
      .catch( ( e ) => {
        always( e );
      } );

  }

}
