import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewChecked,
  OnChanges,
  SimpleChanges,
  ElementRef,
  NgZone
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';

import {AppService} from 'app/services/app';
import {
  LegalDocument,
  LegalDocumentInstance,
  LegalDocumentInstanceSigner,
  LegalDocumentSection,
  SearchResponse
} from 'app/models/legal.document';
import {Profile} from 'app/models/profile';
import {Hash} from 'app/types/containers';

type OptionItem = string[];

@Component({
  moduleId: module.id,
  selector: 'app-view-contracts-editor',
  templateUrl: 'editor.component.html',
  styleUrls: ['editor.component.less']
})
export class ViewsContractsEditorComponent implements OnChanges, OnInit, OnDestroy, AfterViewChecked {

  public saving: boolean = true;

  public document: LegalDocument = null;
  public documentEdited: LegalDocument = null;
  public dirty: boolean = false;

  public contacts: Profile[] = null;
  public contactsById: Hash<Profile> = {};

  public employeeProfiles: Profile[] = null;

  public $el: JQuery = null;

  constructor(public app: AppService, public route: ActivatedRoute, public router: Router, public el: ElementRef, public location: Location, public zone: NgZone) {

    this.app.contentLoading(true);
    this.app.toolbar.whiteOverContent = false;
    this.app.toolbar.backgroundColor = null;
    this.app.footer.showMenu = false;

    this.$el = $(this.el.nativeElement);

  }

  public subscriptions: any[] = [];

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public ngOnInit(): void {

    document.body.scrollTop = 0;

    this.subscriptions.push(this.route.params.subscribe((params: { id: string }) => {
      this.setDocumentId(params.id);
    }));

    if (this.contacts === null) {
      this.contacts = [];

      this.app.profileModel.listEmployees()
        .then((profiles: Profile[]) => {
          this.employeeProfiles = this.contacts = profiles;
          this.contactsById = {};
          profiles.forEach((profile) => {
            this.contactsById[profile.id] = profile;
          });
        });
    }

  }

  public ngAfterViewChecked(): void {

    [
      'input',
      'textarea',
      'select'
    ].forEach((type: string) => {

      const $el = this.$el.find(`${type}:not(.change-detection-tracking)`);

      if ($el.length < 1) {
        return;
      }

      $el.addClass('change-detection-tracking');

      $el.change(() => {
        this.dirtyCheck();
      });

    });


  }

  public ngOnChanges(changes: SimpleChanges): void {

    window.setTimeout(() => {
      this.dirtyCheck();
    });

  }

  public getContactOptions(): OptionItem[] {
    const optionItems: OptionItem[] = [];

    this.contacts.forEach((contact) => {

      if (this.documentEdited.preSigners.indexOf(contact.id) < 0) {
        optionItems.push([contact.id, `${contact.lastName}, ${contact.firstName} <${contact.email}>`])
      }

    });

    return optionItems.sort((a, b) => {

      const aName = a[1];
      const bName = b[1];

      if (aName < bName) {
        return -1;
      }

      if (aName > bName) {
        return 1;
      }

      return 0;
    });
  }

  public getNotificationOptions(): OptionItem[] {
    const optionItems: OptionItem[] = [];

    this.employeeProfiles.forEach((profile) => {

      if (this.documentEdited.notifications.indexOf(profile.id) < 0) {
        optionItems.push([profile.id, `${profile.lastName}, ${profile.firstName} <${profile.email}>`])
      }

    });

    return optionItems.sort((a, b) => {

      const aName = a[1];
      const bName = b[1];

      if (aName < bName) {
        return -1;
      }

      if (aName > bName) {
        return 1;
      }

      return 0;
    });

  }

  public dirtyCheck(): void {

    const existing = JSON.stringify(this.document);
    const edited = JSON.stringify(this.documentEdited);

    this.dirty = existing !== edited;

  }

  public setDocumentId(id: string): void {

    if (typeof id !== 'string' || id.length < 1) {
      this.setDocument(null);
      this.app.contentLoading(false);
      return;
    }

    if (id === 'new') {

      const date = new Date().toISOString();
      const name = 'New Legal Document ' + date;
      const url = name.toLowerCase().replace(/ /g, '-');

      const base: LegalDocument = <LegalDocument>{
        id: 'new',
        name: null,
        title: null,
        url: null,
        sections: [],
        preSigners: [],
        notifications: []
      };

      const edited: LegalDocument = <LegalDocument>{
        id: 'new',
        name: name,
        title: name,
        url: url,
        sections: [],
        preSigners: [],
        notifications: []
      };

      this.setDocument(base, edited);
      this.app.contentLoading(false);

    } else {

      let duplicate = false;
      if (id.match(/^duplicate:/)) {
        duplicate = true;
        id = id.replace(/^duplicate:/, '');
      }

      this.app.legalDocumentModel.get(id)
        .then((document: LegalDocument) => {
          this.app.contentLoading(false);

          if (duplicate) {

            document.id = 'new';

            const edited = JSON.parse(JSON.stringify(document));

            const postfix = ' Copy';

            edited.name += postfix;
            edited.title += postfix;
            edited.url += postfix.toLowerCase().replace(/ /g, '-');

            this.setDocument(document, edited);

          } else {
            this.setDocument(document);
          }

        })
        .catch(() => {
          this.app.contentLoading(false);
          this.setDocument(null);
        });

    }

  }

  public setDocument(document: LegalDocument, documentEdited?: LegalDocument): void {

    if (document) {
      this.document = document;

      if (documentEdited) {
        this.documentEdited = documentEdited;
      } else {
        this.documentEdited = JSON.parse(JSON.stringify(document));
      }

      if (this.document.id) {
        this.location.replaceState('/contracts/' + this.document.id);
      }

    } else {
      this.document = null;
      this.documentEdited = null;
    }

    this.dirtyCheck();

  }

  public save(): void {

    if (this.documentEdited.id === 'new') {
      this.documentEdited.id = null;
    }

    this.app.legalDocumentModel.save(this.documentEdited)
      .then((document: LegalDocument) => {
        this.setDocument(document);
      })
      .catch((err) => {
        console.error('error saving legal document', err);
      });

  }

  public reset(): void {
    this.setDocument(this.document);
  }

  public cancel(): void {
    this.router.navigate(['/contracts']);
  }

  public getProfile(id: string): Profile {
    return this.contactsById[id];
  }

  public addSection(): void {

    this.documentEdited.sections.push({
      title: '',
      contents: ''
    });

    this.dirtyCheck();

  }

  public removeSection(i: number) {
    this.documentEdited.sections.splice(i, 1);
    this.dirtyCheck();
  }

  public moveSectionUp(i: number) {
    if (i > 0) {
      const temp = this.documentEdited.sections[i - 1];
      this.documentEdited.sections[i - 1] = this.documentEdited.sections[i];
      this.documentEdited.sections[i] = temp;
    }
    this.dirtyCheck();
  }

  public moveSectionDown(i: number) {
    if (i < this.documentEdited.sections.length - 1) {
      const temp = this.documentEdited.sections[i + 1];
      this.documentEdited.sections[i + 1] = this.documentEdited.sections[i];
      this.documentEdited.sections[i] = temp;
    }
    this.dirtyCheck();
  }

  public getPreSigners(): Profile[] {
    const signers: Profile[] = [];
    let missingIdCount = 1;

    this.documentEdited.preSigners.forEach((userId) => {
      const profile = this.getProfile(userId);

      if (profile) {
        signers.push(profile);
      } else {
        signers.push(<Profile>{
          id: `missing_profile_id_${missingIdCount++}`,
          firstName: `Missing Profile {${userId}}`
        });
      }

    });

    return signers;
  }

  public addPreSigner(): void {
    const userId = this.$el.find('select.pre-signers-selector').val();
    if (this.documentEdited.preSigners.indexOf(userId) < 0) {
      this.documentEdited.preSigners.push(userId);
      this.dirtyCheck();
    }
  }

  public removePreSigner(id: string): void {
    const index = this.documentEdited.preSigners.indexOf(id);
    if (index > -1) {
      this.documentEdited.preSigners.splice(index, 1);
    }
    this.dirtyCheck();
  }

  public moveSignerUp(id: string): void {

    const preSigners = this.documentEdited.preSigners;
    const currentIndex = preSigners.indexOf(id);

    if (currentIndex < 1) {
      return;
    }

    const temp = preSigners[currentIndex - 1];
    preSigners[currentIndex - 1] = preSigners[currentIndex];
    preSigners[currentIndex] = temp;

    this.dirtyCheck();

  }

  public moveSignerDown(id: string): void {

    const preSigners = this.documentEdited.preSigners;
    const currentIndex = preSigners.indexOf(id);

    if (currentIndex >= preSigners.length - 1) {
      return;
    }

    const temp = preSigners[currentIndex + 1];
    preSigners[currentIndex + 1] = preSigners[currentIndex];
    preSigners[currentIndex] = temp;

    this.dirtyCheck();

  }

  public getNotifications(): Profile[] {

    const notifications: Profile[] = [];

    if (!this.documentEdited.notifications) {
      return notifications;
    }

    this.documentEdited.notifications.forEach((userId) => {

      const profile = this.getProfile(userId);

      if (profile) {
        notifications.push(profile);
      }

    });

    return notifications.sort((a, b) => {

      const aName = `${a.lastName} ${a.firstName} ${a.email}`;
      const bName = `${b.lastName} ${b.firstName} ${b.email}`;

      if (aName < bName) {
        return -1;
      }

      if (aName > bName) {
        return 1;
      }

      return 0;

    });

  }

  public addNotification(): void {

    const userId = this.$el.find('select.notifications-selector').val();

    if (this.documentEdited.notifications.indexOf(userId) > -1) {
      return;
    }

    this.documentEdited.notifications.push(userId);

    this.dirtyCheck();

  }

  public removeNotification(userId: string): void {

    const index = this.documentEdited.notifications.indexOf(userId);

    if (index < 0) {
      return;
    }

    this.documentEdited.notifications.splice(index, 1);

    this.dirtyCheck();

  }

}
