
<div class="editor-body" *ngIf="!app.content.loading &amp;&amp; !!article &amp;&amp; !!articleEdited">
  <!--.editor-settings-general([class.show-menu]="mode === 'settings'")-->
  <div class="editor-settings-general" [class.show-menu]="mode === 'settings'">
    <div class="name">Article General Settings</div>
    <div class="versions-menu">
      <div class="name">Versions</div>
      <div class="versions-notice" *ngIf="articleHistory === null">Loading...</div>
      <div class="versions-notice" *ngIf="articleHistory !== null &amp;&amp; articleHistory.length &lt; 1">No History</div>
      <select class="versions-select" *ngIf="articleHistory !== null &amp;&amp; articleHistory.length &gt; 0" [(ngModel)]="selectedVersion">
        <option value="-1" selected>Select a Previous Version</option>
        <option *ngFor="let article of  articleHistory; let i = index" value="{{i}}">{{ article.updated | date: 'medium' }}</option>
      </select>
      <div class="versions-action" *ngIf="selectedVersion !== '-1'" (click)="revertVersion()">Revert</div>
    </div>
    <div class="article-vdr-view" *ngIf="hasArticleVdr()" (click)="viewArticleVdr()">View Article VDR</div>
    <form class="editor-general" *ngIf="!!editorMenuGeneralForm" [formGroup]="editorMenuGeneralForm" (ngSubmit)="save()">
      <div class="article-published">
        <label class="check-box">
          <div class="name">Published</div>
          <input type="checkbox" formControlName="published">
        </label>
        <label class="check-box">
          <div class="name">Hidden</div>
          <input type="checkbox" formControlName="hidden">
        </label>
      </div>
      <div class="article-type">
        <div class="name">Article Type</div>
        <select formControlName="type">
          <option *ngFor="let value of [['post', 'Page / Blog'], ['listing', 'Listing'], ['buyer', 'Buyer'], ['advisor', 'Advisor'], ['video', 'Video']]" value="{{value[0]}}">{{ value[1] }}</option>
        </select>
      </div>
      <div class="article-sort-hint">
        <div class="name">Sort Order Hint</div>
        <select formControlName="sortHint">
          <option selected value="">Select A Value</option>
          <option *ngFor="let value of sortHintValues" value="{{value}}">{{ value }}</option>
        </select>
      </div>
      <div class="article-access-level">
        <div class="name">Access Level</div>
        <div class="input-type-select-access-level" [(accessLevel)]="articleEdited.accessLevel"></div>
      </div>
      <div class="article-listing-reference" *ngIf="articleEdited.type === 'listing' || articleEdited.type === 'buyer'|| articleEdited.type === 'advisor'">
        <div class="name">Select a Listing Reference</div>
        <select *ngIf="this.listingsLoading">
          <option value="none" selected>Loading...</option>
        </select>
        <select *ngIf="!this.listingsLoading" formControlName="listingLink">
          <option value="none" selected>Select a Listing</option>
          <option *ngFor="let value of getListings()" value="{{value.id}}">"{{ value.name }}" by {{ getProfileName(value.userId) }} ({{ value.id }})</option>
        </select><br>
        <div class="article-listing-reference-view" (click)="viewListingReference()" *ngIf="getSelectedListingId()">View Listing Data</div>
        <div class="article-listing-vdr-view" (click)="viewListingVdr()" *ngIf="getSelectedListingId()">View Listing VDR</div>
      </div>
      <div class="article-full-width-input">
        <div class="name">Author</div>
        <div class="message" *ngIf="!authors">Loading...</div>
        <select *ngIf="authors" formControlName="author">
          <option *ngFor="let value of authors" value="{{value[0]}}">{{ value[1] }} {{ value[0] }}</option>
        </select>
      </div>
      <div class="article-full-width-input" *ngIf="articleEdited.type === 'listing' || articleEdited.type === 'buyer'">
        <div class="name">Listing NDA</div>
        <div class="message" *ngIf="!legalDocuments">Loading...</div>
        <select *ngIf="legalDocuments" formControlName="nda">
          <option [value]="null">None</option>
          <option *ngFor="let value of legalDocuments" value="{{value[0]}}">{{ value[1] }}</option>
        </select><br>
        <div class="name">Listing Exclusive Questionnaire</div>
        <div class="message" *ngIf="!questionnaires">Loading...</div>
        <select *ngIf="questionnaires" formControlName="formId">
          <option value="null">Default</option>
          <option *ngFor="let value of questionnaires" value="{{value[0]}}">{{ value[1] }}</option>
        </select>
      </div>
      <div class="article-created-date">
        <div class="name">Created</div>
        <input type="text" formControlName="created" placeholder="mm/dd/yyyy">
      </div>
      <div class="article-listing-data" *ngIf="articleEdited.type === 'listing'">
        <div class="name">Listing Company Name</div>
        <textarea class="listing-company-name" formControlName="listingCompanyName"></textarea>
        <div class="name">Project Code Name</div>
        <input class="listing-code-name" type="text" formControlName="projectCodeName">
        <div class="name">Listing Company Industry</div>
        <textarea class="listing-company-name" formControlName="listingCompanyIndustry"></textarea>
        <div class="name">Listing Location Name</div>
        <input class="listing-region" type="text" formControlName="listingLocation" placeholder="Denver, CO; Bend, OR etc">
        <div class="name">Listing Location State</div>
        <input class="listing-region" type="text" formControlName="listingLocationState" placeholder="CO, ID, OR, etc">
        <div class="name">Listing Price</div>
        <input class="listing-price" type="number" formControlName="listingPrice" placeholder="Whole dollar amount, no dollar sign">
        <div class="name">Buyer Fee</div>
        <input class="listing-buyer-fee" type="number" formControlName="listingBuyerFee" placeholder="Buyer fee in percent (1, 1.25, 5, etc)">
        <div class="name">Exio Score</div>
        <input class="listing-price" type="number" formControlName="exioScore" placeholder="A number, 0-100">
        <div class="name">NAICS Code</div>
        <input class="listing-price" type="text" formControlName="naicsCode" placeholder="Enter a single NAICS code">
      </div>
      <div class="article-buyer-data" *ngIf="articleEdited.type === 'buyer' || articleEdited.type === 'advisor'">
        <div class="name">Buyer Name</div>
        <textarea class="listing-company-name" formControlName="listingCompanyName"></textarea>
        <div class="name">Project Code Name</div>
        <input class="listing-code-name" type="text" formControlName="projectCodeName">
        <div class="name">Buyer Interests by Industry</div>
        <textarea class="listing-company-name" formControlName="listingCompanyIndustry"></textarea>
        <div class="name">Buyer Interests by State</div>
        <input class="listing-region" type="text" formControlName="buyerInterestsByState" placeholder="Comma separated list: CO,ID,OR,etc">
        <div class="name">Buyer Price Min</div>
        <input class="listing-price" type="number" formControlName="buyerInterestsPriceMin" placeholder="Whole dollar amount, no dollar sign">
        <div class="name">Buyer Price Max</div>
        <input class="listing-price" type="number" formControlName="buyerInterestsPriceMax" placeholder="Whole dollar amount, no dollar sign">
        <div class="name">NAICS Code Interests</div>
        <input class="listing-price" type="text" formControlName="buyerInterestsNAICS" placeholder="Comma separated list: 1234,5678,etc.">
      </div>
      <div class="article-path">
        <div class="name">URL</div>
        <input type="text" formControlName="path" placeholder="Enter URL">
      </div>
      <!--.article-tagline(*ngIf="articleEdited.type !== 'listing' && articleEdited.type !== 'buyer'")-->
      <div class="article-path-alt">
        <div class="name">URL Aliases<span class="glyphicon glyphicon-plus" (click)="addUrlAlias()"></span></div>
        <div class="article-redirect-paths-list" formArrayName="redirectPaths">
          <div class="article-redirect-path" *ngFor="let redirectPath of editorMenuGeneralForm.controls.redirectPaths.controls; let i = index">
            <input type="text" [formControlName]="i"><span class="glyphicon glyphicon-minus" (click)="removeUrlAlias(i)"></span>
          </div>
        </div>
      </div>
      <div class="article-tagline">
        <div class="name">Article Pre-Title</div>
        <textarea formControlName="tagline" placeholder="Enter Pre-Title"></textarea>
      </div>
      <div class="article-title">
        <div class="name">Article Title</div>
        <textarea formControlName="title" placeholder="Enter Title"></textarea>
      </div>
      <div class="article-description">
        <div class="name">Article Sub-Title</div>
        <textarea formControlName="description" placeholder="Enter Sub-Title"></textarea>
      </div>
      <div class="article-categories">
        <div class="name">Categories<span class="glyphicon glyphicon-plus" (click)="addCategory()"></span></div>
        <div class="article-categories-list" formArrayName="categories">
          <div class="article-category" *ngFor="let category of editorMenuGeneralForm.controls.categories.controls; let i = index">
            <input type="text" [formControlName]="i"><span class="glyphicon glyphicon-minus" (click)="removeCategory(i)"></span>
          </div>
        </div>
      </div>
      <div class="article-actions">
        <div class="name">Action Buttons<span class="glyphicon glyphicon-plus" (click)="addAction()"></span></div>
        <div class="article-actions-list" formArrayName="actions">
          <div class="article-action" *ngFor="let action of editorMenuGeneralForm.controls.actions.controls; let i = index" [formGroupName]="i">
            <div class="article-action-name">
              <label>Name</label>
              <input type="text" formControlName="name">
            </div>
            <div class="article-action-url">
              <label>URL</label>
              <input type="text" formControlName="url">
            </div><span class="glyphicon glyphicon-minus" (click)="removeAction(i)"></span>
          </div>
        </div>
      </div>
      <div class="article-submenus">
        <div class="name">SubMenu Items<span class="glyphicon glyphicon-plus" (click)="addSubmenuItem()"></span><span class="toggle-form-code" (click)="showSubMenuCode()" *ngIf="!submenuDataMode">Show Code</span><span class="toggle-form-code" (click)="showSubMenuForm()" *ngIf="submenuDataMode">Show Form</span><span class="error-message" *ngIf="submenuDataMode &amp;&amp; !isValidSubMenuData()">Not Valid JSON</span></div>
        <div class="article-submenus-code" *ngIf="submenuDataMode">
          <textarea></textarea>
        </div>
        <div class="article-submenus-list" *ngIf="!submenuDataMode" formArrayName="submenu">
          <div class="article-submenu" *ngFor="let action of editorMenuGeneralForm.controls.submenu.controls; let i = index" [formGroupName]="i">
            <div class="article-submenu-name">
              <label>Name</label>
              <input type="text" formControlName="name">
            </div>
            <div class="article-submenu-url">
              <label>URL</label>
              <input type="text" formControlName="url">
            </div><span class="glyphicon glyphicon-minus" (click)="removeSubmenuItem(i)"></span>
          </div>
        </div>
      </div>
      <div class="article-tile">
        <div class="tile-input tile-url">
          <div class="name">Tile Image URL Small ( 212px tall by 300px wide)</div>
          <input type="text" formControlName="tileImgUrl" placeholder="Enter Tile Image URL (optional)">
        </div>
        <div class="tile-url-preview" *ngIf="articleEdited.tileImgUrl">
          <div class="name">Tile Image URL Small Preview</div><br><img [src]="tileImgUrl(false)">
        </div>
        <div class="tile-input tile-url">
          <div class="name">Tile Image URL Large ( 417px tall by 300px wide)</div>
          <input type="text" formControlName="tileImgLargeUrl" placeholder="Enter Tile Image URL (optional)">
        </div>
        <div class="tile-url-preview" *ngIf="articleEdited.tileImgLargeUrl">
          <div class="name">Tile Image URL Large Preview</div><br><img [src]="tileImgUrl(true)">
        </div>
      </div>
      <div class="article-banner">
        <div class="article-banner-left">
          <div class="banner-input banner-url">
            <div class="name">Banner Image URL</div>
            <input type="text" formControlName="bannerImgUrl" placeholder="Enter Banner Image URL (optional)">
          </div>
          <div class="banner-input banner-opacity">
            <div class="name">Banner Overlay Opacity</div>
            <select formControlName="bannerOpacity">
              <option *ngFor="let value of bannerOpacityValues" value="{{value}}">{{ value }}%</option>
            </select>
          </div>
          <div class="banner-input banner-position">
            <div class="name">Banner Image Position</div>
            <select formControlName="bannerImgPosition">
              <option *ngFor="let value of bannerPositionValues" value="{{value}}">{{ value }}</option>
            </select>
          </div>
          <div class="banner-input banner-background-color">
            <div class="name">Banner Background Color</div>
            <div class="select-background-color-block" *ngFor="let color of app.colors;" [style.background-color]="color" (click)="articleEdited.bannerBackground = color"></div><a class="reset-foreground active" (click)="articleEdited.bannerBackground = null">Reset</a>
          </div>
          <div class="banner-input banner-layout">
            <div class="name">Banner Layout</div>
            <select formControlName="bannerLayout">
              <option *ngFor="let value of bannerLayoutValues" value="{{value[0]}}">{{ value[1] }}</option>
            </select>
          </div>
        </div>
        <div class="article-banner-right">
          <div class="banner-input banner-url">
            <div class="name">Banner Image URL Mobile</div>
            <input type="text" formControlName="bannerImgUrlMobile" placeholder="Enter Banner Image URL for Mobile (optional)">
          </div>
          <div class="banner-input banner-opacity">
            <div class="name">Banner Overlay Opacity Mobile</div>
            <select formControlName="bannerOpacityMobile">
              <option *ngFor="let value of bannerOpacityValues" value="{{value}}">{{ value }}%</option>
            </select>
          </div>
          <div class="banner-input banner-position">
            <div class="name">Banner Image Position Mobile</div>
            <select formControlName="bannerImgPositionMobile">
              <option *ngFor="let value of bannerPositionValues" value="{{value}}">{{ value }}</option>
            </select>
          </div>
          <div class="banner-input banner-background-color">
            <div class="name">Banner Background Color Mobile</div>
            <div class="select-background-color-block" *ngFor="let color of app.colors;" [style.background-color]="color" (click)="articleEdited.bannerBackgroundMobile = color"></div><a class="reset-foreground active" (click)="articleEdited.bannerBackgroundMobile = null">Reset</a>
          </div>
          <div class="banner-input banner-layout">
            <div class="name">Banner Layout Mobile</div>
            <select formControlName="bannerLayoutMobile">
              <option *ngFor="let value of bannerLayoutValues" value="{{value[0]}}">{{ value[1] }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="article-banner-preview">
        <div class="banner-input banner-test">
          <div class="name">Banner Preview</div>
          <div class="app-content-banner-container">
            <app-content-banner [article]="articleEdited"></app-content-banner>
          </div>
        </div>
      </div>
      <div class="article-listing-documents" *ngIf="articleEdited.type === 'listing' || articleEdited.type === 'buyer'">
        <div class="name">Listing Documents
          <button *ngIf="!loadingDocuments" type="button" [class.active]="true" (click)="refreshDocuments()">Refresh</button>
          <div class="loader-bar-container" *ngIf="loadingDocuments">
            <div class="loader-bar"></div>
          </div>
        </div>
        <div class="article-listing-documents-container" *ngIf="articleCount()  &lt; 0">Loading...</div>
        <div class="article-listing-documents-container" *ngIf="articleCount() === 0">No Document Published</div>
        <div class="article-listing-documents-container" *ngIf="articleCount() &gt; 0">
          <div class="article-listing-document-container" *ngFor="let documentFile of getArticleDocuments()">
            <div class="article-listing-document-field article-listing-document-field-name">{{ documentFile.name }}</div>
            <div class="article-listing-document-field article-listing-document-field-access-level">
              <div class="input-type-select-access-level" [(accessLevel)]="documentFile.accessLevel" (change)="fileAccessChanged()"></div>
            </div>
            <div class="article-listing-document-field article-listing-document-field-meta">Modified: {{ documentFile.modifiedTime | date:'medium' }}</div>
          </div>
        </div>
      </div>
      <!--.article-access-level-selection-->
      <!--	.name User Access Levels-->
      <!--	.article-access-level-selection-selector-container(*ngIf="!accessLevelProfilesReady()") Loading...-->
      <!--	.article-access-level-selection-selector-container(*ngIf="accessLevelProfilesReady() && accessLevelProfileOptions().length < 1") No Additional Users Available-->
      <!--	.article-access-level-selection-selector-container(*ngIf="accessLevelProfilesReady() && accessLevelProfileOptions().length > 0")-->
      <!--		.article-access-level-selection-container-->
      <!--			.input-type-dropdown-container-->
      <!--				.input-type-dropdown([options]="accessLevelProfileOptions()", [unselectedDisplayValue]="'Select a Profile'")-->
      <!--			span((click)="accessLevelUserAdd('private')") Add Private-->
      <!--			span((click)="accessLevelUserAdd('exclusive')") Add Exclusive-->
      <!--	.name Private Users-->
      <!--	.article-access-level-selection-list-container(*ngIf="accessLevelProfilesReady() && this.articleEdited.accessLevelPrivate")-->
      <!--		.article-access-level-selection-list(*ngIf="this.articleEdited.accessLevelPrivate.length < 1")-->
      <!--			.article-access-level-selection-user-->
      <!--				.name None-->
      <!--		.article-access-level-selection-list(*ngIf="this.articleEdited.accessLevelPrivate.length > 0")-->
      <!--			.article-access-level-selection-user(*ngFor="let profileId of this.articleEdited.accessLevelPrivate;")-->
      <!--				.name {{ getProfileName(profileId) }}-->
      <!--				span.glyphicon.glyphicon-minus((click)="accessLevelUserRemove(profileId)")-->
      <!--	.name Exclusive Users-->
      <!--	.article-access-level-selection-list-container(*ngIf="accessLevelProfilesReady() && this.articleEdited.accessLevelExclusive")-->
      <!--		.article-access-level-selection-list(*ngIf="this.articleEdited.accessLevelExclusive.length < 1")-->
      <!--			.article-access-level-selection-user-->
      <!--				.name None-->
      <!--		.article-access-level-selection-list(*ngIf="this.articleEdited.accessLevelExclusive.length > 0")-->
      <!--			.article-access-level-selection-user(*ngFor="let profileId of this.articleEdited.accessLevelExclusive;")-->
      <!--				.name {{ getProfileName(profileId) }}-->
      <!--				span.glyphicon.glyphicon-minus((click)="accessLevelUserRemove(profileId)")-->
      <div class="buttons">
        <div class="loader-spinner" *ngIf="saving"></div>
        <button class="control-search" *ngIf="!saving" type="submit" [class.active]="saveEnabled()" [disabled]="!saveEnabled()">Save</button>
        <button class="control-search" *ngIf="!saving" type="button" (click)="cancel()" [class.active]="resetEnabled()" [disabled]="!resetEnabled()">Cancel</button>
        <div class="save-message" *ngIf="saveMessage">{{ saveMessage }}</div>
      </div>
    </form>
  </div>
  <div class="editor-content-general">
    <div class="name">Article Content Editor</div>
    <div class="instructions">Drag and Drop Content Items</div>
    <div class="editor-components-menu-placeholder"></div>
    <div class="editor-components-menu">
      <div class="buttons" *ngIf="isFixedComponentMenu()">
        <div class="loader-spinner" *ngIf="saving"></div>
        <button class="control-search" *ngIf="!saving" type="button" (click)="save()" [class.active]="saveEnabled()" [disabled]="!saveEnabled()">Save</button>
        <button class="control-search" *ngIf="!saving" type="button" (click)="cancel()" [class.active]="resetEnabled()" [disabled]="!resetEnabled()">Cancel</button>
        <div class="save-message" *ngIf="saveMessage">{{ saveMessage }}</div>
      </div>
      <div class="editor-components">
        <div class="editor-component content-type" *ngFor="let component of editorMenuComponents" [attr.data-component-type]="component[0]" [attr.data-component-name]="component[1]"><span class="component-icon glyphicon" [class]="'span component-icon glyphicon ' + component[2]"></span>
          <div class="component-name">{{ component[1] }}</div>
        </div>
      </div>
    </div>
    <div class="editor-components-content" *ngIf="!!articleEdited &amp;&amp; !!articleEdited.sections">
      <app-content-render-sections [sections]="articleEdited.sections" [mode]="'edit'" [fixedMenu]="isFixedComponentMenu()" [parentChangeDetection]="getEditorDirtyCheckHandler()"></app-content-render-sections>
    </div>
  </div>
</div>