
<div class="buffer"></div>
<div class="container">
  <div class="map">
    <highcharts-chart [Highcharts]="Highcharts" [constructorType]="'mapChart'" [options]="chartOptions" [(update)]="chartUpdate" style="width: 800px; height: 400px; display: inline-block;"></highcharts-chart>
  </div>
  <div class="last-update" *ngIf="lastUpdateTimeStamp &amp;&amp; !loadingContent">{{ lastEventList.data.length }} event{{lastEventList.data.length === 1 ? '' : 's'}}</div>
  <div class="last-update" *ngIf="lastUpdateTimeStamp &amp;&amp; loadingContent">Refreshing data...</div>
  <div class="last-update" *ngIf="!lastUpdateTimeStamp">Loading data...</div>
  <div class="filters">
    <button mat-button mat-raised-button color="primary" [disableRipple]="false" [disabled]="isOldestMonth()" (click)="previousMonth()">Previous Month</button>
    <button mat-button mat-raised-button color="primary" [disableRipple]="false" [disabled]="isCurrentMonth()" (click)="nextMonth()">Next Month</button>
    <button mat-button mat-raised-button color="primary" [disableRipple]="false" [disabled]="isCurrentMonth()" (click)="thisMonth()">Month To Date</button>
    <mat-form-field class="month">
      <mat-select placeholder="Month" [(value)]="filter.month" (selectionChange)="applyFilter()">
        <mat-option *ngFor="let month of months; let i = index" [value]="i">{{ month }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="year">
      <mat-select placeholder="Year" [(value)]="filter.year" (selectionChange)="applyFilter()">
        <mat-option *ngFor="let year of years" [value]="year" [disabled]="disableYear(year)">{{ year }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="activity-types">
      <mat-select placeholder="Event Types" multiple [(value)]="filter.activityTypes" (selectionChange)="applyFilter()">
        <mat-option *ngFor="let activityType of activityTypes" [value]="activityType.value">{{ activityType.display }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-radio-group class="show-format" [(ngModel)]="displayType" [labelPosition]="'before'" [name]="'Display Type'" (change)="renderChart()">
      <mat-radio-button [value]="'percent'" color="primary">Show Percent</mat-radio-button>
      <mat-radio-button [value]="'count'" color="primary">Show Counts</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="activity-list">
    <div class="title">Activity List</div>
    <div class="table" *ngIf="!lastUpdateTimeStamp">
      <div class="no-activities">Loading...</div>
    </div>
    <div class="table" *ngIf="lastUpdateTimeStamp &amp;&amp; lastEventList.data.length &lt; 1">
      <div class="no-activities">No Events Match</div>
    </div>
    <div class="table" *ngIf="lastUpdateTimeStamp &amp;&amp; lastEventList.data.length &gt; 0">
      <mat-table [dataSource]="lastEventList">
        <ng-container matColumnDef="timestamp">
          <mat-header-cell class="timestamp" *matHeaderCellDef>Timestamp</mat-header-cell>
          <mat-cell class="timestamp" *matCellDef="let activity">{{ activity.timestamp | date : 'MMM d, y \'at\' h:mm a' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
          <mat-header-cell class="action" *matHeaderCellDef>Action</mat-header-cell>
          <mat-cell class="action" *matCellDef="let activity">{{ activity.action }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="user">
          <mat-header-cell class="user" *matHeaderCellDef>User</mat-header-cell>
          <mat-cell class="user" *matCellDef="let activity" (click)="openProfile(activity.userId)">{{ activity.user.email || 'Anonymous' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="state">
          <mat-header-cell class="state" *matHeaderCellDef>State</mat-header-cell>
          <mat-cell class="state" *matCellDef="let activity">{{ activity.state }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="['timestamp', 'action', 'user', 'state']"></mat-header-row>
        <mat-row *matRowDef="let row; columns: ['timestamp', 'action', 'user', 'state']"></mat-row>
      </mat-table>
    </div>
  </div>
</div>