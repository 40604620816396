
<div class="signature-container col-xs-12" *ngIf="signerProfile">
  <div class="signature-field col-xs-12 col-md-6">
    <div class="signature-label col-xs-4 col-md-4">Name:</div>
    <div class="signature-content signature-content-name col-xs-8 col-md-8">{{ signerProfile.firstName + ' ' + signerProfile.lastName }}</div>
  </div>
  <div class="signature-field col-xs-12 col-md-6" *ngIf="signerProfile.company">
    <div class="signature-label col-xs-4 col-md-4">Company:</div>
    <div class="signature-content signature-content-company col-xs-8 col-md-8">{{ signerProfile.company }}</div>
  </div>
  <div class="signature-field col-xs-12 col-md-6" *ngIf="signerProfile.title">
    <div class="signature-label col-xs-4 col-md-4">Title:</div>
    <div class="signature-content signature-content-title col-xs-8 col-md-8">{{ signerProfile.title }}</div>
  </div>
  <div class="signature-field col-xs-12 col-md-6" *ngIf="signerProfile.email">
    <div class="signature-label col-xs-4 col-md-4">Email:</div>
    <div class="signature-content signature-content-email col-xs-8 col-md-8">{{ signerProfile.email }}</div>
  </div>
  <div class="signature-field col-xs-12 col-md-6" *ngIf="signerProfile.mobilePhone">
    <div class="signature-label col-xs-4 col-md-4">Phone:</div>
    <div class="signature-content signature-content-phone col-xs-8 col-md-8">{{ signerProfile.mobilePhone }}</div>
  </div>
  <div class="clear-both" *ngIf="hasAddress()">&nbsp;</div>
  <div class="signature-field col-xs-12 col-md-6" *ngIf="hasAddress()">
    <div class="signature-label col-xs-4 col-md-4">Address:</div>
    <div class="signature-content signature-content-address col-xs-8 col-md-8" [innerHTML]="getAddress()"></div>
  </div>
  <div class="clear-both">&nbsp;</div>
  <div class="signature-field col-xs-12 col-md-6">
    <div class="signature-label col-xs-4 col-md-4">Signature:</div>
    <div class="signature-content signature-content-signature col-xs-8 col-md-8" [ngClass]="getSignatureClass()">{{ signerProfile.firstName }} {{ signerProfile.lastName }}</div>
  </div>
  <div class="signature-field col-xs-12 col-md-6">
    <div class="signature-label col-xs-4 col-md-4">Date:</div>
    <div class="signature-content signature-content-date col-xs-8 col-md-8" [ngClass]="getSignatureClass()">{{ signed | date: 'longDate' }}</div>
  </div>
</div>