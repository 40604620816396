import * as async from 'async';
import * as moment from 'moment-timezone';
import { AppService } from 'app/services/app';
import { Component, Input, ElementRef, OnInit } from '@angular/core';
import { ProfileFile } from 'app/models/profile';
import { LogicService } from 'app/services/logic';
import { Router } from '@angular/router';

interface UploadQueueParams {
  profileFile: ProfileFile;
  file: File
}

import {
  WorkflowItem,
  WorkflowState
} from 'app/models/workflow';

@Component( {
  moduleId: module.id,
  selector: 'app-content-render-profile-upload',
  templateUrl: 'profile.upload.component.html',
  styleUrls: [ 'profile.upload.component.less' ]
} )
export class ContentRenderProfileUploadComponent implements OnInit {

  @Input()
  public workflowTargetState = <WorkflowState>null;

  @Input()
  public path: string = '_default';

  @Input()
  public accept: string = null;

  @Input()
  public item: WorkflowItem = null;

  public files: ProfileFile[] = null;

  public $el: JQuery = null;

  public uploadQueue = null;

  constructor( public app: AppService, public router: Router, public el: ElementRef, public logicService: LogicService ) {
    this.$el = $( this.el.nativeElement );

    this.setFileCount();

    this.uploadQueue = async.queue( ( params: UploadQueueParams, done ) => {
      this.processUpload( params, done );
    }, 2 );

  }

  public setFileCount(): void {

    if ( !this.workflowTargetState || !this.item || !this.item.target ) {
      return;
    }

    let count = 0;

    if ( this.item && this.item.target && Array.isArray( this.files ) ) {

      this.files.forEach( ( file ) => {

        if ( this.uploadComplete( file ) ) {
          count++;
        }

      } );

    }

    this.logicService.setDataValue( this.workflowTargetState, this.item.target, count );

  }

  public ngOnInit(): void {

    window.setTimeout( () => {

      if ( this.item && this.item.params ) {

        if ( typeof this.item.params.path === 'string' ) {
          this.path = <string>this.item.params.path;
        }
        if ( typeof this.item.params.accept === 'string' ) {
          this.accept = <string>this.item.params.accept;
        }

        if ( typeof this.item.params.min === 'number' && this.item.params.min > 0 && typeof this.item.target === 'string' ) {

          this.item.completeCheck = {
            '$gte': [
              this.item.target,
              this.item.params.min
            ]
          };

        }

      }

      this.initFileList();

    } );
  }

  public initFileList(): void {


    this.app.profileModel.listUploads( {
      profileId: <string>this.workflowTargetState.id,
      path: this.path,
    } )
      .then( ( files ) => {

        this.files = files;
        this.setFileCount();

      } )
      .catch( ( e ) => {
        console.error( 'Error loading existing file list', e );
      } );

  }

  public openDialogue(): void {

    const input = this.$el.find( 'input.profile-upload-input' );

    if ( !input.hasClass( 'tracking-select' ) ) {

      input.addClass( 'tracking-select' );

      input.change( () => {
        this.queueUpload( (<any>input.get( 0 )).files );
        input.val( null );
      } );

    }

    input.trigger( 'click' );


  }

  public viewFile( file: ProfileFile ): void {
    window.open( file.webViewLink, '_blank' );
  }

  public removeFile( file: ProfileFile ): void {

    let index: number = null;
    this.files.forEach( ( fileItem, i: number ) => {
      if ( fileItem.name === file.name ) {
        index = i;
      }
    } );

    if ( index !== null ) {
      this.files.splice( index, 1 );
    }

    this.setFileCount();

    this.app.profileModel
      .archiveUpload( {
        profileId: <string>this.workflowTargetState.id,
        path: this.path + '/' + file.name
      } )
      .then( () => {
        this.setFileCount();
      } )
      .catch( ( e ) => {
        console.error( 'error deleting file', e, file );
      } );

  }

  public fileList(): ProfileFile[] {
    return this.files.sort( ( a, b ) => {
      if ( a.name < b.name ) {
        return -1;
      }

      if ( a.name > b.name ) {
        return 1;
      }

      return 0;

    } );
  }

  public processUpload( params: UploadQueueParams, done: () => void ): void {

    let finished = false;

    const _done = () => {

      if ( finished ) {
        return;
      }

      finished = true;

      done();

    };

    const profileFile = params.profileFile;
    const file = params.file;

    this.app.profileModel
      .getUploadUrl( {
        profileId: <string>this.workflowTargetState.id,
        path: this.path,
        name: profileFile.name,
        size: file.size
      } )
      .then( ( uploadData ) => {

        // console.log( 'uploadData', uploadData );

        const url = 'https://cors-anywhere.herokuapp.com/' + uploadData.url;

        profileFile.uploadProgress = 0;

        $.ajax( url, {
          type: 'PUT',
          contentType: uploadData.mimeType,
          crossDomain: true,
          processData: false,
          data: file,
          xhr: function () {

            const xhr = new (<any>window).XMLHttpRequest();

            // Upload progress
            xhr.upload.addEventListener( 'progress', function ( evt ) {
              if ( evt.lengthComputable ) {
                profileFile.uploadProgress = evt.loaded / evt.total;
              }

            }, false );

            return xhr;
          },
          error: () => {
            console.error( 'error during file upload', profileFile );
            _done();
          }
        } )
          .then( ( file ) => {

            if ( file ) {

              Object.keys( file ).forEach( ( key ) => {
                if ( file.hasOwnProperty( key ) ) {
                  profileFile[ key ] = file[ key ];
                }
              } );

              delete profileFile.uploadProgress;
              this.setFileCount();

            } else {
              profileFile.uploadProgress = -1;
              console.error( 'error during file upload', profileFile );
            }

            _done();

          } );


      } )
      .catch( ( e ) => {
        console.error( 'error starting file upload', e, file );
      } );

  }

  public queueUpload( files: File[] ): void {

    if ( files.length < 1 ) {
      return;
    }

    const formatFileName = ( name: string ): string => {
      return name.trim().replace( /[^a-zA-Z_\-()\[\]\.0-9]+/g, '' );
    };

    files = Array.from( files ).sort( ( a, b ) => {

      const aName = formatFileName( a.name );
      const bName = formatFileName( b.name );

      if ( aName < bName ) {
        return -1;
      }

      if ( aName > bName ) {
        return 1;
      }

      return 0;
    } );

    for ( let i = 0; i < Math.min( 20, files.length ); i++ ) {

      const file = files[ i ];

      let filename = formatFileName( file.name );

      if ( filename.length < 1 || file.size > 100000000 ) {
        continue;
      }

      const timestamp = moment().tz( 'America/New_York' ).format( 'YYYYMMDD_HHmmss' );

      filename = filename.replace( /(\.[a-zA-Z0-9]+)?$/, '_' + timestamp + '$1' );

      if ( !this.files ) {
        this.files = [];
      }

      const profileFile: ProfileFile = {
        name: filename,
        uploadProgress: null
      };

      this.files.push( profileFile );
      this.setFileCount();

      this.uploadQueue.push( { profileFile: profileFile, file: file } );

    }

    this.uploadQueue.resume();
  }

  public uploadComplete( file: ProfileFile ): boolean {
    return !file.hasOwnProperty( 'uploadProgress' );
  }

  public formatPercent( ratio: number ): string {

    if ( ratio === null ) {
      return 'Starting';
    } else if ( ratio < 0 ) {
      return 'Error';
    } else if ( ratio >= 0.99 ) {
      return 'Success';
    }

    return `${Math.floor( ratio * 100 )}%`;
  }


}
