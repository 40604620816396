import {Component, OnInit, Input} from '@angular/core';
import {AppService} from 'app/services/app';
import {LegalDocument, LegalDocumentInstance, LegalDocumentInstanceSigner} from 'app/models/legal.document';
import {Profile} from 'app/models/profile';
import {Hash} from 'app/types/containers';

@Component({
  moduleId: module.id,
  selector: '.content-render-legal-document-view',
  templateUrl: 'legal.document.view.component.html',
  styleUrls: ['legal.document.view.component.less']
})
export class ContentRenderLegalDocumentViewComponent implements OnInit {

  @Input()
  public path: string = null;

  @Input()
  public instanceId: string = null;

  @Input()
  public showWaitingSignatures: boolean = true;

  public document: LegalDocument = null;

  public instance: LegalDocumentInstance = null;

  public profileCache: Hash<Profile> = {};

  public loading: boolean = false;

  constructor(public app: AppService) {
    this.app.contentLoading(true);
  }

  public ngOnInit(): void {

    if (this.path && this.loading === false && this.document === null && this.instance === null) {

      window.setTimeout(() => {
        this.loading = true;
        this.document = null;
        this.instance = null;

        this.app
          .legalDocumentModel
          .getSignerInstance({
            path: this.path
          })
          .then((instance) => {

            if (instance) {
              this.instance = instance;
            } else {
              this.instance = null;
            }

            return this.app
              .legalDocumentModel
              .getByPath({path: this.path})
              .then((document) => {
                this.document = document;
                this.loading = false;
                this.app.contentLoading(false);
              })
              .catch(() => {
                this.document = null;
                this.instance = null;
                this.app.contentLoading(false);
              });

          })
          .catch(() => {
            this.loading = false;
            this.document = null;
            this.instance = null;
          });

      });

    }

  }


  public markdownToHtml(markdownStr: string): string {

    if (typeof markdownStr !== 'string') {
      markdownStr = '';
    }

    return this.app.markdownToHtml(markdownStr);

  }

  public signerProfile(signer: LegalDocumentInstanceSigner): Profile {

    if (this.profileCache.hasOwnProperty(signer.userId)) {
      return this.profileCache[signer.userId];
    }

    this.profileCache[signer.userId] = null;

    this.app.profileModel
      .get(signer.userId)
      .then((profile) => {
        this.profileCache[signer.userId] = profile;
      });


    return null;

  }

  public listRequestedSigners(): LegalDocumentInstanceSigner[] {

    let result: LegalDocumentInstanceSigner[] = [];

    if (this.instance && this.instance.signers) {

      result = this.instance.signers.filter((signer) => {
        return signer && (typeof signer.signed !== 'string' || signer.signed.length < 1);
      });

    }

    return result;

  }

}
