import {Component, Input, OnInit, OnDestroy, ElementRef, NgZone} from '@angular/core';

import {AppService} from 'app/services/app';
import {ApiService} from 'app/services/api';
import {VideoService} from 'app/services/video';

interface Dimensions {
  height: number;
  width: number;
};

interface MetaData {
  height: number;
  width: number;
  duration: number;
  files: {}[];
  pictures: {
    active: boolean;
    sizes: {
      height: number;
      width: number;
      link: string;
      link_with_play_button: string;
    }[]
  };
};

/**
 * audio/video has not yet been initialized (wait)
 * @type {number}
 */
const HTML5_NETWORK_STATE_NETWORK_EMPTY = 0;

/**
 * audio/video is active and has selected a resource, but is not using the network
 * @type {number}
 */
const HTML5_NETWORK_STATE_NETWORK_IDLE = 1;

/**
 * browser is downloading data
 * @type {number}
 */
const HTML5_NETWORK_STATE_NETWORK_LOADING = 2;

/**
 * no audio/video source found (wait)
 * @type {number}
 */
const HTML5_NETWORK_STATE_NETWORK_NO_SOURCE = 3;

// no information whether the audio/video is ready (wait)
const HTML5_STATE_HAVE_NOTHING = 0;

// metadata for the audio/video is ready (wait)
const HTML5_STATE_HAVE_METADATA = 1;

// data for the current playback position is available, but not enough data to play next frame/millisecond (wait)
const HTML5_STATE_HAVE_CURRENT_DATA = 2;

// data for the current and at least the next frame is available (wait)
const HTML5_STATE_HAVE_FUTURE_DATA = 3;

// enough data available to start playing
const HTML5_STATE_HAVE_ENOUGH_DATA = 4;

interface HTML5VideoTimeRanges {
  length: number;
  start(index: number): number;
  end(index: number): number;
};

interface HTML5Video extends HTMLElement {

  autoplay: boolean;
  buffered: HTML5VideoTimeRanges;
  controls: boolean;
  currentSrc: string;
  src: string;
  currentTime: number;
  duration: number;
  ended: boolean;
  error: any;
  loop: boolean;
  networkState: number; // one of HTML5_NETWORK_STATE_*
  paused: boolean;
  played: HTML5VideoTimeRanges;
  preload: boolean;
  readyState: number; // one of HTML5_STATE_*
  volume: number; // 0.0 to 1.0


  load(): void; // Re-loads the audio/video element
  play(): void; // Starts playing the audio/video
  pause(): void; // Pauses the currently playing audio/video
  onabort(): void; // Fires when the loading of an audio/video is aborted
  oncanplay(): void; // Fires when the browser can start playing the audio/video
  oncanplaythrough(): void; // Fires when the browser can play through the audio/video without stopping for buffering
  ondurationchange(): void; // Fires when the duration of the audio/video is changed
  onemptied(): void; // Fires when the current playlist is empty
  onended(): void; // Fires when the current playlist is ended
  onerror(): void; // Fires when an error occurred during the loading of an audio/video
  onloadeddata(): void; // Fires when the browser has loaded the current frame of the audio/video
  onloadedmetadata(): void; // Fires when the browser has loaded meta data for the audio/video
  onloadstart(): void; // Fires when the browser starts looking for the audio/video
  onpause(): void; // Fires when the audio/video has been paused
  onplay(): void; // Fires when the audio/video has been started or is no longer paused
  onplaying(): void; // Fires when the audio/video is playing after having been paused or stopped for buffering
  onprogress(): void; // Fires when the browser is downloading the audio/video
  onratechange(): void; // Fires when the playing speed of the audio/video is changed
  onseeked(): void; // Fires when the user is finished moving/skipping to a new position in the audio/video
  onseeking(): void; // Fires when the user starts moving/skipping to a new position in the audio/video
  onstalled(): void; // Fires when the browser is trying to get media data, but data is not available
  onsuspend(): void; // Fires when the browser is intentionally not getting media data
  ontimeupdate(): void; // Fires when the current playback position has changed
  onvolumechange(): void; // Fires when the volume has been changed
  onwaiting(): void; // Fires when the video stops because it needs to buffer the next frame

}

type FilLTypes = 'width' | 'height';
type ModeTypes = 'single' | 'service';

@Component({
  moduleId: module.id,
  // tslint:disable-next-line:component-selector
  selector: '.vimeo-player',
  templateUrl: 'vimeo.component.html',
  styleUrls: ['vimeo.component.less']
})
export class ContentVimeoComponent implements OnInit, OnDestroy {

  @Input()
  public videoId: string = null;

  @Input()
  public fillType: FilLTypes = 'width';

  @Input()
  public mode: ModeTypes = 'single';

  @Input()
  public metaData: MetaData = null;

  public resizeHandler: () => void = null;

  public dimensionConstraint: Dimensions = null;

  public modalEnabled: boolean = false;

  public modalShowing: boolean = false;

  public playing: boolean = false;
  public loading: boolean = false;

  public toolbarDisabled: boolean = false;

  public playQueue: number = null;

  public subscriptions: any[] = [];

  public $: {
    host: JQuery;
    modal: JQuery;
    container: JQuery;
    message: JQuery;
    wait: JQuery;
    content: JQuery;
    controls: JQuery;
    playPause: JQuery;
    play: JQuery;
    pause: JQuery;
    timePlayed: JQuery;
    timeBufferProgress: JQuery;
    timePlayedProgress: JQuery;
    timeSlider: JQuery;
    timeTotal: JQuery;
    fullScreenContainer: JQuery;
    fullScreenButton: JQuery;
    fullScreenClose: JQuery;
    thumbnail: JQuery;
    thumbnailPlay: JQuery;
    video: JQuery;
  } = {
    host: null,
    container: null,
    modal: null,
    message: null,
    wait: null,
    content: null,
    controls: null,
    playPause: null,
    play: null,
    pause: null,
    timePlayed: null,
    timeBufferProgress: null,
    timePlayedProgress: null,
    timeSlider: null,
    timeTotal: null,
    fullScreenContainer: null,
    fullScreenButton: null,
    fullScreenClose: null,
    thumbnail: null,
    thumbnailPlay: null,
    video: null,
  };

  // native HTML5 player handle
  public rawPlayer: HTML5Video = null;

  public state: {
    message: string;
    sliding: boolean;
    wait: boolean;
  };

  constructor(public appService: AppService,
              public api: ApiService,
              public videoService: VideoService,
              public el: ElementRef,
              public zone: NgZone) {

  }

  public init(): void {

    this.state = {
      message: null,
      sliding: false,
      wait: true,
    };

  }

  public ngOnInit(): void {

    if (this.mode === 'service') {
      this.enableModal();
      this.setupServiceSubscriptions();
    } else {
      this.zone.runOutsideAngular(() => {
        this.init();
        this.cacheJQueryHandles();
        this.loadMetaData();
      });
    }

  }

  public clearResizeHandler(): void {
    if (this.resizeHandler) {
      $('html body').off('scroll', this.resizeHandler);
      $(window).off('resize', this.resizeHandler);
      this.resizeHandler = null;
    }
  }

  public ngOnDestroy(): void {

    this.hideModal();

    if (this.playQueue) {
      window.clearTimeout(this.playQueue);
      this.playQueue = null;
    }

    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.clearResizeHandler();

    // if ( this.$.modal ) {
    // 	this.$.modal.remove();
    // 	this.$.modal = null;
    // }

  }

  public closeModal(): void {

    if (this.showingFullScreen()) {
      this.leaveFullScreen();
    }

    this.pause();
    this.hideModal();


  }

  public setupServiceSubscriptions(): void {

    let loading = false;
    let playCallback = null;

    this.subscriptions.push(this.videoService.onLoad({
      next: (params) => {
        if (!params) {
          return;
        }

        loading = true;
        this.videoId = params.videoId;
        this.metaData = params.metaData || null;

        this.loading = true;

        if (this.$.thumbnail) {
          this.hide(this.$.thumbnail);
        }
        if (this.$.thumbnailPlay) {
          this.hide(this.$.thumbnailPlay);
        }

        this.zone.runOutsideAngular(() => {
          this.init();
          this.cacheJQueryHandles();
          this.loadMetaData((success) => {

            loading = false;

            if (success && playCallback) {
              const temp = playCallback;
              playCallback = null;
              temp();
            }
          });
        });

      },
      complete: () => {
        // NO-OP
      },
      error: () => {
        // NO-OP
      }
    }));

    this.subscriptions.push(this.videoService.onPlay({
      next: () => {

        if (loading) {
          playCallback = () => {
            this.play();
          }
        } else {
          playCallback = null;
          this.play();
        }

      },
      complete: () => {
        // NO-OP
      },
      error: () => {
        // NO-OP
      }
    }));

    this.subscriptions.push(this.videoService.onPause({
      next: () => {
        if (!this.videoId) {
          return;
        }

        this.pause();
      },
      complete: () => {
        // NO-OP
      },
      error: () => {
        // NO-OP
      }
    }));

  }

  public getDimensions(fillType?: FilLTypes): Dimensions {

    if (typeof fillType !== 'string' || fillType.trim().length < 1) {
      fillType = this.fillType;
    }

    let d: Dimensions = {
      height: 0,
      width: 0
    };

    if (!this.metaData) {
      return d;
    }

    d = {
      height: this.metaData.height,
      width: this.metaData.width
    };

    // this.videoId === '202407368' && console.log( 'fillType', this.videoId, this.fillType );

    const constraint = this.dimensionConstraint;

    if (constraint && d.height !== 0 && d.width !== 0) {

      const hRatio = constraint.height / d.height;
      const wRatio = constraint.width / d.width;

      // if either height or width is larger than the constraint
      if (hRatio < 1 || wRatio < 1) {

        if (hRatio < wRatio) { // if height is more larger than width
          d.height = constraint.height;
          d.width = Math.floor(d.width * hRatio);
        } else { // if width is more larger than height
          d.width = constraint.width;
          d.height = Math.floor(d.height * wRatio);
        }

      } else if (hRatio > 1 || wRatio > 1) {

        if (hRatio < wRatio) { // if height is less smaller than width
          d.height = constraint.height;
          d.width = Math.floor(d.width * hRatio);
        } else { // if width is less smaller than height
          d.width = constraint.width;
          d.height = Math.floor(d.height * wRatio);
        }

      }

    } else if (fillType === 'width') {

      // container's width sets the pace
      d.width = this.$.host.width();

      // if the video meta data specifies a width, calculate the height
      // according to the ratio of the native video width relative to the
      // width of the container's width
      if (this.metaData.width > 0) {
        d.height = Math.floor(this.metaData.height / this.metaData.width * d.width + 0.5);
      }

      // this.videoId === '202407368' && console.log( 'd 1 host.width', d );

    } else {

      // container's width sets the pace
      d.height = this.$.host.height();

      // if the video meta data specifies a width, calculate the height
      // according to the ratio of the native video width relative to the
      // width of the container's width
      if (this.metaData.height > 0) {
        d.width = Math.floor(this.metaData.width / this.metaData.height * d.height + 0.5);
      }

      // this.videoId === '202407368' && console.log( 'd 2 host.height', d );

    }

    return d;

  }

  public show(el: JQuery): void {
    if (!el) {
      return;
    }
    el.show();
    el.addClass('vimeo-show');
    el.removeClass('vimeo-hide');
  }

  public hide(el: JQuery): void {
    if (!el) {
      return;
    }
    el.removeClass('vimeo-show');
    el.addClass('vimeo-hide');
  }

  public updateUI(): void {
    requestAnimationFrame(() => {
      this.showModal();
      this._updateUI();
    });
  }

  public _updateUI(): void {

    const log = function (one?: any, two?: any, three?: any, four?: any, five?: any, six?: any) {

      // let args = Array.prototype.slice.call( arguments );
      // args.unshift( 'updateUI' );
      // console.log.apply( console, args );

    };

    log('updateUI', this.state);
    log('this.state', this.state);

    this.show(this.$.container);

    this.setThumbnail();

    /**
     * message: null,
     wait: true,
     playerReady: false,
     fullScreen: false,
     */

    // this can show/hide regardless of other things
    if (this.fullScreenSupported()) {

      this.show(this.$.fullScreenContainer);
      log('show full screen container');
    } else {

      this.hide(this.$.fullScreenContainer);
      this.hide(this.$.fullScreenClose);

      log('hide full screen container');
    }

    // showing a message hides everything else
    if (this.state.message) {

      log('show message', this.state.message);
      this.show(this.$.message);
      this.show(this.$.thumbnailPlay);

      // hide everything else
      this.hide(this.$.wait);
      this.hide(this.$.content);
      this.hide(this.$.video);

      this.$.message.html(this.state.message);

      return;
    }

    log('hide message');
    this.hide(this.$.message);
    this.$.message.html();

    this.state.wait = false;
    if (!this.rawPlayer || this.loading) {
      log('wait !rawPlayer');
      this.state.wait = true;
    } else if (this.rawPlayer.networkState === HTML5_NETWORK_STATE_NETWORK_EMPTY ||
      this.rawPlayer.networkState === HTML5_NETWORK_STATE_NETWORK_NO_SOURCE) {
      log('wait networkState');
      this.state.wait = true;

    } else if (this.playing && this.rawPlayer.readyState === HTML5_STATE_HAVE_NOTHING) {
      this.state.wait = true;
    } else if (this.rawPlayer.readyState !== HTML5_STATE_HAVE_ENOUGH_DATA && this.rawPlayer.readyState !== HTML5_STATE_HAVE_NOTHING) {
      this.state.wait = true;
    } else {
      log('NO wait');
    }

    // this can display over videos and thumbnails, so no need stop processing
    if (this.state.wait) {
      this.show(this.$.wait);
      this.hide(this.$.thumbnailPlay);
    } else {
      this.hide(this.$.wait);
      this.show(this.$.thumbnailPlay);
    }

    if (this.rawPlayer && this.rawPlayer.played) {
      log('played', this.rawPlayer.played.length);
    }

    // don't show controls until thumbnail play has been clicked at least
    // once
    // if ( this.rawPlayer && this.rawPlayer.played.length > 0 ) {
    // 	this.show( this.$.controls );
    // }

    // // if there is no player, it implies not ready
    // // also show thumbnail if video has never played
    // if ( this.rawPlayer && this.rawPlayer.played.length > 0 ) {
    //
    // 	console.error('')
    // 	this.hide( this.$.thumbnail );
    // }

    // if paused, show play button, otherwise show pause button
    if (this.rawPlayer && !this.rawPlayer.paused) {
      log('player is playing');
      this.playing = true;
      this.hide(this.$.play);
      this.show(this.$.pause);
    } else {
      log('player is paused');
      this.playing = false;
      this.show(this.$.play);
      this.hide(this.$.pause);
    }

    // always show the content when no message
    log('show content');
    this.show(this.$.content);

    const dimensions = this.getDimensions();

    [
      this.$.container,
      this.$.message,
      this.$.content,
      this.$.thumbnail
    ].forEach((el: JQuery) => {

      log('setting dimension', dimensions, 'to', el);
      el.css('height', dimensions.height);
      el.css('width', dimensions.width);

    });

    if (this.$.video) {
      log('setting dimension', dimensions, 'to', this.$.video);
      this.$.video.attr('height', dimensions.height);
      this.$.video.attr('width', dimensions.width);
    }

    log('start update time displays');
    this.updateTimeDisplays();
    log('end updating time displays');

  }

  public formatTime(seconds: number): string {

    seconds = Math.floor(seconds);

    const minutes = Math.floor(seconds / 60);
    seconds = seconds - (minutes * 60);

    let formattedSeconds = `${seconds}`;
    if (seconds < 10) {
      formattedSeconds = `0${seconds}`;
    }

    return `${minutes}:${formattedSeconds}`;

  }

  public updateTimeDisplays(): void {

    if (!this.metaData || this.metaData.duration <= 0) {
      return;
    }

    const timePlayedPercent = this.rawPlayer.currentTime / this.metaData.duration * 100;

    if (Math.abs(this.metaData.duration - this.rawPlayer.currentTime) <= 5 && !this.$.controls.hasClass('vimeo-hide')) {
      this.hide(this.$.controls);
    }

    if (Math.abs(this.metaData.duration - this.rawPlayer.currentTime) <= 1 && !this.$.thumbnail.hasClass('vimeo-show')) {
      this.show(this.$.thumbnail);
    }

    if (this.state.sliding) {

      // the current time display shows the slider position
      this.$.timePlayed.html(this.formatTime(this.metaData.duration * <number>this.$.timeSlider.val() / 100));

    } else {

      // set the slider value to the current time percent
      this.$.timeSlider.val(timePlayedPercent);

      // set the time display to video time, not slider position
      this.$.timePlayed.html(this.formatTime(this.rawPlayer.currentTime));

    }

    // whatever the slider position is now, sync the background color offset
    this.$.timePlayedProgress.css('left', `${this.$.timeSlider.val()}%`);

    // duration always stays the same
    this.$.timeTotal.html(this.formatTime(this.metaData.duration));

  }

  public checkBuffer(): void {

    const percentBuffered = () => {

      if (!this.metaData || !this.rawPlayer || this.rawPlayer.buffered.length < 1 || this.metaData.duration <= 0) {
        return 0;
      }

      let index = null;
      const currentTime = this.rawPlayer.currentTime;

      // search for buffer window spanning the current time index and use that one
      // stop searching once one is found
      for (let i = 0; index === null && i < this.rawPlayer.buffered.length; i++) {

        if (this.rawPlayer.buffered.start(i) <= currentTime &&
          currentTime <= this.rawPlayer.buffered.end(i)) {
          index = i;
        }

      }

      // default if one can't be found
      if (index === null) {
        index = 0;
      }

      return this.rawPlayer.buffered.end(index) / this.metaData.duration * 100;

    };

    this.$.timeBufferProgress.css('left', `${percentBuffered()}%`);

  }

  public applyVideoSourceFiles(): void {

    const metaData = this.metaData;

    if (!metaData || !metaData.files) {
      return;
    }

    // reset video element
    this.$.host.find('.vimeo-content .vimeo-video video').remove();
    this.$.host.find('.vimeo-content .vimeo-video').append('<video></video>');
    this.$.video = this.$.host.find('.vimeo-content .vimeo-video video');
    this.rawPlayer = <HTML5Video>this.$.video.get(0);

    let files = metaData.files.filter((file: any) => {
      return file.quality !== 'hls';
    });

    if (files.length < 1) {
      files = metaData.files;
    }

    files.forEach((file: any) => {
        if (!file.hasOwnProperty('width')) {
          file.width = 0;
        }
      }
    );

    const windowWidth = $(window).width();

    files
      .sort((fileA: any, fileB: any) => {

        const widthA = fileA.width;
        const widthB = fileB.width;

        let result = 0;
        if (widthA >= windowWidth && widthB >= windowWidth) {

          result = widthA - widthB; // return smallest one larger than the window first
        } else if (widthA >= windowWidth) {
          result = -1; // A is larger than the window, take it
        } else if (widthB >= windowWidth) {
          result = 1; // B is larger than the window, take it
        } else {
          result = widthB - widthA; // return the biggest one smaller than the window first
        }

        return result;

      })
      .forEach((file: any) => {

        file = {
          src: file.link_secure || file.link,
          type: file.type
        };

        this.$.video.append(`<source src="${file.src}" type="${file.type}">`)

      });

    this.registerRawPlayerEventHandlers();

  }

  public setMessage(message: string): void {

    if (this.rawPlayer) {
      this.playing = false;
      this.rawPlayer.pause();
    }

    this.state.message = message;
    this.updateUI();

  }

  public setThumbnail(): void {

    const metaData = this.metaData;

    if (!metaData || !metaData.pictures || !metaData.pictures.sizes) {
      this.$.thumbnail.css('background-image', null);
      return;
    }

    let bestMatch: any = null;

    metaData.pictures.sizes.forEach((picture) => {

      if (bestMatch === null) {
        bestMatch = picture;
      }

      if (picture.height > bestMatch.height) {
        bestMatch = picture;
      }

    });

    const link = bestMatch.link || bestMatch.link_with_play_button || null;

    if (link) {
      this.$.thumbnail.css('background-image', `url('${link}')`);
    } else {
      this.$.thumbnail.css('background-image', null);
    }

  }

  public registerRawPlayerEventHandlers(): void {

    const log = function (one?: any, two?: any, three?: any, four?: any, five?: any, six?: any) {

      const args = Array.prototype.slice.call(arguments);
      args.unshift('Event: ');
      // console.log.apply( console, args );

    };

    this.rawPlayer.addEventListener('ended', () => {

      log('Player Ended');

      // reset everything
      // this.rawPlayer.load();
      this.rawPlayer.currentTime = 0;
      this.hide(this.$.controls);

      if (this.$.thumbnail) {
        this.show(this.$.thumbnail)
      }

      if (this.$.thumbnailPlay) {
        this.hide(this.$.thumbnailPlay)
      }

    });

    this.rawPlayer.addEventListener('canplay', () => {
      log('Player can play');
      this.updateUI();
    });
    this.rawPlayer.addEventListener('canplaythrough', () => {
      log('Player can play through');
      this.updateUI();
    });
    this.rawPlayer.addEventListener('durationchange', () => {
      log('Player duration change');
      if (this.metaData.duration !== this.rawPlayer.duration) {
        this.metaData.duration = this.rawPlayer.duration;
        this.updateUI();
      }

    });

    // any potential advance in video
    this.rawPlayer.addEventListener('progress', () => {
      log('Player progress');
      this.checkBuffer();
    });
    this.rawPlayer.addEventListener('timeupdate', () => {
      log('Player time update');
      this.updateTimeDisplays();
    });

    // video can't play due to bandwidth or some other data issue
    this.rawPlayer.addEventListener('stalled', () => {
      log('Player stalled');
      this.updateUI();
    });
    this.rawPlayer.addEventListener('waiting', () => {
      log('Player waiting');
      this.updateUI();
    });

  }

  public enableModal(): void {
    this.modalEnabled = true;
    this.modalShowing = false;
  }

  public showModal(): void {

    const $window = $(window);
    const $body = $('html body');

    const $host = this.$.host;
    const $modal = this.$.modal;
    const $container = this.$.container;

    // if ( !this.$.modal ) {
    // 	this.$.modal = $( '<div></div>' ).addClass( 'vimeo-modal-background' ).hide();
    // 	$body.append( this.$.modal );
    // }

    if (!this.modalEnabled) {
      $container.attr('style', '');
      return;
    }

    if (!this.modalShowing) {
      this.hide($host);
      $host.hide();
      return;
    }

    let requestedFrame = false;

    if (!this.resizeHandler) {

      // console.log( 'resizeHandler? NO' );
      const run = () => {

        // console.log( 'resize handler' );

        if (requestedFrame) {
          return;
        }
        requestedFrame = true;

        requestAnimationFrame(() => {
          requestedFrame = false;

          this.updateUI();
        })

      };

      this.resizeHandler = function () {
        run();
      };

      $body.scroll(this.resizeHandler);
      $window.resize(this.resizeHandler);

      $body.css({overflow: 'hidden'});

      this.zone.run(() => {
        this.toolbarDisabled = this.appService.toolbar.disabled;
        this.appService.toolbar.disabled = true;
      });

    }

    const browserEnv = this.appService.browserEnv();

    this.show($host);
    this.show($modal);

    if (this.showingFullScreen() || browserEnv.isSafari) {
      this.dimensionConstraint = {
        height: Math.floor($window.height()),
        width: Math.floor($window.width())
      };
    } else {
      this.dimensionConstraint = {
        height: Math.floor($window.height() * 0.95),
        width: Math.floor($window.width() * 0.95)
      };
    }

    const d = this.getDimensions('width');

    $modal
      .height($body.height())
      .width($body.width())
      .show();

    // force to any due to bug in types for JQuery UI
    (<any>$container.position)({
      my: 'center center',
      at: 'center center',
      of: $body
    });

    $host
      .css({
        'z-index': 100000
      })
      .height($body.height());

    $container
      .css({
        position: 'fixed'
      })
      .height(d.height)
      .width(d.width);

    // force to any due to bug in types for JQuery UI
    (<any>$container.position)({
      my: 'center center',
      at: 'center center',
      of: $window
    });

  }

  public hideModal(): void {

    this.clearResizeHandler();
    this.modalShowing = false;

    const $host = this.$.host;
    const $body = $('html body');
    const $container = this.$.container;

    $host
      .attr('style', '')
      .height(0)
      .hide();
    $container.attr('style', '');
    $body.css({overflow: 'visible'});

    this.zone.run(() => {
      this.appService.toolbar.disabled = this.toolbarDisabled;
    });

  }

  public play(): void {

    if (!this.videoId) {
      return;
    }

    if (this.mode === 'service') {
      this.modalShowing = true;
    }

    if (this.rawPlayer && !this.loading) {

      this.show(this.$.controls);
      this.hide(this.$.thumbnail);

      this.rawPlayer.preload = true;
      this.playing = true;
      this.rawPlayer.play();

    } else {

      if (!this.playQueue) {
        this.playQueue = window.setTimeout(() => {
          this.playQueue = null;
          this.play();
        }, 1000);
      }
    }

    this.updateUI();

  }

  public pause(): void {

    if (this.playQueue) {
      window.clearTimeout(this.playQueue);
      this.playQueue = null;
    }

    if (this.rawPlayer) {
      this.playing = false;
      this.rawPlayer.pause();
    }

    this.updateUI();

  }

  public registerControlsPlayPauseEventHandlers() {


    if (!this.$.play || this.$.play.hasClass('event-handlers')) {
      return;
    }
    this.$.play.addClass('event-handlers');

    const playHandler = () => {
      this.play();
    };

    this.$.thumbnail.on('click', playHandler);
    this.$.play.on('click', playHandler);

    this.$.pause.on('click', () => {
      this.pause();
    });

  }

  public registerControlsSliderEventHandlers() {

    const slider = this.$.timeSlider;

    if (!slider || slider.hasClass('event-handlers')) {
      return;
    }
    slider.addClass('event-handlers');

    // tracks user changes only
    slider.change(() => {

      if (this.rawPlayer && this.metaData && this.metaData.duration > 0) {
        this.rawPlayer.currentTime = slider.val() * this.metaData.duration / 100;
      }

      this.updateUI();

    });

    slider.mousedown(() => {
      this.state.sliding = true;
      this.$.controls.on('mousemove', slideWatch);
    });

    slider.mouseup(() => {
      this.state.sliding = false;
      this.$.controls.off('mousemove', slideWatch);
    });

    const slideWatch = () => {
      this.updateTimeDisplays();
    };


  }

  public registerControlsFullScreenEventHandlers() {
  }

  public registerControlsEventHandlers() {

    this.registerControlsPlayPauseEventHandlers();
    this.registerControlsSliderEventHandlers();
    this.registerControlsFullScreenEventHandlers();

  }

  public cacheJQueryHandles(): void {

    this.$.host = $(this.el.nativeElement);

    if (this.$.host.hasClass('handles-cached')) {
      return;
    }

    this.$.host.addClass('handles-cached');

    this.$.modal = this.$.host.find('.vimeo-modal');
    this.$.container = this.$.host.find('.vimeo-container');

    this.$.message = this.$.host.find('.vimeo-message');
    this.$.wait = this.$.host.find('.vimeo-wait');
    this.$.content = this.$.host.find('.vimeo-content');

    this.$.controls = this.$.host.find('.vimeo-content .vimeo-controls');

    this.$.playPause = this.$.host.find('.vimeo-content .vimeo-controls .play-pause');
    this.$.play = this.$.host.find('.vimeo-content .vimeo-controls .play-pause .play');
    this.$.pause = this.$.host.find('.vimeo-content .vimeo-controls .play-pause .pause');

    this.$.timePlayed = this.$.host.find('.vimeo-content .vimeo-controls .time-parts .time-played');
    this.$.timeBufferProgress = this.$.host.find('.vimeo-content .vimeo-controls .time-parts .buffer-progress-fill');
    this.$.timePlayedProgress = this.$.host.find('.vimeo-content .vimeo-controls .time-parts .time-progress-fill');
    this.$.timeSlider = this.$.host.find('.vimeo-content .vimeo-controls .time-parts input[type=range].time-progress-slider');
    this.$.timeTotal = this.$.host.find('.vimeo-content .vimeo-controls .time-parts .time-total');
    this.$.fullScreenContainer = this.$.host.find('.vimeo-content .vimeo-controls .full-screen');
    this.$.fullScreenButton = this.$.host.find('.vimeo-content .vimeo-controls .full-screen .full-screen-icon');

    this.$.fullScreenButton.click(() => {
      this.toggleFullScreen();
    });

    this.$.fullScreenClose = this.$.host.find('.vimeo-content .vimeo-close-full-screen');
    this.$.thumbnail = this.$.host.find('.vimeo-content .vimeo-thumbnail');
    this.$.thumbnailPlay = this.$.host.find('.vimeo-content .vimeo-thumbnail .vimeo-thumbnail-play');

    if (this.mode === 'service') {
      this.$.host.hide();
      this.hide(this.$.thumbnail);
    }

  }

  public loadMetaData(done?: (success: boolean) => void): void {

    if (!this.videoId) {
      this.loading = false;
      done(false);
      return;
    }

    if (this.metaData) {

      // need to wait for HTML dom elements to settle
      window.setTimeout(() => {
        this.loading = false;
        this.applyVideoSourceFiles();
        this.registerControlsEventHandlers();
        this.updateUI();
        done(true)
      }, 1000);

      return;
    }

    this.api.call('vimeo.byId', {id: this.videoId})
      .then((metaData: MetaData): void => {

        this.metaData = metaData || this.metaData || null;

        this.applyVideoSourceFiles();
        this.registerControlsEventHandlers();
        this.updateUI();

        this.loading = false;
        done(true);

      })
      .catch((err: any) => {

        console.error('error loading video meta data', this.videoId, err);
        this.setMessage('There was an error loading the video. Please reload the page and try again.');
        done(false);

      });

  }

  public showingFullScreen(): boolean {

    if (!this.fullScreenSupported()) {
      return false;
    }

    const el = (<any>document);

    return !!(el.fullscreenElement || el.webkitFullscreenElement || el.mozFullScreenElement || el.msFullscreenElement);

  }

  public toggleFullScreen(): void {

    if (!this.fullScreenSupported()) {
      if (this.$.controls) {
        this.$.controls.removeClass('vimeo-force-show');
      }
    } else if (this.showingFullScreen()) {
      this.leaveFullScreen();
    } else {
      this.goFullScreen();
    }

  }

  public leaveFullScreen(): void {

    if (this.$.controls) {
      this.$.controls.removeClass('vimeo-force-show');
    }
    if (!this.fullScreenSupported()) {
      return;
    }

    const el = (<any>document);

    if (el.exitFullscreen) {
      el.exitFullscreen();
      return;
    }

    if (el.webkitExitFullscreen) {
      el.webkitExitFullscreen();
      return;
    }

    if (el.mozCancelFullScreen) {
      el.mozCancelFullScreen();
      return;
    }

    el.msExitFullscreen();

    return;

  };

  public goFullScreen(): void {

    if (!this.fullScreenSupported() || !this.$.host || !this.$.controls) {
      return;
    }

    this.$.host.addClass('full-screen-run');
    this.$.controls.addClass('vimeo-force-show');

    const el = (<any>this.$.host.get(0));

    if (el.requestFullscreen) {
      el.requestFullscreen();
      return;
    }

    if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
      return;
    }

    if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen();
      return;
    }

    el.msRequestFullscreen();

    return;

  }

  public fullScreenSupported(): boolean {

    const browserEnv = this.appService.browserEnv();

    if (!this.$.host || this.$.host.length < 1 || browserEnv.isSafari) {
      return false;
    }

    const el = (<any>this.$.host.get(0));

    if (el.requestFullscreen) {
      return true;
    }
    if (el.mozRequestFullScreen) {
      return true;
    }
    if (el.webkitRequestFullscreen) {
      return true;
    }

    return !!el.msRequestFullscreen;

  }

}
