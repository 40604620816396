
<app-content-banner [article]="article"></app-content-banner>
<div class="contact-content">
  <div class="contact-inner-content">
    <div class="title">Exio strives to provide the best service possible with every contact.</div>
    <div class="tagline">Are you looking to sell your business? Would you like to become a Network Partner? Want to join a winning team? From basic questions to complex inquiries, Exio is here to help. Contact us today!</div>
    <div class="row">
      <div class="col-sm-8 col-xs-12">
        <div class="col-form" *ngIf="!submitted">
          <form #contactModel="ngForm" (ngSubmit)="send()">
            <div class="row hidden-xs">
              <div class="col-sm-6">
                <input type="text" id="firstName1" name="firstName" placeholder="First Name *" [(ngModel)]="model.firstName" required>
              </div>
              <div class="col-sm-6">
                <input type="text" id="lastName1" name="lastName" placeholder="Last Name *" [(ngModel)]="model.lastName" required>
              </div>
            </div>
            <div class="row hidden-sm hidden-md hidden-lg">
              <div class="col-xs-12">
                <input type="text" id="firstName2" name="firstName" placeholder="First Name *" [(ngModel)]="model.firstName" required>
              </div>
            </div>
            <div class="row hidden-sm hidden-md hidden-lg">
              <div class="col-xs-12">
                <input type="text" id="lastName2" name="lastName" placeholder="Last Name *" [(ngModel)]="model.lastName" required>
              </div>
            </div>
            <div class="row hidden-xs">
              <div class="col-sm-6">
                <input type="email" id="email1" name="email" placeholder="Email *" [(ngModel)]="model.email" required>
              </div>
              <div class="col-sm-6">
                <input type="text" id="company1" name="company" placeholder="Company *" [(ngModel)]="model.company" required>
              </div>
            </div>
            <div class="row hidden-sm hidden-md hidden-lg">
              <div class="col-xs-12">
                <input type="email" id="email2" name="email" placeholder="Email *" [(ngModel)]="model.email" required>
              </div>
            </div>
            <div class="row hidden-sm hidden-md hidden-lg">
              <div class="col-xs-12">
                <input type="text" id="company2" name="company" placeholder="Company *" [(ngModel)]="model.company" required>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <input type="text" id="subject" name="subject" placeholder="Subject *" [(ngModel)]="model.subject" required>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <textarea id="comments" name="comments" placeholder="Comments" [(ngModel)]="model.comments"></textarea>
              </div>
            </div>
            <div class="row" *ngIf="errorMessage">
              <div class="col-xs-12">
                <div class="error-message">{{ errorMessage }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 buttons" *ngIf="!processing">
                <button type="submit">Send</button>
              </div>
              <div class="col-xs-12 buttons" *ngIf="processing">
                <div class="loader-spinner"></div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-thank-you" *ngIf="submitted">
          <h3>Thank you for contacting us.</h3>
          <h3>A member of the Exio team will contact you shortly.</h3><br *ngIf="params &amp;&amp; params.return">
          <h5 *ngIf="params &amp;&amp; params.return">Returning to {{ params.return }} in 10 seconds...</h5>
        </div>
      </div>
      <div class="col-sm-4 col-xs-12 contact-block">
        <div class="col-contact-info">
          <div class="section">
            <div class="section-content">
              <div class="title">Company Headquarters</div>
              <div class="info">2900 N Government Way #22</div>
              <div class="info">Coeur d'Alene, ID 83815</div>
            </div>
          </div>
          <div class="section">
            <div class="section-content">
              <div class="title">Direct Contact</div>
              <div class="info">Phone: 303.569.8050</div>
              <div class="info">Fax: 303.569.8050</div>
              <div class="info">Email:&nbsp;<a href="mailto:info@goexio.com">info@goexio.com</a></div>
            </div>
          </div>
          <div class="section">
            <div class="section-content">
              <div class="title">Business Hours</div>
              <div class="info">Monday - Friday</div>
              <div class="info">8am - 5pm PT</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="page-break"></div>