
<div class="signup-form" *ngIf="!profile">Loading...</div>
<div class="signup-form" *ngIf="profile">
  <div class="col-xs-12">
    <mat-form-field>
      <input matInput [(ngModel)]="profile.company" type="text" placeholder="Company Name" (blur)="onChangeHandler()" [formControl]="inputCompany">
      <mat-error *ngIf="inputCompany.invalid">{{ getError(inputCompany) }}</mat-error>
    </mat-form-field>
  </div>
  <div class="col-xs-12 col-sm-6">
    <mat-form-field>
      <input matInput [(ngModel)]="profile.firstName" type="text" placeholder="First Name" (blur)="onChangeHandler()" [formControl]="inputFirstName">
      <mat-error *ngIf="inputFirstName.invalid">{{ getError(inputFirstName) }}</mat-error>
    </mat-form-field>
  </div>
  <div class="col-xs-12 col-sm-6">
    <mat-form-field>
      <input matInput [(ngModel)]="profile.lastName" type="text" placeholder="Last Name" (blur)="onChangeHandler()" [formControl]="inputLastName">
      <mat-error *ngIf="inputLastName.invalid">{{ getError(inputLastName) }}</mat-error>
    </mat-form-field>
  </div>
  <div class="col-xs-12" *ngIf="showSignature &amp;&amp; signatureAvailable()">
    <div class="signature-display">{{ profile.firstName }} {{ profile.lastName }}</div>
  </div>
  <div class="col-xs-12">
    <mat-form-field>
      <input matInput [(ngModel)]="profile.title" type="text" placeholder="Title" (blur)="onChangeHandler()" [formControl]="inputTitle">
      <mat-error *ngIf="inputTitle.invalid">{{ getError(inputTitle) }}</mat-error>
    </mat-form-field>
  </div>
  <div class="col-xs-12 col-sm-6">
    <mat-form-field>
      <input matInput [(ngModel)]="profile.email" type="email" placeholder="Email" (blur)="onChangeHandler()" [formControl]="inputEmail">
      <mat-error *ngIf="inputEmail.invalid">{{ getError(inputEmail) }}</mat-error>
    </mat-form-field>
  </div>
  <div class="col-xs-12 col-sm-6">
    <mat-form-field>
      <input matInput [(ngModel)]="profile.workPhone" type="tel" placeholder="Phone" (blur)="onChangeHandler()" [formControl]="inputPhone">
      <mat-error *ngIf="inputPhone.invalid">{{ getError(inputPhone) }}</mat-error>
    </mat-form-field>
  </div>
  <div class="col-xs-12">
    <mat-form-field>
      <input matInput [(ngModel)]="profile.address.street1" type="text" placeholder="Address Line 1" (blur)="onChangeHandler()" [formControl]="inputAddress1">
      <mat-error *ngIf="inputAddress1.invalid">{{ getError(inputAddress1) }}</mat-error>
    </mat-form-field>
  </div>
  <div class="col-xs-12">
    <mat-form-field>
      <input matInput [(ngModel)]="profile.address.street2" type="text" placeholder="Address Line 2" (blur)="onChangeHandler()">
    </mat-form-field>
  </div>
  <div class="col-xs-12 col-sm-6">
    <mat-form-field>
      <input matInput [(ngModel)]="profile.address.city" type="text" placeholder="City" (blur)="onChangeHandler()" [formControl]="inputCity">
      <mat-error *ngIf="inputCity.invalid">{{ getError(inputCity) }}</mat-error>
    </mat-form-field>
  </div>
  <div class="col-xs-12 col-sm-3">
    <mat-form-field>
      <mat-label>State</mat-label>
      <mat-select [(ngModel)]="profile.address.state" (blur)="onChangeHandler()" [formControl]="inputState">
        <mat-option *ngFor="let state of states" [value]="state">{{ state }}</mat-option>
      </mat-select>
      <mat-error *ngIf="inputState.invalid">{{ getError(inputState) }}</mat-error>
    </mat-form-field>
  </div>
  <div class="col-xs-12 col-sm-3">
    <mat-form-field>
      <input matInput [(ngModel)]="profile.address.zip" type="text" placeholder="Zip" (blur)="onChangeHandler()" [formControl]="inputZip">
      <mat-error *ngIf="inputZip.invalid">{{ getError(inputZip) }}</mat-error>
    </mat-form-field>
  </div>
</div>