import {Component, Input, OnInit, ElementRef, AfterViewChecked, NgZone} from '@angular/core';
import {AppService} from 'app/services/app';
import {Hash} from 'app/types/containers';
import {LegalDocument, LegalDocumentInstance, LegalDocumentInstanceSigner, LegalDocumentSection} from 'app/models/legal.document';
import {Profile} from 'app/models/profile';
import * as moment from 'moment';

type DocumentMode = 'sign' | 'render';

@Component({
  moduleId: module.id,
  selector: '.content-render-legal-document',
  templateUrl: 'legal.document.component.html',
  styleUrls: ['legal.document.component.less']
})
export class ContentRenderLegalDocumentComponent implements OnInit, AfterViewChecked {

  @Input()
  public document: LegalDocument = null;

  @Input()
  public instance: LegalDocumentInstance = null;

  public profileCacheCount: number = 0;
  public profileCache: Hash<Profile> = {};

  public $el: JQuery = null;

  constructor(public app: AppService, public el: ElementRef, public zone: NgZone) {

    this.$el = $(this.el.nativeElement);

  }

  public ngAfterViewChecked(): void {


  }

  public ngOnInit(): void {

    if (this.document && this.instance) {

      this.document = this.app
        .legalDocumentModel
        .applyMergeCodes(this.document, null, this.instance.data);

    }

  }

  public sectionContent(i: number, section: LegalDocumentSection): string {
    return `${i + 1}\\.  **${section.title}**. ${section.contents}`;
  }

  public markdownToHtml(markdownStr: string): string {

    if (typeof markdownStr !== 'string') {
      markdownStr = '';
    }

    return this.app.markdownToHtml(markdownStr);

  }

  public listPreSigners(): LegalDocumentInstanceSigner[] {

    let result: LegalDocumentInstanceSigner[] = [];

    if (this.document && this.document.preSigners) {

      result = this.document.preSigners.map((preSigner) => {
        return {
          userId: preSigner,
          signed: this.instance.created,
          requested: this.instance.created
        };
      });

    }

    return result;
  }

  public listSigners(): LegalDocumentInstanceSigner[] {

    let result: LegalDocumentInstanceSigner[] = [];

    if (this.instance && this.instance.signers) {

      result = this.instance.signers.filter((signer) => {
        return signer && typeof signer.signed === 'string' && signer.signed.length > 0;
      });

    }

    return result;

  }

  public signerProfile(signer: LegalDocumentInstanceSigner): Profile {

    if (this.profileCache.hasOwnProperty(signer.userId)) {
      return this.profileCache[signer.userId];
    }

    this.profileCache[signer.userId] = null;

    this.app.profileModel
      .get(signer.userId)
      .then((profile) => {
        this.profileCacheCount++;

        if (this.profileCacheCount >= (this.listSigners().length + this.listPreSigners().length)) {
          this.app.contentLoading(false);
        }

        this.profileCache[signer.userId] = profile;

        return null;
      });


    return null;

  }

}
