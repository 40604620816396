
<div class="banner" *ngIf="article.bannerImgUrl" [style.background-color]="article.bannerBackground" [style.background-image]="sanitizer.bypassSecurityTrustStyle(imageUrl(article.bannerImgUrl))"></div>
<div class="content" [ngClass]="contentContainerClasses()">
  <div class="coming-soon-banner" *ngIf="isComingSoon()">
    <div class="coming-soon-banner-container">Coming Soon</div>
    <div class="coming-soon-banner-tail"></div>
  </div><img class="content-icon" [src]="sanitizer.bypassSecurityTrustUrl(iconUrl(article))">
  <div class="title" [innerHTML]="getTitle()"></div>
  <div class="description">{{ article.description }}</div>
  <div class="action" *ngIf="getAction()" [class.coming-soon]="isComingSoon()" [routerLink]="actionUrl(getAction(), article)" [fragment]="actionFragment(getAction(), article)">
    <div class="name">{{ getAction().name }}</div>
  </div>
</div>