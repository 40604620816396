
<div class="tile-container" [ngClass]="classes()" [class.container-marketplace]="mode === 'marketplace'" [routerLink]="pathUrl(getArticleLink(article))" [fragment]="pathFragment(getArticleLink(article))">
  <div class="banner tile-banner show-tall" [style.background-image]="sanitizer.bypassSecurityTrustStyle(tileImgUrl(article, true))"></div>
  <div class="banner tile-banner show-small" [style.background-image]="sanitizer.bypassSecurityTrustStyle(tileImgUrl(article, false))"></div>
  <div class="content-normal" *ngIf="mode === 'normal'" [class.platinum-listing]="isPlatinum()">
    <div class="tagline">{{ article.tagline }}</div>
    <div class="title" [innerHTML]="getTitle()"></div>
    <div class="break"></div>
    <div class="description">{{ article.description }}</div>
  </div>
  <div class="content-marketplace" *ngIf="mode === 'marketplace'" [class.platinum-listing]="isPlatinum()">
    <div class="tagline">{{ getMarketPlaceTagLine() }}</div>
    <div class="title" [innerHTML]="getTitle()"></div>
    <div class="break"></div>
    <div class="description">{{ article.description }}</div>
  </div>
  <div class="platinum-banner" *ngIf="isPlatinum()">
    <div class="banner-container">Platinum</div>
    <div class="banner-tail"></div>
  </div>
  <div class="ribbon-container" *ngIf="showRibbon()">
    <div class="banner-container">{{ ribbonText() }}</div>
    <div class="banner-tail"></div>
  </div>
  <div class="favorite-icon" *ngIf="showFavoriteToggle()" (click)="toggleFavorite($event)">
    <div class="component-icon heart" [class.active]="isFavorite()"></div>
  </div>
</div>