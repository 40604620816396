import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppService } from 'app/services/app';
import { Article } from 'app/models/article';
import { Profile } from 'app/models/profile';
import { QuestionnaireSession, SubmissionAnswer } from 'app/models/questionnaire';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material';

type SortTypes = 'sortHint' | 'name' | 'position';

@Component( {
  moduleId: module.id,
  selector: 'app-view-content-questionnaire',
  templateUrl: 'questionnaire.component.html',
  styleUrls: [ 'questionnaire.component.less' ]
} )
export class ViewsContentQuestionnaireComponent implements OnInit, OnDestroy {

  public authenticatedProfile: Profile = null;
  public subscriptions: any[] = [];
  private article: Article = null;
  private user: Profile = null;
  public session: QuestionnaireSession = null;
  public sessionAnswers: SubmissionAnswer[] = null;
  public userName: string = null;

  constructor( public appService: AppService,
               public route: ActivatedRoute,
               public router: Router,
               public sanitizer: DomSanitizer,
               public location: Location ) {

    this.appService.contentLoading( true );
    this.appService.toolbar.whiteOverContent = false;
    this.appService.toolbar.backgroundColor = '#FFFFFF';

    this.appService.footer.hide = true;

    this.appService.titleService.setTitle( 'Exio - Questionnaire Results - Loading...' );

  }

  public ngOnInit(): void {

    document.body.scrollTop = 0;

    this.subscriptions.push( this.appService.getAuthenticatedProfile( {
      next: ( profile ) => {

        if ( typeof profile === 'boolean' && !profile ) {

          this.appService.contentLoading( true );

        } else if ( !profile ) {

          this.router.navigate( [ '/action/login' ] );

        } else {

          this.authenticatedProfile = profile;

          this.subscriptions.push( this.route.params.subscribe( ( params: { submissionId: string } ) => {
            this.loadSubmission( params.submissionId );
          } ) );

        }

      },
      error: () => {
        this.router.navigate( [ '/action/login' ] );
      },
      complete: () => {
        this.router.navigate( [ '/action/login' ] );
      }
    } ) );

  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach( ( subscription ) => {
      subscription.unsubscribe();
    } );
  }

  private loadSubmission( submissionId: string ): void {

    this.appService.questionnaireModel.getSessionBySubmissionId( submissionId )
      .then( sessionContext => {

        this.session = sessionContext.session;
        this.user = sessionContext.user;
        this.article = sessionContext.article;

        this.populateSessionAnswers();
        this.userName = this.getUserName();

        this.appService.titleService.setTitle( 'Exio - Questionnaire Results - ' + this.article.title );
        this.appService.contentLoading( false );

      } )
      .catch( e => {
        console.error( 'failed to get session by submission id', e );
      } );

  }

  private getUserName(): string {

    let name = '';

    if ( this.user.firstName && this.user.lastName ) {
      name = `${this.user.firstName} ${this.user.lastName}`;
    } else if ( this.user.firstName ) {
      name = this.user.firstName;
    } else if ( this.user.lastName ) {
      name = this.user.lastName;
    }

    return name;

  }

  private populateSessionAnswers(): void {

    this.sessionAnswers = [];

    // filter efficiently
    this.session.submissionData.answers.forEach( answer => {

      if ( answer.name.toLowerCase().trim() === 'redirecturl' ) {
        return;
      }

      if ( answer.name.toLowerCase().trim() === 'listingurl' ) {
        return;
      }

      switch ( answer.type ) {
        case 'control_email':
        case 'control_address':
        case 'control_phone':
        case 'control_datetime':
        case 'control_time':
        case 'control_textbox':
        case 'control_textarea':
        case 'control_dropdown':
        case 'control_radio':
        case 'control_checkbox':
        case 'control_number':
        case 'control_fileupload':
        case 'control_signature':

          this.sessionAnswers.push( answer );

          break;
      }

    } );

  }

  private getArticlePath(): string {
    return this.article.path.replace( /^\/?/, '/' );
  }

  private getStateFormat( state: string ): string {

    if ( !state ) {
      return '';
    }

    return state.replace( / .*$/, '' );
  }

  private linkToFileName( link: string ): string {

    if ( !link ) {
      return '';
    }

    return link.replace( /^.*\//, '' );
  }

  private openFileLink( link: string ): void {

    if ( !link ) {
      return;
    }

    window.open( link, '_blank' );
  }


}
