
<div class="listing-upload-container" *ngIf="!!workflowTargetState &amp;&amp; !!path &amp;&amp; !!workflowTargetState.id &amp;&amp; workflowTargetState.id !== 'new'">
  <input class="listing-upload-input" *ngIf="files !== null &amp;&amp; !accept" type="file" multiple>
  <input class="listing-upload-input" *ngIf="files !== null &amp;&amp; !!accept" type="file" [accept]="accept" multiple>
  <div class="listing-upload-button">
    <button class="active enabled" (click)="openDialogue()">Upload File</button>
  </div>
  <div class="loader-spinner" *ngIf="files === null"></div>
  <div class="listing-uploaded-files" *ngIf="files !== null &amp;&amp; files.length &lt; 1">
    <div class="listing-uploaded-file">
      <div class="listing-uploaded-file-field listing-uploaded-file-name">No Files Uploaded</div>
    </div>
  </div>
  <div class="listing-uploaded-files" *ngIf="files !== null">
    <div class="listing-uploaded-file" *ngFor="let file of fileList();">
      <div class="listing-uploaded-file-field listing-uploaded-file-name">{{ file.name }}</div>
      <div class="listing-uploaded-file-field listing-uploaded-file-remove" *ngIf="uploadComplete(file)" (click)="removeFile(file)">Remove</div>
      <div class="listing-uploaded-file-field listing-uploaded-file-progress" *ngIf="!uploadComplete(file)">{{ formatPercent(file.uploadProgress) }}</div>
    </div>
  </div>
</div>