
<app-content-banner [article]="article"></app-content-banner>
<div class="blog-entry" *ngIf="article">
  <h1 class="title mobile-title" [innerHTML]="formatTitle(article.title, true)"></h1>
  <h1 class="title desktop-title" [innerHTML]="formatTitle(article.title, false)"></h1>
  <div class="info"><span class="updated">{{article.updated | date:'MMMM d, y'}}&nbsp;</span><span class="author" *ngIf="authorProfile">by&nbsp;</span><span class="name author-link" *ngIf="authorProfile &amp;&amp; authorProfile.id" (click)="goToAuthor(authorProfile);">{{authorProfile.firstName}} {{authorProfile.lastName}}</span><span class="name" *ngIf="authorProfile &amp;&amp; !authorProfile.id">{{authorProfile.firstName}} {{authorProfile.lastName}}</span></div>
  <div class="content-render" [article]="article"></div>
</div>
<app-social-share></app-social-share>
<app-forms-newsletter></app-forms-newsletter>
<app-footer-border></app-footer-border>