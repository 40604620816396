
<div class="tiles-container">
  <div class="app-content-tiles show-six" *ngIf="listingsBuyersSix.length &gt; 0" [articles]="listingsBuyersSix" [mode]="'normal'" [disableAd]="true"></div>
  <div class="app-content-tiles show-four" *ngIf="listingsBuyersFour.length &gt; 0" [articles]="listingsBuyersFour" [mode]="'normal'" [disableAd]="true"></div>
  <div class="vimeo-featured-video" *ngIf="featuredVideoId()">
    <div class="vimeo-player-thumbnail big-thumbnail" (click)="playVideo(featuredVideoId())" [style.background-image]="featuredVideoThumbnail()">
      <div class="vimeo-thumbnail-contents">
        <div class="vimeo-thumbnail-play"></div>
        <div class="vimeo-pre-title">{{ getFeaturedVideo().tagline }}</div>
        <div class="vimeo-title">WHAT IF YOUR LISTING<br>PROMOTED ITSELF?</div>
      </div>
    </div>
    <div class="vimeo-player-thumbnail little-thumbnail" (click)="playVideo(featuredVideoId())" [style.background-image]="featuredVideoThumbnail()">
      <div class="vimeo-thumbnail-contents">
        <div class="vimeo-thumbnail-play"></div>
        <div class="vimeo-pre-title">{{ getFeaturedVideo().tagline }}</div>
        <div class="vimeo-title" [innerHTML]="formatNewlines(getFeaturedVideo().title)"></div>
      </div>
    </div>
  </div>
  <div class="bottom-articles-container">
    <div class="bottom-articles"><a class="seo-link" *ngFor="let article of bottomArticles" [href]="getArticleLink(article)">{{ article.title }}</a>
      <div class="bottom-article-container article-container" *ngFor="let article of bottomArticles" [routerLink]="pathUrl(getArticleLink(article))" [fragment]="pathFragment(getArticleLink(article))">
        <div class="bottom-article">
          <div class="tagline">{{ article.tagline }}</div>
          <div class="title" [innerHTML]="getTitle(article)"></div>
          <div class="break"></div>
          <div class="description">{{ article.description }}</div>
        </div>
      </div>
      <div class="bottom-article-container video-container" *ngIf="recentVideoId()">
        <div class="bottom-article-video">
          <div class="vimeo-player-thumbnail big-thumbnail" (click)="playVideo(recentVideoId())" [style.background-image]="recentVideoThumbnail(true)">
            <div class="vimeo-thumbnail-contents">
              <div class="vimeo-thumbnail-play"></div>
              <div class="vimeo-pre-title">{{ getRecentVideo().tagline }}</div>
              <div class="vimeo-title" [innerHTML]="formatNewlines(getRecentVideo().title)"></div>
            </div>
          </div>
          <div class="vimeo-player-thumbnail little-thumbnail" (click)="playVideo(recentVideoId())" [style.background-image]="recentVideoThumbnail(false)">
            <div class="vimeo-thumbnail-contents">
              <div class="vimeo-thumbnail-play"></div>
              <div class="vimeo-pre-title">{{ getRecentVideo().tagline }}</div>
              <div class="vimeo-title" [innerHTML]="formatNewlines(getRecentVideo().title)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>