
<div class="toolbar-seo-container">
  <div class="section" *ngFor="let section of menuSections()"><a class="seo-link" *ngFor="let link of section.links" [href]="link.url">{{ link.title }}</a></div>
</div>
<div class="toolbar-container" [class.expanded-menu]="!isCollapsed()">
  <div class="toolbar-container-handles" [class.hidden]="appService.toolbar.disabled &amp;&amp; appService.toolbar.collapsed">
    <div class="logo" (click)="goHome()">
      <div class="logo-image" *ngIf="!showGray()"></div>
      <div class="logo-image-gray" *ngIf="showGray()"></div>
    </div>
    <div class="app-toolbar-profile-handle" *ngIf="isCollapsed() &amp;&amp; profile" (click)="goDashboard()">
      <div class="component-icon profile-button white" *ngIf="!showGray()"></div>
      <div class="component-icon profile-button grey" *ngIf="showGray()"></div>
    </div>
    <div class="app-toolbar-menu-handle closed-menu" *ngIf="isCollapsed()" (click)="toggleOpened()">
      <div class="component-icon menu" *ngIf="!showGray()"></div>
      <div class="component-icon menu-gray" *ngIf="showGray()"></div>
    </div>
    <div class="app-toolbar-menu-handle opened-menu" *ngIf="!isCollapsed()" (click)="toggleOpened()">
      <div class="component-icon close-handle"></div>
    </div>
  </div>
  <div class="app-toolbar-menu" [class.hidden]="isCollapsed()">
    <div class="sections-container">
      <div class="sections desktop-view">
        <div class="section" *ngFor="let section of menuSections()">
          <div class="section-title">{{ section.title }}</div>
          <div class="section-links">
            <div class="section-link" *ngFor="let link of section.links" (click)="goLink(link)">{{ link.title }}</div><a class="seo-link" *ngFor="let link of section.links" [href]="link.url">{{ link.title }}</a>
          </div>
        </div>
      </div>
      <div class="sections mobile-view">
        <div class="section">
          <div class="section-title" (click)="goHome()">Home</div>
        </div>
        <div class="section" *ngFor="let section of menuSections()">
          <div class="section-title" *ngIf="section.links.length === 1" (click)="goLink(section.links[0])">{{ section.title }}</div>
          <div class="section-title" *ngIf="section.links.length &gt; 1" (click)="toggleExpandedSection(section)">{{ section.title }}
            <div class="icon-container" *ngIf="!isSectionExpanded(section)">
              <div class="component-icon arrow-large-right"></div>
            </div>
            <div class="icon-container" *ngIf="isSectionExpanded(section)">
              <div class="component-icon arrow-large-down"></div>
            </div>
          </div>
          <div class="section-links" *ngIf="section.links.length &gt; 1" [class.expanded-section]="isSectionExpanded(section)">
            <div class="section-link" *ngFor="let link of section.links" (click)="goLink(link)">{{ link.title }}</div>
          </div>
        </div>
      </div>
      <div class="clear-cookies" (click)="clearCookies()">Clear Cookies</div>
    </div>
    <div class="contact-info">
      <div class="contact-info-group">
        <div class="contact-icon">
          <div class="component-icon screen"></div>
        </div>
        <div class="contact-data">Email</div>
        <div class="contact-data"><a href="mailto:info@goexio.com">info@goexio.com</a></div>
      </div>
      <div class="contact-info-group">
        <div class="contact-icon">
          <div class="component-icon phone"></div>
        </div>
        <div class="contact-data">Phone</div>
        <div class="contact-data"><a href="tel:1-303-569-8050">+1 (303) 569-8050</a></div>
      </div>
      <div class="contact-info-group">
        <div class="contact-icon">
          <div class="component-icon location-marker"></div>
        </div>
        <div class="contact-data"><a href="https://www.google.com/maps/place/2900+N+Government+Way+%2322,+Coeur+d'Alene,+ID+83815">2900 N Government Way #22<br>Coeur d'Alene, ID 83815</a></div>
      </div>
    </div>
  </div>
</div>