
<div class="slider" *ngIf="getArticleCount() &gt; 0">
  <div class="slider-container">
    <div class="page" *ngFor="let article of getArticles(); let i = index;" [ngClass]="getPageClasses(i)">
      <app-content-banner [article]="article" [topPadding]="topPadding" [blur]="locked"></app-content-banner>
    </div>
    <div class="locked-icon" *ngIf="locked" (click)="clickedLock()">
      <div class="component-icon lock-white"></div>
    </div>
    <div class="controls-container dots-mode" *ngIf="navigationMode === 'dots' &amp;&amp; getArticleCount() &gt; 1">
      <div class="controls">
        <div class="control" *ngFor="let article of getArticles(); let i = index;" [ngClass]="getPageClasses(i)" (click)="selectPage(i)"></div>
      </div>
    </div>
    <div class="controls-container arrows-mode" *ngIf="(navigationMode === 'arrows' || navigationMode === 'gallery') &amp;&amp; getArticleCount() &gt; 1">
      <div class="control-left" (click)="selectPreviousPage()">
        <div class="component-icon arrow-large-left"></div>
      </div>
      <div class="control-right" (click)="selectNextPage()">
        <div class="component-icon arrow-large-right"></div>
      </div>
      <div class="control-next" *ngIf="navigationMode === 'arrows'" (click)="selectNextPage()">
        <div class="control-next-content" *ngIf="!isLastPage()">SEE NEXT</div>
        <div class="control-next-content" *ngIf="isLastPage()">RESTART</div>
      </div>
    </div>
  </div>
</div>