
<!--.legal-document-view(*ngIf="loading || !document || !instance")-->
<!--	.loading-document-->
<!--		.loader-spinner-->
<div class="legal-document-view" *ngIf="!loading &amp;&amp; document &amp;&amp; instance">
  <div class="content-render-legal-document" [document]="document" [instance]="instance"></div>
  <div class="legal-document-requested-signatures" *ngIf="showWaitingSignatures &amp;&amp; listRequestedSigners().length &gt; 0">
    <div class="col-xs-4"></div>
    <div class="legal-document-requested-signatures-title col-xs-4">Waiting for the Following Signatures</div>
    <div class="col-xs-4"></div>
    <div class="legal-document-requested-signature col-xs-12" *ngFor="let signer of listRequestedSigners()">
      <div class="legal-document-requested-signature-contents col-xs-12" *ngIf="!!signerProfile(signer)">
        <div class="legal-document-requested-signature-name col-xs-6">{{ signerProfile(signer).firstName }} {{ signerProfile(signer).lastName }}</div>
        <div class="legal-document-requested-signature-requested-date col-xs-6">Sent: {{ signer.requested | date: 'longDate' }}</div>
      </div>
    </div>
  </div>
</div>