
<div class="legal-document-body" *ngIf="!document || !instance">Legal Document Not Found</div>
<div class="legal-document-body" *ngIf="document &amp;&amp; instance">
  <div class="legal-document-title" [innerHTML]="markdownToHtml(document.title)"></div>
  <div class="legal-document-statement" *ngIf="!document.parties">This {{ document.title }} ("Agreement") is made as of {{ instance.created | date: 'longDate' }} (the "Effective Date") between Exit Strategy Ltd. ("Exio") and signer(s) ("Other Party")</div>
  <div class="legal-document-statement" *ngIf="document.parties" [innerHTML]="markdownToHtml(document.parties)"></div>
  <div class="legal-document-description" [innerHTML]="markdownToHtml(document.description)"></div>
  <div class="legal-document-sections-container" *ngIf="document.sections">
    <div class="legal-document-sections">
      <div class="legal-document-section-container" *ngFor="let section of document.sections; let i = index;">
        <div class="legal-document-section">
          <div class="legal-document-section-contents" [innerHTML]="markdownToHtml(sectionContent(i, section))"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="legal-document-signature-statement">IN WITNESS WHEREOF, the parties have executed this Agreement to be effective on and as of the Effective Date stated above.</div>
  <div class="legal-document-signatures-container">
    <div class="legal-document-signatures">
      <div class="legal-document-signature" *ngFor="let signer of listPreSigners()">
        <app-content-render-legal-document-signature [signed]="signer.signed" [signerProfile]="signerProfile(signer)"></app-content-render-legal-document-signature>
      </div>
      <div class="legal-document-signature" *ngFor="let signer of listSigners()">
        <app-content-render-legal-document-signature [signed]="signer.signed" [signerProfile]="signerProfile(signer)"></app-content-render-legal-document-signature>
      </div>
    </div>
  </div>
  <div class="legal-document-version" *ngIf="document.version">{{ document.version }}</div>
</div>