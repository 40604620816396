
<div class="toolbar-buffer"></div>
<div class="content" *ngIf="authenticatedProfile &amp;&amp; session">
  <div class="content-container">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <div class="title">Questionnaire Results</div>
        </mat-card-title>
        <mat-card-subtitle>
          <div class="sub-title">for {{ this.article.title }} {{ getArticlePath() }}</div>
        </mat-card-subtitle>
        <mat-card-subtitle>
          <div class="sub-title">submitted by {{ userName }} <a href="mailto:{{ this.user.email }}">{{ this.user.email }}</a></div>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <mat-list>
          <ng-container *ngFor="let answer of sessionAnswers">
            <mat-list-item>
              <h3 matLine>{{ answer.text }}</h3>
              <div class="data" *ngIf="answer.type === 'control_email'" matLine>
                <mat-form-field>
                  <input matInput [readonly]="true" [value]="answer.answer">
                </mat-form-field>
              </div>
              <div class="data" *ngIf="answer.type === 'control_textbox'" matLine>
                <mat-form-field>
                  <input matInput [readonly]="true" [value]="answer.answer">
                </mat-form-field>
              </div>
              <div class="data" *ngIf="answer.type === 'control_textarea'" matLine>
                <mat-form-field>
                  <textarea matInput [readonly]="true" [value]="answer.answer"></textarea>
                </mat-form-field>
              </div>
              <div class="data" *ngIf="answer.type === 'control_dropdown'" matLine>
                <mat-form-field>
                  <input matInput [readonly]="true" [value]="answer.answer">
                </mat-form-field>
              </div>
              <div class="data" *ngIf="answer.type === 'control_radio'" matLine>
                <mat-form-field>
                  <input matInput [readonly]="true" [value]="answer.answer">
                </mat-form-field>
              </div>
              <div class="data" *ngIf="answer.type === 'control_number'" matLine>
                <mat-form-field>
                  <input matInput [readonly]="true" [value]="answer.answer">
                </mat-form-field>
              </div>
              <div class="data" *ngIf="answer.type === 'control_checkbox'" matLine>
                <mat-form-field *ngFor="let value of answer.answer">
                  <input matInput [readonly]="true" [value]="value">
                </mat-form-field>
              </div>
              <div class="data" *ngIf="answer.type === 'control_phone'" matLine>
                <mat-form-field>
                  <input matInput [readonly]="true" [value]="answer.prettyFormat">
                </mat-form-field>
              </div>
              <div class="data" *ngIf="answer.type === 'control_datetime'" matLine>
                <mat-form-field>
                  <input matInput [readonly]="true" [value]="answer.prettyFormat | date">
                </mat-form-field>
              </div>
              <div class="data" *ngIf="answer.type === 'control_time'" matLine>
                <mat-form-field>
                  <input matInput [readonly]="true" [value]="answer.prettyFormat">
                </mat-form-field>
              </div>
              <div class="data" *ngIf="answer.type === 'control_signature'" matLine><img class="signature" [src]="answer.answer"></div>
              <div class="data uploads" *ngIf="answer.type === 'control_fileupload'" matLine><br>
                <mat-chip-list>
                  <mat-chip *ngFor="let link of answer.answer" (click)="openFileLink(link)">{{ linkToFileName(link) }}</mat-chip>
                </mat-chip-list>
              </div>
              <div class="data address" *ngIf="answer.type === 'control_address'" matLine><br>
                <app-view-content-questionnaire-address [answer]="answer"></app-view-content-questionnaire-address>
              </div>
            </mat-list-item>
          </ng-container>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>
</div>